import React from 'react';  
import PropTypes from 'prop-types';  
import { Dialog, DialogContent, DialogTitle, Button, Typography, DialogActions, IconButton } from '@mui/material';  
import CloseIcon from '@mui/icons-material/Close';  

const CustomModal = ({ showModal, modalContext, icon, closeModal, navigate }) => {  
    return (  
        <>  
            {showModal && (  
                <Dialog  
                    open={showModal}  
                    aria-labelledby="notification-dialog-title"
                    onClose={closeModal}  
                    disableBackdropClick  
                    PaperProps={{  
                        style: {  
                            width: '400px',  // Set a fixed width  
                            minHeight: '200px', // Ensure a minimum height  
                            borderRadius: '8px',  
                        },  
                    }}  
                >  
                    <DialogTitle id="notification-dialog-title" className="flex items-center">  
                    {icon}
                <Typography variant="h6" className="ml-1 mt-2 text-[#555555]">
                            {modalContext.isError ? 'Failure' : 'Success'}  
                        </Typography>  
                        <IconButton  
                            aria-label="close"  
                            onClick={closeModal}  
                            sx={{ position: 'absolute', right: 8, top: 8 }}  
                        >  
                            <CloseIcon className='w-[14px] h-[14px]' />  
                        </IconButton>  
                    </DialogTitle>  

                    <DialogContent className="flex flex-col" style={{ padding: '16px 24px', marginTop: '-16px', flexGrow: 1, color:"#555555", fontSize:"10px" }}>
                <Typography variant="body1" style={{fontSize:"14px",paddingLeft:"16px"}}>  
                            {modalContext.text}  
                        </Typography>  
                    </DialogContent>  

                    <DialogActions className="flex justify-end mb-2 mr-2">  
                        <Button  
                            variant="contained"  
                            onClick={navigate}  
                            style={{  
                                backgroundColor: modalContext.isError ? '#FA4343' : '#58AB40',  
                                color: 'white',  
                                width: '56px',  
                                height: '32px',  
                                textTransform: 'none',  
                                borderRadius: '6px',  
                            }}  
                        >  
                            Okay  
                        </Button>  
                    </DialogActions>  
                </Dialog>  
            )}  
        </>  
    );  
};  

CustomModal.propTypes = {  
    modalContext: PropTypes.shape({  
        text: PropTypes.string.isRequired,  
        isError: PropTypes.bool,  
    }).isRequired,  
    icon: PropTypes.element.isRequired,  
    closeModal: PropTypes.func.isRequired,  
    navigate: PropTypes.func.isRequired,  
};  

export default CustomModal;