import React, { useState, useRef, useEffect } from 'react';
import { SearchBoxIcon } from '../../assets/svgs';
import { Checkbox } from '@mui/material';
const DownArrowIcon = ({ className, style }) => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={style}
    >
        <path
            d="M1.75 1.5L7.57226 6.21869C7.76995 6.37891 8.05643 6.3651 8.23779 6.18662L13 1.5"
            stroke="#737373"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

const MultiSelect = ({ label, name, options, value, onChange, error, placeholder }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownRef = useRef(null); // Create a ref for the dropdown

    const handleToggle = (option) => {
        const currentIndex = value.indexOf(option.value);
        const newValue = [...value];

        if (currentIndex === -1) {
            newValue.push(option.value);
        } else {
            newValue.splice(currentIndex, 1);
        }
        onChange(newValue);
    };

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleRemove = (option) => {
        const newValue = value.filter(val => val !== option);
        onChange(newValue);
    };

    // Close the dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative mb-[6px] mt-[6px] placeholder-[#979797] placeholder:text-[10px]">
            <div ref={dropdownRef} className={`relative mb-[16px] rounded-md ${open ? 'border-[#EB7E39] shadow-md' : 'border-gray-300'} border transition-all duration-200 placeholder-[#979797] placeholder:text-[10px]`}>
                <div
                    className="flex justify-between items-center p-3 cursor-pointer placeholder-[#979797] placeholder:text-[10px] placeholder:font-medium"
                    onClick={() => setOpen(!open)}
                >
                    <span className="flex flex-grow flex-wrap placeholder-[#979797] placeholder:text-[10px] placeholder:font-medium">
                        {value.length > 0 ? (
                            value.map(val => {
                                const option = options.find(opt => opt.value === val);
                                return (
                                    <div
                                        key={val}
                                        className="flex items-center bg-[#D9D9D9] rounded-md px-2 py-1 mt-1 text-[10px] mr-1 text-[#555555] placeholder-[#979797] placeholder:text-[10px] placeholder:font-medium"
                                    >
                                        <span className='placeholder-placeholderColor placeholder:text-[10px] placeholder:font-medium p-1'>{option?.label}</span>
                                        <button
                                            className="ml-1 text-[#555555] font-bold focus:outline-none"
                                            onClick={() => handleRemove(val)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                );
                            })
                        ) : (
                            <span className="text-[#979797] text-[10px]">{placeholder || "Select an option"}</span>
                        )}
                    </span>

                    {value.length > 0 && (
                        <div className="bg-[#D9D9D9] mr-2 rounded-md w-8 h-6 flex items-center justify-center text-[#555555]">
                            {value.length}
                        </div>
                    )}
                    <DownArrowIcon className={`transition-transform duration-200 mr-[10px] ${open ? 'rotate-180' : ''}`} />
                </div>
                {open && (
                    <div className="relative">
                        <div className="absolute z-10 w-full bg-white border border-[#EB7E39] rounded-md mt-1 shadow-lg p-2">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="w-full p-2 border border-checkBoxBorder rounded-[10px] focus:outline-none pr-8 h-[40px] text-[#555555] text-[12px] placeholder-[#979797] placeholder:text-[12px]"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <div className="absolute top-3 right-3">
                                    <SearchBoxIcon />
                                </div>
                            </div>

                            {/* Options List */}
                            <div className="max-h-24 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 mt-2">
                                {filteredOptions.length > 0 ? (
                                    filteredOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            className={`flex mb-1 p-1 items-center justify-between cursor-pointer rounded-md ${value.includes(option.value) ? 'bg-[#FFDBC4]' : 'hover:bg-[#FFDBC4]'}`}
                                        >
                                            <span className='text-[10px] p-2 text-[#555555]'>{option.label}</span>

                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent event bubbling
                                                    handleToggle(option); // Toggle selection
                                                }}
                                                className={`w-[14px] h-[14px] border-2 border-baseOrange flex items-center justify-center cursor-pointer ${value.includes(option.value) ? 'bg-baseOrange' : 'bg-transparent'} mr-4`}
                                            >
                                                {value.includes(option.value) && (
                                                    <svg width="8" height="8" viewBox="0 0 10 10" fill="#eb7e39" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 5L4 8L9 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                )}
                                            </div>

                                            <input
                                                type="checkbox"
                                                checked={value.includes(option.value)}
                                                readOnly
                                                className="hidden" // Hide the default checkbox
                                                id={`checkbox-${option.value}`} // Unique ID for label
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="p-2 text-[10px] text-gray-500">No options found</div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
    );
};

export default MultiSelect;