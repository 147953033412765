import React from 'react';
import { useState, useEffect, useRef } from "react";
const GroupComponent = ({ group, index, toggleVisibility, matchedValue, hoveredPoint }) => {
    const [parameter, value] = matchedValue ? matchedValue.split(": ") : [null, null];

    return (
        <div
            key={group.id}
            style={{
                backgroundColor: group.isVisible ? group.fillColor : "#f5f5f5", // Highlight active tab
                borderBottom: group.isVisible ? "3px solid #007BFF" : "none", // Active tab indicator
            }}
            className="text-center text-[10px] w-28 py-2 cursor-pointer hover:bg-gray-200 transition-all"
            onClick={() => toggleVisibility(index)}
        >
            <div>{group.penName}</div>
            {value && (
                <p className="!text-[10px]/[12px] text-left mt-1">
                    {value} <br />
                    <span
                        className="w-full text-[10px]/[12px] block overflow-hidden text-ellipsis whitespace-nowrap"
                        title={hoveredPoint?.title}
                    >
                        {hoveredPoint?.title}
                    </span>
                </p>
            )}
        </div>
    );
};




const HoveredDetailsComponent = ({ hoveredPoint, data, isDeviationModeOn }) => {
    const containerRef = useRef(null);
    const [itemsPerRow, setItemsPerRow] = useState(1); // Default to 1 item per row for safety

    // Calculate the number of items per row based on container width
    useEffect(() => {
        const calculateItemsPerRow = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const padding = 32; // Total horizontal padding (16px left + 16px right)
                const adjustedWidth = containerWidth - padding; // Adjust for padding
                const columnWidth = 180; // Approximate width of each column
                const maxItems = Math.floor(adjustedWidth / columnWidth);
                setItemsPerRow(maxItems > 0 ? maxItems : 1); // Ensure at least 1 item per row
            }
        };

        calculateItemsPerRow();

        // Recalculate on window resize
        window.addEventListener("resize", calculateItemsPerRow);
        return () => window.removeEventListener("resize", calculateItemsPerRow);
    }, []);

    // Add the title as the first data point in the body
    const dataWithTitle = [
        `${isDeviationModeOn ? `Delta values at : ${hoveredPoint.title}` : `Time: ${hoveredPoint.title} `}`,
        ...hoveredPoint.body,
    ];

    return (
        <div
            ref={containerRef}
            className={`text-[10px]/[12px] my-1  p-1 w-full  overflow-x-auto`}
            style={{ maxWidth: "98%" }}
        >
            <div className="flex flex-wrap gap-4">
                {dataWithTitle.map((item, index) => {
                    const [parameter, value] = item.includes(": ")
                        ? item.split(": ")
                        : [item, null];


                    const dataCheck = data.find((batch) =>
                        parameter.includes(batch.batchNumber?.toString().trim())
                    );

                    return (
                        <div
                            key={index}
                            className={`flex items-center gap-1 h-8 rounded-lg  p-4 ${index === 0 ? "" : "bg-white border border-gray-200"} flex-grow`}
                            style={{
                                width: `${100 / itemsPerRow}%`,
                                minWidth: "180px",
                                maxWidth: "180px",
                            }}
                        >

                            {dataCheck && (
                                <div
                                    style={{
                                        backgroundColor: parameter.includes("Error Lower") || parameter.includes("Error Upper")
                                            ? "	#ff0000cc"
                                            : dataCheck.color,
                                    }}
                                    className="w-4 h-4 rounded-full"
                                ></div>

                            )}

                            <div className="flex items-center gap-1">
                                <span className="font-medium">{parameter}:</span>
                                {value && <span>{value}</span>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};



const HoverAndPenListComponent = ({ data, toggleVisibility, hoveredPoint, isDeviationModeOn }) => {
    return (
        <div className="flex flex-col overflow-hidden ml-4 bg-bgBatchDetailsFilter w-[98%]">
            <div className="flex bg-gray-100 border-b">
                {data?.map((group, index) => {
                    const matchedValue = hoveredPoint?.body?.find((item) => {
                        const [parameter] = item.split(": ");
                        return parameter === group.penName;
                    });

                    return (
                        // <GroupComponent
                        //     key={group.id}
                        //     group={group}
                        //     index={index}
                        //     toggleVisibility={toggleVisibility}
                        //     matchedValue={matchedValue}
                        //     hoveredPoint={hoveredPoint}
                        // />
                        <></>);
                })}
            </div>

            {/* Details Section */}
            {hoveredPoint && (
                <HoveredDetailsComponent
                    hoveredPoint={hoveredPoint}
                    data={data}
                    toggleVisibility={toggleVisibility}
                    isDeviationModeOn={isDeviationModeOn}
                />
            )}
        </div>
    );
};

export default HoverAndPenListComponent;
