import React, { useState, useMemo, useCallback } from "react";
import { Switch, Tooltip } from "antd";
import MasterBatchSubmenu from "../MasterBatchSubmenu/index";
import NormalSidebar from "../NormalSidbar/index";
import { DownloadIcon } from "../../assets/svgs";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const Sidebar = ({
  resetZoom,
  handleColorChange,
  handleLineWidthChange,
  handleShowLogScale,
  batchesSent = [],
  isSidebarOpen,
  handleTimeIntervalChange,
  setShowDownloadOverlay,
  timeInterval,
  setTimeInterval,
  yAxisFormat,
  toggleVisibility,
  showLogScale,
  setYAxisFormat,
  errorBand,
  minMaxValue,
  deviationData=[],
  selectedPenId,
  handleMinMaxValueChange,
  setErrorBand,
  onDeviationModeChange,
  isDeviationModeOn,
}) => {
  const [openIndex, setOpenIndex] = useState(null);

  // Memoized derived data
  const { allBatchesEmpty, displayedBatches } = useMemo(() => {
    const allBatchesEmpty = batchesSent.every(
      (batch) => batch.batchResult && batch.batchResult.length === 0
    );

    const masterBatches = batchesSent.filter((batch) => batch.isMasterBatch);
    const normalBatches = batchesSent.filter((batch) => !batch.isMasterBatch);

    const allBatches = [...masterBatches, ...normalBatches];
    const displayedBatches = isDeviationModeOn
    ? [...masterBatches, ...(Array.isArray(deviationData) ? deviationData : [])]
    : allBatches;
  
    return { allBatchesEmpty, displayedBatches };
  }, [batchesSent, isDeviationModeOn, deviationData]);

  // Memoized callback for submenu toggle
  const handleSubmenuToggle = useCallback(
    (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    },
    [] // Stable reference
  );

  return (
    <div className="relative bg-[#f4f4f4] flex justify-end w-full h-[50vh] overflow-y-auto">
      <div className="transition-all duration-300 w-full h-full flex flex-col">
        <div className="px-4 py-2">
          {/* Toolbar */}
          <div className="w-full pl-6 flex justify-start gap-x-4 pt-4 items-center">
            <Tooltip title="Download">
              <button onClick={() => setShowDownloadOverlay(true)}>
                <DownloadIcon className="w-3 h-3 text-[#737373]" />
              </button>
            </Tooltip>
            <Tooltip title="Reset zoom">
              <button onClick={resetZoom}>
                <RestartAltIcon className="w-3 h-3 text-[#737373]" />
              </button>
            </Tooltip>
            <Tooltip title={`Deviation mode ${isDeviationModeOn ? "off" : "on"}`}>
              <Switch
                size="small"
                onChange={onDeviationModeChange}
                style={{
                  backgroundColor: isDeviationModeOn ? "#EB7E39" : undefined,
                }}
              />
            </Tooltip>
          </div>

          {/* Sidebar content */}
          {displayedBatches.map((data, index) => (
            <React.Fragment key={data.batchNumber}>
              {data.isMasterBatch && (
                <MasterBatchSubmenu
                  showLogScale={showLogScale}
                  handleShowLogScale={handleShowLogScale}
                  handleMinMaxValueChange={handleMinMaxValueChange}
                  data={data}
                  handleTimeIntervalChange={handleTimeIntervalChange}
                  setErrorBand={setErrorBand}
                  errorBand={errorBand}
                  selectedPenId={selectedPenId}
                  timeInterval={timeInterval}
                  setYAxisFormat={setYAxisFormat}
                  setTimeInterval={setTimeInterval}
                  yAxisFormat={yAxisFormat}
                  minMaxValue={minMaxValue}
                />
              )}
              <NormalSidebar
                data={data}
                OpenSubMenu={() => handleSubmenuToggle(index)}
                isDeviationModeOn={isDeviationModeOn}
                handleColorChange={handleColorChange}
                handleLineWidthChange={handleLineWidthChange}
                toggleVisibility={toggleVisibility}
                isOpen={openIndex === index}
                index={index}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
