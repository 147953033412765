import React, { useEffect, useCallback, useState } from 'react';
import CustomSelectWithDateRange from '../CustomSelectWithDate';
import CustomSearchableSelect from '../SelectWithSubOptionsSelectable';
import { generateRandomDarkColor } from '../../utils/color.utils.js';

const BatchFilter = React.memo(({
    batchList,
    batchOptionSelected,
    setBatchOptionsSelected,
    groupList,
    data,
    batchLoader,
    toggleVisibility,
    handleReset,
    groupSelectedList,
    setGroupSelectedList,
    setGroupList,
    setBatchesSent,
    groupOptionSelected,
    dataRange,
    groupData,
    setDateRange,
    handleFetchData,
    selectedPenId,
    setSelectedPenId,
    setTimeInterval,
    setGroupOptionsSelected,
    handleDateRender,
    setHandleDateRender,
    filterBtn = true,
}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [listTempSelected, setListTempSelect] = useState([]);

    const handleOptionChange = useCallback((newSelectedOptions) => {
        setBatchOptionsSelected(newSelectedOptions);
    }, [setBatchOptionsSelected]);

    const handleOptionChange2 = (newSelectedOptions) => {
        if (!newSelectedOptions || newSelectedOptions.length === 0) {
            setGroupOptionsSelected([]);
            return;
        }

        const selectedGroup = newSelectedOptions[0];
        if (selectedGroup) {
            setGroupOptionsSelected(newSelectedOptions);
        } else {
        }
    };

    useEffect(() => {
        generateRandomDarkColor.resetUsedColors()
        if (groupList.length > 0 && groupOptionSelected) {
            const newList = groupList
                .filter((group) => group.value === groupOptionSelected[0]?.groupId)
                .map((group) => ({
                    ...group,
                    penIds: group.penIds.map((pen) => ({
                        ...pen,
                        color: generateRandomDarkColor.getRandomColor(), // Assign a unique color
                    })),
                }));


            setGroupSelectedList(newList);
        } else {
            setGroupSelectedList([]);
        }
    }, [groupList, selectedPenId, groupOptionSelected]);

    const handleFunctionExecute = () => {

        handleFetchData();

    };

    return (
        <div className="pt-3">
            <div className={` ${groupSelectedList.length === 0 ? 'h-14' : 'h-14'} flex items-center ml-4 mr-2 w-[98%] py-1 mb-2  bg-bgBatchDetailsFilter`}>
                <div className="w-full px-2 flex gap-x-1 justify-between items-center">
                    <div className="w-full flex gap-x-2">
                        <CustomSelectWithDateRange
                            batchList={batchList}
                            batchOptionSelected={batchOptionSelected}
                            setBatchOptionsSelected={setBatchOptionsSelected}
                            dataRange={dataRange}
                            handleDateRender={handleDateRender}
                            setDateRange={setDateRange}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            listTempSelected={listTempSelected}
                            setListTempSelect={setListTempSelect}
                            label={"Add batches for comparison"}
                            timeLabel={"Select a date range"}
                            batchLoader={batchLoader}
                            setHandleDateRender={setHandleDateRender}
                            handleOptionChange={handleOptionChange}
                        />
                        <CustomSearchableSelect
                            options={groupList}
                            showLabel={false}
                            selectedOptions={groupOptionSelected}
                            onChange={handleOptionChange2}
                            handleDateRender={handleDateRender}
                            setHandleDateRender={setHandleDateRender}
                            setGroupOptionsSelected={setGroupOptionsSelected}
                            multiSelect={false}
                            selectMaxCount={1}

                            placeholder="Select group from the drop down"
                            showParameter={true}
                        />
                    </div>
                    <div className="w-4/12">
                        {/* <button
                            className="w-11/12 text-[14px] h-10 bg-baseOrange rounded-md text-white justify-end"
                            onClick={handleFunctionExecute}
                        >
                            Apply Filters
                        </button> */}
                    </div>
                </div>

            </div>
        </div>
    );
});

export default BatchFilter;
