import React, { useEffect, useRef, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import AddGroup from './AddGroup';
import EditGroup from './EditGroup';
import DeleteGroup from './DeleteGroup';
import Notification from '../Notifications/index';
import { NoUserIcon, SearchBoxIcon, DeletePenIcon, EditPenIcon } from '../../assets/svgs';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/path';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import getAPIMap from '../../routes/url/ApiUrls';
import Loader from '../loader/CustomLoader';

const DownArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 1.5L7.57226 6.21869C7.76995 6.37891 8.05643 6.3651 8.23779 6.18662L13 1.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const UpArrowIcon = ({ className, style }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M1.75 6.5L7.57226 1.78131C7.76995 1.62109 8.05643 1.6349 8.23779 1.81338L13 6.5"
      stroke="#737373"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const GroupPage = ({ groupId }) => {
  const {
    // fetchGroups,
    // getGroupDetailsById,
    // editGroup,
    // deleteGroup,
    // addNewGroup,
    // UpdateGroupPenDetail
  } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [deleteGroupOpen, setDeleteGroupOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedGroupType, setSelectedGroupType] = useState(''); // Default set to '' until fetched
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [groupTypes, setGroupTypes] = useState([]); // State for group types
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  const navigate = useNavigate();

  const addNewGroup = async (groupDetails) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const response =
        await axiosPrivate.post('/groups', groupDetails);

      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
      } else {
        console.error("Failed to add groups:", error.message);
      }
      throw error;
    }
  };
  const editGroup = async (groupId, updatedgroupDetails) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const apiUrl = getAPIMap("updateGroupById").replace(":id", groupId);

      const response = await axiosPrivate.put(apiUrl, updatedgroupDetails);

      return response.data;
    } catch (error) {
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
      } else {
        console.error("Failed to update Group:", error.message);
      }
      throw error;
    }
  };
  const getGroupDetailsById = async (groupId) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const response = await axiosPrivate.get(
        getAPIMap("getGroupById").replace(":id", groupId));
      return response.data;
    } catch (error) {
      console.error("Failed to fetch group details:", error);
      throw error;
    }
  };

  // Function to fetch group types from API
  const fetchGroupTypes = async () => {
    try {
      const response = await axiosPrivate.get(`${getAPIMap("getGroupType")}`);
      const data = response.data;
      setGroupTypes(data); // Assuming data is an array of group types
      setSelectedGroupType(data[0] || ''); // Set default to first fetched type (if available)
    } catch (error) {
      console.error('Failed to fetch group types:', error);
    }
  };

  useEffect(() => {
    // Fetching group types when the component mounts
    fetchGroupTypes();
  }, []);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      if (groupId) {
        try {
          const data = await getGroupDetailsById(groupId);
          setSelectedGroup(data);
        } catch (error) {
          console.error("Error fetching group details:", error);
        }
      }
    };
    fetchGroupDetails();
  }, [groupId]);
  const fetchGroups = async ({ sortBy = "name", type, limit = 100, page = 1 } = {}) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const apiUrl = getAPIMap("getGroups");
      const apiEndpoint = `${apiUrl}?sortBy=${sortBy}&type=${type.toLowerCase()}&limit=${limit}&page=${page}`;
      const response = await axiosPrivate.get(apiEndpoint);
      if (response?.data) {
        return response.data;
      } else {
        console.warn("No groups found for the specified criteria.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching groups:", error.message);
      return null;
    }
  };
  useEffect(() => {
    const getGroups = async () => {
      if (selectedGroupType) {
        await setLoading(true);
        try {
          const groupsByType = await fetchGroups({
            type: selectedGroupType, // Using the selected group type
            limit: 100,
            page: 1,
          });
          setGroups(groupsByType || []);
          setFilteredGroups(groupsByType || []);
        } catch (err) {
          setError("Error fetching groups");
        } finally {
          setLoading(false);
        }
      }
    };
    getGroups();
  }, [selectedGroupType]);

  const handleAddGroupOpen = () => setAddGroupOpen(true);
  const handleAddGroupClose = () => setAddGroupOpen(false);

  const handleEditGroupOpen = (group) => {
    setSelectedGroup(group);
    setSelectedGroupId(group.id);
    setEditGroupOpen(true);
  };

  const handleEditGroupClose = () => {
    setSelectedGroup(null);
    setSelectedGroupId(null);
    setEditGroupOpen(false);
  };

  const handleAddGroup = async (group) => {
    try {
      const addedGroup = await addNewGroup(group);
      setNotificationMessage('Group added successfully. Check group list.');
      setNotificationSeverity('success');
      setGroups((prevGroups) => [...prevGroups, addedGroup]);
    } catch (err) {
      setNotificationSeverity('error');
      setNotificationMessage('Error adding group');
    }
    setAddGroupOpen(false);
    setNotificationOpen(true);
    fetchGroupsAfterAction();
  };
  const UpdateGroupPenDetail = async (penId, updatedPen) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const response = await axiosPrivate.put(
        getAPIMap("updateGroupPenDetail").replace(":id", penId),
        updatedPen);

      return response.data;
    } catch (error) {
      console.error("Failed to update group pen details:", error);
      throw error;
    }
  };

  const handleUpdateGroup = async (penGroupId, updatedGroupDetails) => {
    try {
      const response = await UpdateGroupPenDetail(penGroupId, updatedGroupDetails);
      setNotificationMessage('Group added successfully. Check the group list.');
      setNotificationSeverity('success');
      const updatedGroup = response.groupDetail || response;

      setGroups((prevGroups) =>
        prevGroups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group))
      );
    } catch (error) {
      setNotificationSeverity('error');
      setNotificationMessage('Error updating group');
    }
    setEditGroupOpen(false);
    setNotificationOpen(true);
    fetchGroupsAfterAction();
  };

  const handleDeleteGroupOpen = (groupId) => {
    setSelectedGroupId(groupId);
    setDeleteGroupOpen(true);
  };

  const handleDeleteGroupClose = () => {
    setDeleteGroupOpen(false);
    setSelectedGroupId(null);
  };
  const deleteGroup = async (groupId) => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      await axiosPrivate.delete(getAPIMap("deleteGroup").replace(":id", groupId));


      return groupId;

    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
      } else {
        console.error("Failed to delete group:", error.message);
      }
      throw error;
    }
  };
  const deleteGroupHandler = async () => {
    try {
      await deleteGroup(selectedGroupId);
      setGroups((prevGroups) => prevGroups.filter((group) => group.id !== selectedGroupId));
      setNotificationMessage('Group deleted successfully.');
      setNotificationSeverity('success');
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'Something went wrong. We were not able to delete the group. Try again.';
      setNotificationMessage(errorMessage);
      setNotificationSeverity('error');
    }
    handleDeleteGroupClose();
    setNotificationOpen(true);
    fetchGroupsAfterAction();
  };

  const fetchGroupsAfterAction = async () => {
    try {
      await setLoading(true);

      const groupsByType = await fetchGroups({
        type: selectedGroupType,
        limit: 100,
        page: 1,
      });
      setGroups(groupsByType || []);
      setFilteredGroups(groupsByType || []);
    } catch (err) {
      setError("Error fetching updated groups");
    } finally {
      await setLoading(false);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleSelect = (groupType) => {
    setSelectedGroupType(groupType);
    setIsOpen(false);
  };

  const onFilter = (query) => {
    const filtered = groups.filter((group) =>
      group.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredGroups(filtered);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onFilter(query); // Filter immediately as the user types
  };

  const handleOverlayClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      navigate(PATHS.homePath); // Navigate back to the home route
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-opacity-70 bg-backgroundColor" ref={overlayRef} aria-hidden={true} onClick={handleOverlayClick}>
        <div className="w-3/12 md:w-2/12 h-[85.5%] mt-8 shadow-lg bg-white fixed left-24 top-8 bottom-2 rounded-[8px] transition-all duration-300 z-30" ref={containerRef} tabIndex={-1} aria-modal={true} onClick={(e) => { e.stopPropagation() }}>
          <div className="flex mt-2 justify-between items-center px-4">
            <h3 className="text-[12px]/[16px] font-semibold text-gray-800 ml-[-8px]">Group</h3>
            <div
              className="text-[30px] text-[#EB7E39] hover:cursor-pointer mr-[-5px]"
              onClick={handleAddGroupOpen}
            >
              +
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div className="relative flex-shrink-0">
              <div
                className={`border placeholder:text-[#A9A3A3] border-[#A9A3A3] outline-none border-t border-b border-l-0 border-r-0 pl-2
                  ${selectedGroupType ? 'bg-[#A7D49A] text-[#555555] text-[10px]' : 'bg-white text-[10px] text-[#555555]'}
                  cursor-pointer flex items-center justify-between h-8 w-24 `}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedGroupType || "Group Type"}
                {isOpen ? <UpArrowIcon className="mr-1" /> : <DownArrowIcon className="mr-1" />}
              </div>
              {isOpen && (
                <div className="absolute mt-1 bg-white border border-borderColorBox rounded shadow-lg w-full">
                  {groupTypes.length > 0 ? groupTypes.map((g, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelect(g)}
                      className="text-[10px] p-2 hover:bg-[#A7D49A] hover:text-[#555555] cursor-pointer"
                    >
                      {g}
                    </div>
                  )) : (
                    <div className="text-center text-[#555555] text-[12px] p-2">
                      No group types
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="relative flex-grow">
              <input
                type="text"
                className="w-full border-l border-[#A9A3A3] placeholder:text-[#A9A3A3] outline-none border-t border-b border-r-0 pr-8 pl-2 h-8 text-[10px] text-[#A9A3A3]"
                placeholder="Search Group"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <SearchBoxIcon />
              </div>
            </div>
          </div>
          <div className="overflow-y-auto  mt-2 h-5/6 max-h-[550px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
            {loading ? <Loader loading={loading} /> :
              filteredGroups.length === 0 ? (
                <div className="flex flex-col items-center py-20">
                  <NoUserIcon className="w-16 h-16 mb-2 text-gray-400" />
                  <p className="text-gray-600 text-[10px]">No groups to display</p>
                </div>
              ) : (
                <ul>
                  {filteredGroups.map((group) => (
                    <li
                      key={group.id}
                      className="flex text-[10px] border-b border-borderColorBox text-customGray justify-between items-center hover:bg-hoverOrange hover:border-b-0 hover:rounded-md p-2"
                    >
                      <div className='text-customGray text-[10px]/[18px]'>{group.name}</div>
                      <div className="flex gap-x-4">
                        <div onClick={() => handleDeleteGroupOpen(group.id)} className='hover:cursor-pointer'>
                          <DeletePenIcon />
                        </div>
                        <div onClick={() => handleEditGroupOpen(group)} className='hover:cursor-pointer'>
                          <EditPenIcon />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
          </div>
        </div>
      </div>

      <AddGroup
        open={addGroupOpen}
        handleClose={handleAddGroupClose}
        addGroup={handleAddGroup}
        existGroup={filteredGroups.map(group => group.name)}  
        editGroupPenDetails={handleUpdateGroup}

      />
      <EditGroup
        open={editGroupOpen}
        handleClose={handleEditGroupClose}
        groupId={selectedGroupId}
        existGroup={filteredGroups.map(group => group.name)}  // Pass the names of filtered groups

        editGroupPenDetails={handleUpdateGroup}
        setNotification={(message, severity) => {
          setNotificationMessage(message);
          setNotificationSeverity(severity);
          setNotificationOpen(true);
        }}
      />

      <DeleteGroup
        open={deleteGroupOpen}
        onClose={handleDeleteGroupClose}
        onDelete={deleteGroupHandler}
      />
      <Notification
        open={notificationOpen}
        message={notificationMessage}
        onClose={handleNotificationClose}
        severity={notificationSeverity}
      />
    </>
  );
};

export default GroupPage;
