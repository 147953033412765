// import React, { useDeferredValue, useEffect, useState } from "react";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { InfoIcon } from "../../assets/svgs";
// import CustomSelect from "../CustomSelect/index";
// import { formatOptions, timeIntervalOptions } from "../../features/batch-comparison/batch-comparison.constants";
// import { Tooltip } from "antd";

// const Index = ({
//     data,
//     yAxisFormat,
//     minMaxValue,
//     setYAxisFormat,
//     handleMinMaxValueChange,
//     minValue,
//     errorBand,
//     timeInterval,
//     setTimeInterval,
//     selectedPenId,
//     setErrorBand,
//     showLogScale,
//     handleShowLogScale,
//     maxValue,
// }) => {
//     const [isOpen, setIsOpen] = useState(true);
//     // console.log(selectedPenId)
//     const initialValues = {
//         minValue: minValue || "",
//         maxValue: maxValue || "",
//         timeInterval: timeInterval || null,
//     };

//     const handleOpen = () => {
//         setIsOpen(!isOpen);
//     };

//     const isLogScaleDisabled = yAxisFormat?.toLowerCase() !== "exponential";
//     const [min, setMin] = useState(() => minMaxValue?.min)
//     const [max, setMax] = useState(() => minMaxValue?.max)
//     const deferredMin = useDeferredValue(min);
//     const deferredMax = useDeferredValue(max);
//     console.log(`?? submenu `, minMaxValue, min, max);

//     useEffect(() => {
//         setMin(minMaxValue?.min)
//         setMax(minMaxValue?.max)
//     }, [minMaxValue?.min, minMaxValue?.max]);
//     useEffect(() => {
//         if (
//             !/^[-+]?\d*\.?\d+$/.test(deferredMin?.toString()) ||
//             !/^[-+]?\d*\.?\d+$/.test(deferredMax?.toString())
//         ) {
//             return console.log(`Non-numeric value is not allowed in min or max`);
//         }
//         handleMinMaxValueChange({ min: deferredMin, max: deferredMax });
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [deferredMin, deferredMax])
// console.log(data)
//     return (
//         <Formik
//             initialValues={initialValues}
//             onSubmit={(values) => {
//                 setTimeInterval(values.timeInterval);
//             }}
//             enableReinitialize
//         >
//             {({ setFieldValue, handleChange }) => (
//                 <Form className={`flex-col w-full mt-2`}>
//                     <div className="flex justify-between w-full items-center">
//                         <div className="flex gap-x-2">
//                             <div
//                                 className="rounded-full w-4 h-4"
//                                 style={{ backgroundColor: `${data.color}` }}
//                             ></div>
//                             <div className="underline text-[10px]/[14px] font-medium text-[#1E1B39]">
//                                 Master Batch
//                             </div>
//                         </div>
//                         <div className="text-[10px]/[20px] text-[#737373]">
//                             {data?.batchNumber}
//                         </div>
//                     </div>
//                     <div className="flex flex-col mt-3">
//                         <div className="flex gap-x-2 font-medium text-[10px]/[18px] text-[#191919] items-center justify-start">
//                             Error Band{" "}
//                             <Tooltip title="Any value that is not within the range of error band will be considered as a deviation.">
//                                 <div className="cursor-pointer">
//                                     <InfoIcon />
//                                 </div>
//                             </Tooltip>
//                         </div>
//                         <div className="flex gap-x-2 mt-1 items-center">
//                             <div className="border rounded-md flex gap-x-2 items-center">
//                                 <div
//                                     className="border rounded-md border-[#D5D7DA] px-1 cursor-pointer"
//                                     onClick={() => {
//                                         setErrorBand((prev) => prev - 1);
//                                     }}
//                                 >
//                                     -
//                                 </div>
//                                 <span className="text-[10px]/[18px] text-black">
//                                     {errorBand}
//                                 </span>
//                                 <div
//                                     className="border rounded-md border-[#D5D7DA] px-1 cursor-pointer"
//                                     onClick={() => {
//                                         setErrorBand((prev) => prev + 1);
//                                     }}
//                                 >
//                                     +
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="flex justify-between items-center mt-2">
//                         <div className="text-[10px]/[14px] text-[#1E1B39] font-medium">
//                             Selected Parameter
//                         </div>
//                         <div onClick={handleOpen} className="cursor-pointer">
//                             {isOpen ? (
//                                 <KeyboardArrowUpIcon className="text-[#737373]" />
//                             ) : (
//                                 <ChevronRightIcon className="text-[#737373] w-2 h-2" />
//                             )}
//                         </div>
//                     </div>
//                     {isOpen && (
//                         <>
//                             <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label
//                                     htmlFor="name"
//                                     className="text-[10px]/[20px] text-[#555555]"
//                                 >
//                                     Name
//                                 </label>
//                                 <input
//                                     type="text"
//                                     className="border rounded-sm w-9/12 text-[10px] opacity-80 h-4/6 text-[#979797] p-1 bg-[#F0F5EC] focus:outline:none"
//                                     placeholder=""
//                                     value={selectedPenId.pen_name}
//                                     disabled={true}
//                                 />
//                             </div>

//                             <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label
//                                     htmlFor="minValue"
//                                     className="text-[10px]/[20px] text-[#555555]"
//                                 >
//                                     Min Value
//                                 </label>
//                                 <Field
//                                     id="minValue"
//                                     name="minValues"
//                                     type="text"
//                                     className="focus:outline-none bg-[#F0F5EC] p-1 text-sm"
//                                     placeholder="Enter minimum value"
//                                     onChange={(e) => {
//                                         const value = e.target.value.trim();
//                                         // setFieldValue('minValue', value);
//                                         // handleMinMaxValueChange("min", value); // This updates the minValue in state
//                                         if (/[A-Za-z]/.test(value?.toString())) return;
//                                         setMin(value)
//                                     }}
//                                     value={min}
//                                 />
//                             </div>

//                             <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label
//                                     htmlFor="maxValue"
//                                     className="text-[10px]/[20px] text-[#555555]"
//                                 >
//                                     Max Value
//                                 </label>
//                                 <Field
//                                     id="maxValue"
//                                     name="maxValue"
//                                     type="text"
//                                     className="border rounded-sm w-9/12 text-black p-1 bg-[#F0F5EC] focus:outline-none text-sm"
//                                     onChange={(e) => {
//                                         const value = e.target.value.trim();
//                                         // setFieldValue("maxValue", value);
//                                         // handleMinMaxValueChange("max", value);
//                                         if (/[A-Za-z]/.test(value?.toString())) return;
//                                         setMax(value)
//                                     }}
//                                     value={max}
//                                 />
//                             </div>
//                             <CustomSelect
//                                 label={"Time interval"}
//                                 onChange={(value) => setTimeInterval(value)}
//                                 options={timeIntervalOptions}
//                                 valueToShow={timeInterval}
//                             />
//                             {/* <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label htmlFor="yAxisFormat" className="text-[10px]/[20px] text-[#555555]">Time interval</label>
//                                 <Field
//                                     id="timeInterval"
//                                     name="timeInterval"
//                                     as="select"
//                                     className="border rounded-sm w-6/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] hover:bg-baseOrange focus:outline-none"
//                                     onChange={(e)=>{setTimeInterval(e.target.value)}}
//                                 >
//                                     <option value={null}>None</option>
//                                     <option value={1}>1 minute</option>
//                                     <option value={5}>5 minutes</option>
//                                     <option value={15}>15 minutes</option>
//                                 </Field>
//                             </div> */}

//                             {/* <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label htmlFor="yAxisFormat" className="text-[10px]/[20px] text-[#555555]">Format</label>
//                                 <Field
//                                     id="yAxisFormat"
//                                     name="yAxisFormat"
//                                     as="select"
//                                     className="border rounded-sm w-6/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline-none"
//                                     onChange={handleYAxisFormatChange}
//                                 >
//                                     <option value="integer">Integer</option>
//                                     <option value="real">Real</option>
//                                     <option value="exponential">Exponential</option>
//                                 </Field>
//                             </div> */}
//                             <CustomSelect
//                                 label={"Format"}
//                                 onChange={(value) => {
//                                     setYAxisFormat(value);

//                                     // when the format is exponential, show the log scale automatically
//                                     if (value === "exponential") handleShowLogScale(true);
//                                     else handleShowLogScale(false);
//                                 }}
//                                 index={0}
//                                 options={formatOptions}
//                                 value={yAxisFormat}
//                             />

//                             <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
//                                 <label
//                                     htmlFor="logScale"
//                                     className="text-[10px]/[20px] text-[#555555]"
//                                 >
//                                     Log Scale
//                                 </label>
//                                 <input
//                                     id="logScale"
//                                     type="checkbox"
//                                     className={`${isLogScaleDisabled ? "cursor-not-allowed" : "cursor-pointer"
//                                         } w-4 h-4 text-[#000000] pl-1 bg-[#F0F5EC] border-2 border-[#000000]`}
//                                     checked={showLogScale}
//                                     disabled={isLogScaleDisabled}
//                                 // onChange={handleShowLogScale}
//                                 />
//                             </div>
//                         </>
//                     )}
//                 </Form>
//             )}
//         </Formik>
//     );
// };

// export default Index;
import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InfoIcon } from "../../assets/svgs";
import CustomSelect from "../CustomSelect/index";
import { formatOptions, timeIntervalOptions } from "../../features/batch-comparison/batch-comparison.constants";
import { Tooltip } from "antd";

const Index = ({
  data,
  yAxisFormat,
  minMaxValue,
  setYAxisFormat,
  handleMinMaxValueChange,
  errorBand,
  timeInterval,
  setTimeInterval,
  selectedPenId,
  setErrorBand,
  showLogScale,
  handleShowLogScale,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [values, setValues] = useState({
    min: minMaxValue?.min || "",
    max: minMaxValue?.max || "",
  });

  const isLogScaleDisabled = yAxisFormat?.toLowerCase() !== "exponential";

  useEffect(() => {
    setValues({
      min: minMaxValue?.min || "",
      max: minMaxValue?.max || "",
    });
  }, [minMaxValue]);

  const handleValueChange = (key, value) => {
    if (!/^-?\d*\.?\d*$/.test(value)) return;
    setValues((prev) => ({ ...prev, [key]: Number(value) }));
    handleMinMaxValueChange({ ...values, [key]: Number(value) });
  };

  return (
            <Formik
            >
                {({ setFieldValue, handleChange }) => (
                    <Form className={`flex-col w-full mt-2`}>
                        <div className="flex justify-between w-full items-center">
                            <div className="flex gap-x-2">
                                <div
                                    className="rounded-full w-4 h-4"
                                    style={{ backgroundColor: `${data.color}` }}
                                ></div>
                                <div className="underline text-[10px]/[14px] font-medium text-[#1E1B39]">
                                    Master Batch
                                </div>
                            </div>
                            <div className="text-[10px]/[20px] text-[#737373]">
                                {data?.batchNumber}
                            </div>
                        </div>
                        <div className="flex flex-col mt-3">
                            <div className="flex gap-x-2 font-medium text-[10px]/[18px] text-[#191919] items-center justify-start">
                                Error Band{" "}
                                <Tooltip title="Any value that is not within the range of error band will be considered as a deviation.">
                                    <div className="cursor-pointer">
                                        <InfoIcon />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="flex gap-x-2 mt-1 items-center">
                                <div className="border rounded-md flex gap-x-2 items-center">
                                    <div
                                        className="border rounded-md border-[#D5D7DA] px-1 cursor-pointer"
                                        onClick={() => {
                                            setErrorBand((prev) => prev - 1);
                                        }}
                                    >
                                        -
                                    </div>
                                    <span className="text-[10px]/[18px] text-black">
                                        {errorBand}
                                    </span>
                                    <div
                                        className="border rounded-md border-[#D5D7DA] px-1 cursor-pointer"
                                        onClick={() => {
                                            setErrorBand((prev) => prev + 1);
                                        }}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <div className="text-[10px]/[14px] text-[#1E1B39] font-medium">
                                Selected Parameter
                            </div>
                            <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
                                {isOpen ? (
                                    <KeyboardArrowUpIcon className="text-[#737373]" />
                                ) : (
                                    <ChevronRightIcon className="text-[#737373] w-2 h-2" />
                                )}
                            </div>
                        </div>
                        {isOpen && (
                            <>
                                <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
                                    <label
                                        htmlFor="name"
                                        className="text-[10px]/[20px] text-[#555555]"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="border rounded-sm w-9/12 text-[10px] opacity-80 h-4/6 text-[#979797] p-1 bg-[#F0F5EC] focus:outline:none"
                                        placeholder=""
                                        value={selectedPenId?.pen_name}
                                        disabled={true}
                                    />
                                </div>
    
              {["min", "max"].map((key) => (
                <div className="py-2 bg-[#E5E6E8] my-2">
                <div key={key} className="flex  justify-between px-1 items-center mt-2">
                  <label htmlFor={key} className="text-[10px]  text-[#555555] capitalize">
                    {key} Value
                  </label>
                  <Field
                    id={key}
                    name={key}
                    type="text"
                    className="input bg-[#F0F5EC] w-8/12 mr-1 pl-2 text-[12px] focus:outline-none"
                    value={values[key]}
                    onChange={(e) => handleValueChange( key, e.target.value)}
                  />
                </div>
                </div>
              ))}

                                <CustomSelect
                                    label={"Time interval"}
                                    onChange={(value) => setTimeInterval(value)}
                                    options={timeIntervalOptions}
                                    valueToShow={timeInterval}
                                />
                                {/* <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
                                    <label htmlFor="yAxisFormat" className="text-[10px]/[20px] text-[#555555]">Time interval</label>
                                    <Field
                                        id="timeInterval"
                                        name="timeInterval"
                                        as="select"
                                        className="border rounded-sm w-6/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] hover:bg-baseOrange focus:outline-none"
                                        onChange={(e)=>{setTimeInterval(e.target.value)}}
                                    >
                                        <option value={null}>None</option>
                                        <option value={1}>1 minute</option>
                                        <option value={5}>5 minutes</option>
                                        <option value={15}>15 minutes</option>
                                    </Field>
                                </div> */}
    
                                {/* <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
                                    <label htmlFor="yAxisFormat" className="text-[10px]/[20px] text-[#555555]">Format</label>
                                    <Field
                                        id="yAxisFormat"
                                        name="yAxisFormat"
                                        as="select"
                                        className="border rounded-sm w-6/12 h-4/6 text-[#000000] pl-1 bg-[#F0F5EC] focus:outline-none"
                                        onChange={handleYAxisFormatChange}
                                    >
                                        <option value="integer">Integer</option>
                                        <option value="real">Real</option>
                                        <option value="exponential">Exponential</option>
                                    </Field>
                                </div> */}
                                <CustomSelect
                                    label={"Format"}
                                    onChange={(value) => {
                                        setYAxisFormat(value);
    
                                        // when the format is exponential, show the log scale automatically
                                        if (value === "exponential") handleShowLogScale(true);
                                        else handleShowLogScale(false);
                                    }}
                                    index={0}
                                    options={formatOptions}
                                    value={yAxisFormat}
                                />
    
                                <div className="flex justify-between bg-[#E5E6E8] items-center px-1 py-2 mt-2">
                                    <label
                                        htmlFor="logScale"
                                        className="text-[10px]/[20px] text-[#555555]"
                                    >
                                        Log Scale
                                    </label>
                                    <input
                                        id="logScale"
                                        type="checkbox"
                                        className={`${isLogScaleDisabled ? "cursor-not-allowed" : "cursor-pointer"
                                            } w-4 h-4 text-[#000000] pl-1 bg-[#F0F5EC] border-2 border-[#000000]`}
                                        checked={showLogScale}
                                        disabled={isLogScaleDisabled}
                                    // onChange={handleShowLogScale}
                                    />
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
        );
};

export default Index;
