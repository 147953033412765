import { useState, useRef, useEffect } from 'react';

const CustomSelect = ({ value, index, onChange, label, options, valueToShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle selecting an option
  const handleSelect = (option) => {

    setSelectedValue(option.label);
    onChange(option.value, index); // Use value for logic
    setIsOpen(false);
  };

  return (
    <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
      <label className="flex items-center text-[10px]/[20px] text-[#555555] ">{label}</label>

      <div
        ref={selectRef}
        className="relative w-6/12 bg-[#F0F5EC] p-2 rounded-sm cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <span className="capitalize text-[10px]">{selectedValue ? selectedValue : valueToShow}</span>
          <span className="text-gray-500">{isOpen ? '▲' : '▼'}</span>
        </div>

        {isOpen && (
          <div className="absolute bg-white border border-gray-300 rounded-sm w-full mt-1 z-10">
            {options.map((option, index) => (
              <div
                key={index}
                className="p-2 cursor-pointer hover:bg-hoverOrange text-[10px]"
                onClick={() => handleSelect(option)}
              >
                {option.value==="logarithmic"?"Exponential":option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
