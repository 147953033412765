import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DeleteGroup = ({ open, onClose, onDelete, disableBackdropClick }) => {
    return (
<Dialog open={open} onClose={disableBackdropClick ? null : onClose} disableBackdropClick={disableBackdropClick} sx={{
            '& .MuiPaper-root': {
                borderRadius: '8px',
            },
        }}>
                        <IconButton
                onClick={onClose}
                size="small"
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#6E7E91',
                    padding: '4px',
                }}
            >
                <CloseIcon sx={{fontSize:"18px"}} />
            </IconButton>

            <DialogContent>
                <Typography>

                    <b className='text-[14px]'>Are you sure to delete this group?</b>
                    <br />
                    <span style={{ marginTop: '8px', display: 'block',fontSize:"12px" }}>
                        This action cannot be undone. This will permanently delete the group and remove it from group.</span>
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                    sx={{
                        color: '#0F172A',
                        border: '1px solid #D9D9D9',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onDelete}
                    sx={{
                        backgroundColor: '#F57C00',
                        color: 'white',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#E86800',
                        },
                    }}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteGroup;
