import React, { useEffect, useRef, useState } from 'react';
import getAPIMap from '../routes/url/ApiUrls';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import { DeletePenIcon, EditPenIcon, NoPenListData, SearchBoxIcon, SmileyIconSuccess, SuccessIcon } from '../assets/svgs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddPens from '../components/PenlistComps/AddPens';
import EditPens from '../components/PenlistComps/EditPens';
import DeletePens from '../components/PenlistComps/DeletePens';
import CustomModal from '../components/CustomModal/index';
import { PATHS } from '../routes/path';
import { CircularProgress } from "@mui/material"

const modalContextInitialState = {
    text: "",
    title: "",
    icon: null,
    isError: false,
};

const DetailedSidebar = () => {
    const containerRef = useRef(null);
    const overlayRef = useRef(null); // Add reference for the new outer overlay div
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true)
    const [penlistData, setPenlistData] = useState([]);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");
    const [searchParams, setSearchParams] = useSearchParams({ action: "view" });
    const [action, setAction] = useState("view");
    const id = searchParams.get("id");
    const [originalPenList, setOriginalPenList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [penSearch, setPenSearch] = useState("")
    const [modalContext, setModalContext] = useState(modalContextInitialState);

    const axiosPrivate = useAxiosPrivate();

    // Fetch Penlist Data
    useEffect(() => {
        const getAllPenList = async () => {
            try {
                let url = getAPIMap("penlist");
                if (search) {
                    url += `?name=${search}`;
                }
                const response = await axiosPrivate.get(url);
                setPenlistData(response.data);
                setOriginalPenList(response.data)
            } catch (error) {
                setError('Failed to load Penlist data');

            } finally {
                setLoader(false)

            }
        };

        getAllPenList();
    }, [action]);

    const handleOverlayClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            navigate(PATHS.homePath); // Navigate back to the home route
        }
    };

    const handleView = (action, id = null) => {
        setAction(action);
        setSearchParams({ action, id });
    };


    useEffect(() => {
        if (!searchParams.has("action")) {
            setSearchParams({ action: "view" });
            setAction("view");
        } else {
            setAction(searchParams.get("action"));
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (showModal) {
            setSearchParams({ action: "success" });
        }
    }, [showModal]);

    const handleTextBoxChange = (event) => {
        const search = event.target.value
        
        setSearch(event.target.value);
        setPenlistData(search.trim() ? penlistData.filter((pen) => pen.pen_name.toString().toLowerCase().includes(search.toLowerCase())) : originalPenList)
    };


    return (
        <>

            (
            <div
                className="fixed inset-0 flex items-center justify-center bg-backgroundColor bg-opacity-80 backdrop-blur-[5px]"
                ref={overlayRef} aria-hidden={true} onClick={handleOverlayClick}
            >
                <div className="w-3/12   md:w-2/12 h-[85.5%] mt-8 shadow-lg bg-white fixed left-24 top-8 bottom-2 rounded-[8px] transition-all duration-300 z-30" ref={containerRef} tabIndex={-1} aria-modal={true} onClick={(e) => { e.stopPropagation() }} >

                    <div className="flex mt-2 justify-between items-center px-4">
                        <h3 className="font-semibold text-gray-800 text-[12px]">Pen List</h3>
                        <div
                            className="text-[30px] text-[#EB7E39] hover:cursor-pointer"
                            onClick={() => handleView("add")}
                        >
                            +
                        </div>
                    </div>
                    <div className="mt-2 relative">
                        <input
                            type="text"
                            className="w-full border text-[10px]/[18px] h-8 text-[#555555] placeholder:text-[#A9A3A3] outline-none border-top-1 border-b-1 border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[10px]/[18px]"
                            placeholder="Search Pen"
                            value={search}
                            onChange={handleTextBoxChange}
                        />
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <SearchBoxIcon />
                        </div>
                    </div>

                    <div className="mt-1 h-5/6 overflow-y-auto pen-list-scrollbar">
                        {loader ? <div className=' w-full h-full flex justify-center items-center -mt-10'>
                            <CircularProgress
                                size={70}
                                sx={{
                                    color: "#EB7E39",
                                }}

                            />
                        </div> : penlistData?.length === 0 ? (
                            <div className="flex w-full h-full justify-center items-center -mt-10">
                                <NoPenListData className="w-16 h-16" />
                            </div>
                        ) : (
                            <ul className="px-2">
                                {penlistData?.map((pen) => (
                                    <li
                                        key={pen.id}
                                        className="flex text-[10px] border-b border-borderColorBox text-customGray justify-between items-center hover:bg-hoverOrange hover:border-b-0 hover:rounded-md p-2"
                                    >
                                        <div className='text-customGray text-[10px]/[18px]'>{pen.pen_name}</div>
                                        <div className="flex gap-x-4">
                                            <div onClick={() => handleView("delete", pen.id)} className='hover:cursor-pointer'>
                                                <DeletePenIcon />
                                            </div>
                                            <div onClick={() => handleView("edit", pen.id)} className='hover:cursor-pointer'>
                                                <EditPenIcon />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            )  {action === "add" ? (
                <AddPens
                    handleView={handleView}
                    ref={containerRef}
                    setModalContext={setModalContext}
                    setShowModal={setShowModal}
                    setSearchParams={setSearchParams}
                />
            ) : action === "edit" ? (
                <EditPens
                    penId={id}
                    setSearchParams={setSearchParams}
                    ref={containerRef}
                    setModalContext={setModalContext}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            ) : action === "delete" ? (
                <DeletePens
                    penId={id}
                    setSearchParams={setSearchParams}
                    ref={containerRef}
                    setModalContext={setModalContext}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            ) : null}

            <CustomModal
                showModal={showModal}
                modalContext={modalContext}
                icon={modalContext?.icon || <SuccessIcon />}
                closeModal={() => { setShowModal(false); setModalContext({ ...modalContextInitialState }); }}
                navigate={() => { setSearchParams({ action: "view" }); setShowModal(false); setModalContext({ ...modalContextInitialState }); }}
            />
        </>
    );

};

export default DetailedSidebar;
