import React from 'react';

const HoverAndPenListComponent = ({ data, handleToggleDataset, hoveredPoint }) => {
    return (
        <div className='flex items-center'>
            <div className={`flex flex-col items-center justify-center ${hoveredPoint ? "w-24" : "hidden"} space-y-1`}>
                {hoveredPoint && (
                    <>
                        <span className="text-[10px] text-center font-medium">
                            Date and Time
                        </span>
                        <span
                            className="w-full text-[10px] leading-[12px] text-center block overflow-hidden text-ellipsis whitespace-nowrap"
                            title={hoveredPoint?.title}
                        >
                            {hoveredPoint?.title}
                        </span>
                    </>
                )}
            </div>

            <div className="flex bg-gray-200 rounded-lg overflow-hidden ml-2">

                {data.map((group, index) => {
                    // Check if hoveredPoint exists and map over body
                    const matchedValue = hoveredPoint?.body?.find((item) => {
                        const [parameter] = item.split(": ");
                        
                        return parameter === group.penName;
                    });

                    const [parameter, value] = matchedValue
                        ? matchedValue.split(": ")
                        : [null, null];

                    return (
                        <div className='flex '>

                            <div
                                key={group.id}
                                style={{
                                    backgroundColor: group.isVisible ? `${group.fillColor}` : null,
                                }}
                                className="text-white text-[10px] !w-24 p-2  cursor-pointer flex-col items-center justify-center"
                                onClick={() => handleToggleDataset(index)}
                            >
                                <p className='text-center '>{group.penName}</p>
                                {value && (
                                    <p className="!text-[10px]/[18px] text-center">
                                        {value} <br />

                                    </p>
                                )}
                            </div>
                        </div>

                    );
                })}
            </div>
        </div>
    );
};

export default HoverAndPenListComponent;
