import React from 'react';
import BatchComparisonSidebar from '../BatchComparisonSidebar/index';
import DownloadOverlay from '../DownloadOverlay/index';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const SidebarComponent = ({
  isSidebarOpen,
  setIsSidebarOpen,
  ...restProps
}) => {
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  return (
    <div className={`${restProps.batchesSent.length>0?"block":"hidden"}`}>
      <div
        className={`absolute  ${restProps.hoveredPoint===null?"top-36":"top-52"} right-4 z-30  h-full transition-all duration-300 ${
          isSidebarOpen&&restProps.batchesSent.length>0 ? 'w-[25%]' : 'w-0'
        }`}
      >
        <button
          className={`absolute top-4 z-10 ${
            isSidebarOpen&&restProps.batchesSent.length>0 ? 'left-full -translate-x-full' : 'right-0'
          } bg-gray-200 p-2 rounded-r-md shadow-md transition-all duration-300`}
          onClick={toggleSidebar}
          title="Toggle sidebar"
        >
          <ArrowLeftIcon
            className={`text-gray-700 transform ${
              isSidebarOpen&&restProps.batchesSent.length>0 ? 'rotate-180' : 'hidden'
            } ${restProps.batchesSent.length>0?"block":"hidden"}`}
          />
        </button>

        {isSidebarOpen && (
          <div className="w-full bg-white h-full">
            <BatchComparisonSidebar {...restProps}  />
          </div>
        )}
      </div>

      {restProps.showDownloadOverlay && (
        <DownloadOverlay
          handleReportDownload={restProps.fetchBatchComparisonReportAndDownload}
          downloadChart={restProps.handleDownload}
          showPdf={restProps.isDeviationModeOn}
          handleCancel={() => restProps.setShowDownloadOverlay(false)}
        />
      )}
    </div>
  );
};


export default SidebarComponent;
