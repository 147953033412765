import React from 'react'

function ConnectionFooter(props) {
    const { handleOkayClick, handleCancelClick, isEditMode = '' } = props;

    return (
        <div className='flex w-full gap-x-4 mt-3 justify-end'>
            <button type='button' className='w-20 h-10 flex justify-center items-center border border-borderColorBox rounded-md text-customGray text-[14px]' onClick={handleCancelClick}>Cancel</button>
            <button
                type={isEditMode ? "submit" : "button"}
                onClick={handleOkayClick} // Handle the okay button click
                // className={`flex justify-center items-center w-24 h-10 bg-baseOrange text-[16px] text-white rounded-md }`}
                className={`flex justify-center items-center w-auto text-nowrap px-3 h-10 bg-baseOrange text-[14px] text-white rounded-md }`}
            >
                {isEditMode ? "Edit and Save" : "Okay"}
            </button>
        </div>
    )
}

export default ConnectionFooter