export const PATHS = {
    login: "/sign-in",
    role: "/role",
    addRole: "/role/add-role",
    user: "/user",
    homePath: '/',
    addUser: "/user/add-user",
    userUpload: "/user/upload",
    dbConnection: "/database",
    penlist: `/penlist`,
    groupPath: `/group`,
    viewDB:'/database/viewdb',
    masterBatch:`/master-batch`
};
