import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import NoFiltersScreen from '../components/NoFiltersScreen';
import BatchComparisonNavbar from '../components/BatchComparisonNavbar';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import BatchComparisonGraph from '../components/BatchComparisonGraph/index';
import BatchComparisonSidebar from '../components/BatchComparisonSidebar'
import DownloadOverlay from '../components/DownloadOverlay/index'
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import html2canvas from 'html2canvas';
import { CircularProgress } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { calculateMinMaxValues } from '../utils/batchcomparison.utils';
import DataPointsRender from '../components/DataPointsSidebar/index'
import { batchComparitionData } from '../assets/data';
import { generateRandomDarkColor } from '../utils/color.utils.js';
import BatchComparisonDeviationGraph from '../features/batch-comparison/components/BatchComparisonDeviationGraph.jsx';
import { prepareChartData } from '../features/batch-comparison/utils/batch-comparison.utils.js';
import BatchComparisonSidebarFull from '../components/BatchComparisonFinalComps/BatchComparisonSidebarFull.jsx';
import HoverAndPenListComponent from '../components/BatchComparisonFinalComps/HoverAndPenListComponent.jsx'
dayjs.extend(utc)

const BatchComparison = () => {
    const chartRef = useRef(null);
    const [data, setData] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [batchOptionSelected, setBatchOptionsSelected] = useState([]);
    const [batchesSent, setBatchesSent] = useState([]);
    const [timeInterval, setTimeInterval] = useState(15)
    const [showLogScale, setShowLogScale] = useState(false)
    const [minMaxValue, setMinMaxValue] = useState({ min: null, max: null, });
    const [batchLoader, setBatchLoader] = useState(false)
    const [hoveredPoint, sethoveredPoint] = useState(null)
    const [graphLoader, setGraphLoader] = useState(false)
    const [dataResponse, setDataResponse] = useState(false)
    const [errorBand, setErrorBand] = useState(5)
    const [masterBatchId, setMasterBatchId] = useState("")
    const [yAxisFormat, setYAxisFormat] = useState('integer');
    const axiosPrivate = useAxiosPrivate();
    const [dateRange, setDateRange] = useState([null, null]);
    const [groupList, setGroupList] = useState([]);
    const [groupSelectedList, setGroupSelectedList] = useState([])
    const [selectedPenId, setSelectedPenId] = useState(null)
    const [groupIdSelected, setGroupIdSelected] = useState(null)
    const [groupOptionSelected, setGroupOptionsSelected] = useState([]);
    const [handleDateRender, setHandleDateRender] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const [selectAndZoom, setSelectAndZoom] = useState(false)
    const [showDownloadOverlay, setShowDownloadOverlay] = useState(false)


    const [isDeviationModeOn, setIsDeviationModeOn] = useState(false);
    const [deviationData, setDeviationData] = useState(null);
    function onDeviationModeChange() {
        setIsDeviationModeOn((prev) => !prev);
        fetchDeviationData();
    }
    function fetchDeviationData() {
        sethoveredPoint(null)
        const startDateTime = dayjs(dateRange[0]).utc().format();
        const endDateTime = dayjs(dateRange[1]).utc().format();
        const batchNumbers = batchOptionSelected.map(batch => batch.toString());

        if (!selectedPenId || !startDateTime || !startDateTime || errorBand === undefined) {
            return;
        }
        axiosPrivate.post(getAPIMap("getDeviationGraphDetails"), {
            "penId": selectedPenId?.pen_id,
            "startDateTime": startDateTime,
            "endDateTime": endDateTime,
            "comparisonBatchNumbers": batchNumbers,
            "timeDiff": timeInterval?.toString()?.toLowerCase() === "none" ? null : timeInterval,
            "errorBand": errorBand
        }).then((res) => {
            setDeviationData(res.data.data?.map((batch) => {
                // return ({
                //     ...batch,
                //     color: batchesSent.find((data) => data?.batchNumber?.toString() === batch?.batchNumber?.toString())?.color
                // })
                generateRandomDarkColor.resetUsedColors();
                const modifiedBatch = {
                    ...batch,
                    color: generateRandomDarkColor.getRandomColor(),
                    lineWidth: 1,
                    errorband: batch?.isMasterBatch ? errorBand : null,
                    visibility: true,
                };
                return modifiedBatch;
            }));

        }).catch((error) => {
            console.error("Error fetching data:", error);
        });
    }
    useEffect(() => {
        if (isDeviationModeOn) {
            fetchDeviationData()
        }
    }, [errorBand, dateRange, batchOptionSelected, selectedPenId, timeInterval])

    const fetchBatchList = useCallback(async () => {
        setBatchLoader(true)
        try {
            if (!dateRange) {
                return console.error("Invalid date range:", dateRange);
            }
            const [startDate, endDate] = dateRange;
            if (startDate === null || endDate === null || startDate === "Invalid Date" || endDate === "Invalid Date") {
                return console.error("Invalid date range:", dateRange);
            }
            const startDateTime = dayjs(startDate).utc().format();
            const endDateTime = dayjs(endDate).utc().format();

            if (!dayjs(startDateTime).isValid() || !dayjs(endDateTime).isValid()) {
                console.error("Invalid date format after conversion to UTC");
                return;
            }

            const response = await axiosPrivate.get(
                `${getAPIMap("listBatches")}?startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`
            );
            const batches =
                response?.data?.data?.map((batch) => ({
                    label: batch.id,
                    value: batch.id,
                    details: batch.idWithDate,
                })) || [];

            setBatchList(batches);
        } catch (error) {
            console.error("Error fetching batch list:", error);
            if (error?.response?.data?.message) alert(`Error in fetch batch list: \n` + error.response.data.message);
            setBatchList([]);
            setBatchLoader(false)
        } finally {
            setBatchLoader(false)
        }
    }, [axiosPrivate, dateRange]);



    const handleFetchData = async () => {
        const batchNumbers = batchOptionSelected.map(batch => batch.toString());
        setData([])
        generateRandomDarkColor.resetUsedColors();
        if (selectedPenId === null || dateRange[0] === null || dateRange[1] === null || batchNumbers.length === 0 || groupOptionSelected.length === 0) {
            return;
        }
        setGraphLoader(true)
        sethoveredPoint(null)

        const body = {
            penId: selectedPenId?.value,
            startDateTime: dateRange[0],
            endDateTime: dateRange[1],
            batchNumbers: batchNumbers,
            groupId: groupOptionSelected[0]?.groupId?.toString(),
            timeDiff: timeInterval
        };

        const groupListChanged = groupList?.filter((d) => d?.value?.toString() === groupOptionSelected[0]?.groupId?.toString());

        setBatchesSent(groupListChanged);

        try {

            const response = await axiosPrivate.post(getAPIMap("batchComparison"), body);

            const responseData = response.data.data;


            if (responseData.length === 0) {
                setData([]);
                return;
            }

            generateRandomDarkColor.resetUsedColors();

            const modifiedBatchData = responseData.map((batch, index) => {
                const modifiedBatch = {
                    ...batch,
                    color: generateRandomDarkColor.getRandomColor(),
                    lineWidth: 1,
                    errorband: batch.isMasterBatch ? errorBand : null,
                    visibility: true,
                };

                return modifiedBatch;
            });


            setData(modifiedBatchData);

        } catch (error) {
            console.error("Error fetching data:", error);
            setGraphLoader(false)
            if (error?.response?.data?.error) alert(error?.response?.data?.error);
        } finally {
            setGraphLoader(false)
        }

    };

    useEffect(() => {

        if (selectedPenId) {
            handleFetchData()
        }


    }, [selectedPenId?.pen_id, timeInterval])
    const fetchGroupList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("groupByUserType"));
            const groups = response?.data?.map(group => ({
                label: group.name,
                value: group.id,
                penIds: group.penList,
            })) || [];
            setGroupList(groups);
        } catch (error) {
            console.error("Error fetching group list:", error);
        }
    }, [axiosPrivate]);

    const getMasterBatchDetails = async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("mastBatchPath"))
            setMasterBatchId(response.data.batch_id)
        } catch (err) {
        }
    }

    useEffect(() => {
        fetchBatchList();
        fetchGroupList();
        generateRandomDarkColor.resetUsedColors()
    }, [dateRange]);

    useEffect(() => {
        getMasterBatchDetails()
    }, [])

    const filteredBatchList = useMemo(() => {

        return batchList.filter(batch => batch.value.toString() !== masterBatchId.toString());
    }, [batchList, masterBatchId, dateRange]);


    useEffect(() => {

        if (data?.length || deviationData?.length) {
            let payload = {};
            if (isDeviationModeOn) {
                payload = { data: deviationData, displayFormat: yAxisFormat?.toLowerCase(), errorBand, valueKey: 'difference' }
            } else {
                const valueKey = Object.keys(data?.[0]?.batchResult?.[0] || {}).filter(key => key !== "DATE_TIME" && key !== "BATCH_NUMBER" && key !== "minutesPassed" && key.toLowerCase() !== "date_time")?.[0] || '';
                payload = { data, displayFormat: yAxisFormat?.toLowerCase(), errorBand, valueKey: valueKey }
            }

            const { yAxisMin, yAxisMax } = prepareChartData(payload)
            setMinMaxValue({ min: Number(yAxisMin), max: Number(yAxisMax) })
        }

    }, [data, deviationData, isDeviationModeOn, yAxisFormat]);


    const handleDownload = () => {
        setShowDownloadOverlay(false)
        downloadChart()
    }


    const downloadChart = async () => {
        const element = document.getElementById('chart-container');
        const d = new Date()
        const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
        const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
        const filename = `${"BatchComparison"}-${dateDownload}-${timeDownload}.jpeg`;

        if (element) {
            const width = element.offsetWidth;
            const height = element.offsetHeight;

            const canvas = await html2canvas(element, {
                useCORS: true, // Handle cross-origin images correctly
                backgroundColor: 'white', // Set a white background color
                width: width, // Set the canvas width
                height: height, // Set the canvas height
                scale: 2, // Increase scale for higher resolution (optional)
            });

            const dataURL = canvas.toDataURL('image/jpeg', 1.0);

            const link = document.createElement('a');
            link.href = dataURL; // Set the link's href to the data URL
            link.download = filename; // Specify the downloaded file name
            document.body.appendChild(link); // Append the link to the document
            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up the DOM

            setShowDownloadOverlay(false);
        } else {
            console.error("Element to capture is not available.");
        }
    };
    useEffect(() => {
        if (timeInterval > 0) {
            handleFetchData();
        }
    }, [timeInterval]);
    const resetZoom = () => {

        if (chartRef.current) {
            chartRef.current.resetZoom()
        } else {
            console.error("Can't reset the zoom")
        }

    }
    const handleReset = () => {
        setData([]);
        setBatchOptionsSelected([]);
        setGroupOptionsSelected([]);
        setDateRange([null, null]);
        setBatchesSent([]);
        generateRandomDarkColor.resetUsedColors()
        setDataResponse(false);
    };

    const toggleVisibility = (index) => {
        if (isDeviationModeOn) {
            setDeviationData((prev) => {
                const updatedBatches = prev.map((batch, i) => {
                    if (i === index-1) {
                        return { ...batch, visibility: !batch.visibility }; // Immutable update
                    }
                    return batch;
                });
                return updatedBatches;
            });
        } else {
            setData((prevBatches) => {
                const updatedBatches = prevBatches.map((batch, i) => {
                    if (i === index) {
                        return { ...batch, visibility: !batch.visibility }; // Immutable update
                    }
                    return batch;
                });
                return updatedBatches;
            });
        }
    };
    


    const handleYAxisFormatChange = (event) => {
        if (event === "logarithmic") {
            setYAxisFormat("exponential");
        } else {
            setYAxisFormat(event); // Update to the selected format
        }
    };

    const handleTimeIntervalChange = (event) => {
        setTimeInterval(event.target.value)
    }
    const handleColorChange = (index, event, data) => {
        const newColor = event.target.value;
        setData(prevBatches => {
            const updatedBatches = [...prevBatches];
            updatedBatches[index].color = newColor;
            return updatedBatches;
        });

        if (isDeviationModeOn) {
            setDeviationData((prev) => prev?.map((batch) => {
                if (batch.batchNumber === data.batchNumber) batch.color = newColor;
                return batch
            }))
        }
    };
    useMemo(() => {
        handleFetchData()
    }, [timeInterval, axiosPrivate])

    const handleLineWidthChange = (index, event) => {
        const newLineWidth = event.target.value.replace('px', '');
          if (!/^-?\d*\.?\d*$/.test(newLineWidth)) return;
        setData(prevBatches => {
            const updatedBatches = [...prevBatches];
            updatedBatches[index].lineWidth = newLineWidth;
            return updatedBatches;
        });
    };
    const handleShowLogScale = (val) => {
        setShowLogScale(val)
    }
    const handleMinMaxValueChange = (type, newValue) => {
        if (typeof type === "object") return setMinMaxValue(type)

        setMinMaxValue((prev) => {
            const newValueParsed = (newValue === null || newValue === "") ? 0 : Number(newValue);

            if (!isNaN(newValueParsed)) {

                return {
                    ...prev,
                    [type]: newValueParsed
                };
            } else {
                console.error(`Invalid ${type} value:`, newValue);
                return prev;
            }
        });
    };

    useEffect(() => {
        if (groupIdSelected || groupOptionSelected.length > 0) {
            setData([])
            generateRandomDarkColor.resetUsedColors();
            handleFetchData();
        }
    }, [groupIdSelected, groupOptionSelected]);
    const handleSelectAndZoom = () => {
        setSelectAndZoom(!selectAndZoom)
    }
    const fetchBatchComparisonReportAndDownload = async () => {
        try {
            const batchNumbers = batchOptionSelected.map(batch => batch.toString());
            const d = new Date()
            const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
            const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
            const filename = `${"BatchComparison"}-${dateDownload}-${timeDownload}.pdf`;
            const now = new Date()
            const day = String(now.getDate()).padStart(2, '0'); // Add leading zero if needed
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = now.getFullYear();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            // Combine into the desired format
            const formattedDateTime = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
            const element = document.getElementById('chart-container');

            if (element) {
                const width = element.offsetWidth;
                const height = element.offsetHeight;

                const canvas = await html2canvas(element, {
                    useCORS: true,
                    backgroundColor: 'white',
                    width: width,
                    height: height,
                    scale: 2,
                });

                const dataURL = canvas.toDataURL('image/jpeg', 1.0);


                const byteString = atob(dataURL.split(',')[1]);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const uintArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < byteString.length; i++) {
                    uintArray[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
                const formData = new FormData();
                formData.append('image', blob, 'Batch Comparison.jpeg');

                formData.append("startDateTime", dateRange[0]);
                formData.append("endDateTime", dateRange[1]);
                formData.append("penId", selectedPenId?.pen_id);

                formData.append("groupId", groupOptionSelected[0]?.groupId?.toString());

                batchNumbers.forEach(batchNumber => {
                    formData.append('batchNumbers[]', batchNumber);
                });

                formData.append("errorBand", errorBand);
                formData.append("format", yAxisFormat);
                formData.append("timeDiff", timeInterval);
                formData.append("downloadDateTime", formattedDateTime);

                const response = await axiosPrivate.post(getAPIMap("downloadBatchReport"), formData, {
                    responseType: 'blob'
                });

                if (response.status === 200) {
                    const pdfBlob = response.data;

                    const pdfURL = window.URL.createObjectURL(pdfBlob);
                    const pdfLink = document.createElement('a');
                    pdfLink.href = pdfURL;
                    pdfLink.download = filename; // Set the filename for download
                    document.body.appendChild(pdfLink);
                    pdfLink.click();
                    document.body.removeChild(pdfLink);

                } else {
                    console.error('Error uploading chart image:', response.statusText);
                }
            } else {
                console.error("Element to capture is not available.");
            }
        } catch (error) {
            console.error('Error capturing or uploading the chart image:', error);
        }
    };
    useEffect(() => {

    }, [deviationData])




    return (
        <div className="bg-white w-full rounded-r-md  rounded-bl-md rounded-l-none px-1 relative">
            <div >
                <BatchComparisonNavbar
                    batchList={filteredBatchList}
                    batchOptionSelected={batchOptionSelected}
                    setBatchOptionsSelected={setBatchOptionsSelected}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    groupData={batchesSent}
                    batchesSent={batchesSent}
                    setBatchesSent={setBatchesSent}
                    groupList={groupList}
                    batchLoader={batchLoader}
                    setGroupList={setGroupList}
                    setTimeInterval={setTimeInterval}
                    handleDateRender={handleDateRender}
                    setHandleDateRender={setHandleDateRender}
                    toggleVisibility={toggleVisibility}
                    groupOptionSelected={groupOptionSelected}
                    setGroupOptionsSelected={setGroupOptionsSelected}
                    handleFetchData={handleFetchData}
                    filterBtn={false}
                    groupSelectedList={groupSelectedList}
                    setGroupSelectedList={setGroupSelectedList}
                    selectedPenId={selectedPenId}
                    setSelectedPenId={setSelectedPenId}
                    handleReset={handleReset}
                    data={data}
                    minMaxValue={minMaxValue}
                />

                <div id="chart-container">
                    {groupSelectedList.length > 0 && <div className="flex gap-x-2 !z-50 py-2 -mt-2 rounded-b-md bg-bgBatchDetailsFilter mx-4 w-[98%]">
                        <div className="flex bg-gray-200 rounded-lg overflow-hidden ml-2">
                            {groupSelectedList[0]?.penIds?.map((pen, index) => (
                                <div
                                    key={pen.id}
                                    style={{
                                        backgroundColor: pen?.pen_id === selectedPenId?.pen_id ? pen.color : "#bbbcbd",
                                        color: pen?.pen_id === selectedPenId?.pen_id ? "white" : 'black',
                                    }}
                                    className="p-2 text-white text-[10px] !w-24 h-8   cursor-pointer flex items-center justify-center"

                                    onClick={() => setSelectedPenId({ value: pen.pen_id, ...pen })}
                                >
                                    <p>{pen?.pen_name || 'N/A'}</p>
                                </div>
                            ))}
                        </div>

                    </div>}

                    <HoverAndPenListComponent
                        data={isDeviationModeOn?deviationData:data}
                        toggleVisibility={toggleVisibility}
                        hoveredPoint={hoveredPoint}
                        isDeviationModeOn={isDeviationModeOn}
                    />

                    <div className="flex flex-row w-full h-full">
                        {graphLoader ? <div className='h-[65vh] flex justify-center items-center w-full'> <CircularProgress
                            size={30}
                            sx={{
                                color: "#eb7e39",
                            }}

                        /></div> : data.length === 0 ? (
                <div className='w-full h-[72vh]'>            <NoFiltersScreen label={dataResponse ? 'No data found for the relevant search' : 'Add filters to generate graph!'} /></div>
                        ) : (
                            <div className="flex w-full h-full ">
                                <div className={`w-full h-full`}>
                                    <div className='w-full'>
                                        <h6 className="text-[12px]/[20px] text-[#1E1B39] font-semibold ml-4 py-1">Batch Comparison with Master Batch</h6>

                                    </div>
                                    {isDeviationModeOn ? (
                                        <BatchComparisonDeviationGraph
                                            data={deviationData}
                                            errorBand={errorBand}
                                            selectedPenId={selectedPenId}
                                            yAxisFormat={yAxisFormat}
                                            chartRef={chartRef}
                                            sethoveredPoint={sethoveredPoint}
                                            minMaxValue={minMaxValue}
                                        />
                                    ) : (
                                        <BatchComparisonGraph
                                            data={data}
                                            selectedPenId={selectedPenId}
                                            yAxisFormat={yAxisFormat}
                                            batchesSent={batchesSent}
                                            chartRef={chartRef}
                                            errorBand={errorBand}
                                            sethoveredPoint={sethoveredPoint}
                                            minMaxValue={minMaxValue}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            {/* {hoveredPoint && (
    <div className="text-[12px]/[16px] border border-gray-300` rounded-lg mt-2 mx-4">
        <div className="w-full text-center font-bold sticky top-0 bg-white z-10 shadow-sm border-2 border-b border-gray-100 p-2">
            {isDeviationModeOn?"Delta values :" :"Minutes:"} {hoveredPoint.title}
        </div>


        {hoveredPoint.body.map((item, index) => {
            const [parameter, value] = item.split(": ");
            const dataCheck = data.find((batch) => {
                const batchNumber = batch.batchNumber.toString().trim();
                const param = parameter.toString().trim();

                return param.includes(batchNumber);
            });

            return (
                <div key={index} className="flex w-full p-2 pl-6">
                    {dataCheck && (
                        <div className={`text-left w-1/4 h-4 font-medium`}>
                            <div
                                style={{ backgroundColor: dataCheck.color }}
                                className="w-4 h-4 rounded-full" // rounded-full makes it a circle
                            ></div>
                        </div>
                    )}

                    <div className={`text-left px-4 ${parameter === "batchNumber" ? "w-3/4" : "w-full"}`}>
                        {parameter}
                    </div>
                    <div className="text-left flex items-center justify-center mx-10 w-full">{value}</div>
                </div>
            );
        })}
    </div>

)} */}




            <BatchComparisonSidebarFull
                minMaxValue={minMaxValue}
                resetZoom={resetZoom}
                setYAxisFormat={setYAxisFormat}
                handleTimeIntervalChange={handleTimeIntervalChange}
                showDownloadOverlay={showDownloadOverlay}
                errorBand={errorBand}
                handleSelectAndZoom={handleSelectAndZoom}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                timeInterval={timeInterval}
                selectedPenId={selectedPenId}
                setTimeInterval={setTimeInterval}
                handleShowLogScale={handleShowLogScale}
                showLogScale={showLogScale}
                toggleVisibility={toggleVisibility}
                hoveredPoint={hoveredPoint}
                handleMinMaxValueChange={handleMinMaxValueChange}
                setErrorBand={setErrorBand}
                setShowDownloadOverlay={setShowDownloadOverlay}
                handleColorChange={handleColorChange}
                handleLineWidthChange={handleLineWidthChange}
                yAxisFormat={yAxisFormat}
                batchesSent={data}
                deviationData={deviationData}

                groupList={groupList}
                onDeviationModeChange={onDeviationModeChange}
                isDeviationModeOn={isDeviationModeOn} />

            {showDownloadOverlay && (
                <DownloadOverlay
                    handleReportDownload={fetchBatchComparisonReportAndDownload}
                    downloadChart={handleDownload}
                    showPdf={isDeviationModeOn ? true : false}
                    handleCancel={() => {
                        setShowDownloadOverlay(false);
                    }}
                />
            )}
        </div>


    );
};

export default BatchComparison;