import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Chip, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { SearchBoxIcon } from '../../assets/svgs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CircularProgress } from '@mui/material';
dayjs.extend(utc);
function extractDates(input) {
    // Split the input string by " - "
    const parts = input.split(" - ");

    // Check if there are at least three parts (including the identifier)
    if (parts.length >= 3) {
        // The start date is the second part
        const startDate = parts[1].trim();
        // The end date is the third part
        const endDate = parts[2].trim();

        // Convert to YYYY-MM-DD format using dayjs
        const formattedStartDate = dayjs(startDate).format('DD/MM/YYYY');
        const formattedEndDate = dayjs(endDate).format('DD/MM/YYYY');

        return `${formattedStartDate} - ${formattedEndDate}`

    } else {
        throw new Error("Input format is incorrect.");
    }
}
const CustomSearchableSelect = ({
    options = [],
    selectedOptions = [],
    onChange,
    batchLoader = false,
    showDetails = false,
    showLabel = false,
    placeholder = 'Select Options',
    multiSelect = false,
    selectMaxCount,
    showParameter = true,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const filteredOptions = useMemo(() => {
        return options.filter((option) =>
            option.label.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, options]);

    const handleRemoveChip = (value) => {
        onChange(selectedOptions.filter((option) => option !== value));
    };

    const toggleOption = (value) => {
        if (multiSelect) {
            if (selectedOptions.includes(value)) {
                onChange(selectedOptions.filter((option) => option !== value));
            } else if (!selectMaxCount || selectedOptions.length < selectMaxCount) {
                onChange([...selectedOptions, value]);
            }
        } else {
            onChange(selectedOptions.includes(value) ? [] : [value]);
            setIsOpen(false);

        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            {showLabel && <label className="text-[10px] font-semibold mb-2 block">Select Options</label>}
            <div style={{ position: 'relative', maxWidth: '330px', minWidth: '330px' }}>
                <div
                    style={{

                        borderRadius: '4px',
                        display: 'flex',
                        flexWrap: 'nowrap', // Prevent chips from wrapping to next line
                        minHeight: '32px',
                        maxHeight: '32px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        lineHeight:1,
                        rowGap:4,
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden', // Prevent overflowing of chips
                        whiteSpace: 'nowrap', // Prevent chip labels from breaking
                    }}
                    className={`border ${isOpen ? "border-baseOrange" : "border-[#D5D7DA]"} focus:border-baseOrange focus:border hover:border-baseOrange`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div

                    >
                        {selectedOptions.length > 0 ? (
                            selectedOptions.map((option) => {
                                const matchedOption = options.find((opt) => opt.value === option);
                                return (
                                    matchedOption && (
                                        <Chip
                                            key={option}
                                            deleteIcon={<CloseIcon style={{ fontSize: 16 }} />}
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',

                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 700,
                                                            fontSize: '12px',
                                                            color: '#333',
                                                        }}
                                                    >
                                                        {matchedOption.label}
                                                    </span>
                                                    {showParameter && (
                                                        <span
                                                            style={{
                                                                fontSize: '10px',
                                                                color: '#888',
                                                              
                                                            }}
                                                        >
                                                            {matchedOption.penIds?.length} parameters
                                                        </span>
                                                    )}
                                                </div>
                                            }
                                            onDelete={() => handleRemoveChip(option)}
                                            style={{
                                                backgroundColor: '#F5F5F5',
                                                color: '#333',
                                                textOverflow: 'ellipsis', // Apply ellipsis for long text
                                                whiteSpace: 'nowrap', // Prevent text wrapping
                                            }}
                                        />
                                    )
                                );
                            })
                        ) : (
                            <span style={{ paddingLeft: 8, fontSize: 10, color: '#979797' }}>
                                {placeholder}
                            </span>
                        )}
                    </div>


                    <ExpandMoreIcon style={{ marginLeft: 'auto', color: '#979797' }} />
                </div>

                {isOpen && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '120%',
                            left: 0,
                            width: '100%',
                            border: '1px solid #EB7E39',
                            borderRadius: '8px',
                            backgroundColor: '#FFFFFF',
                            zIndex: 1000,
                        }}
                    >
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{
                                    flexGrow: 1,
                                    padding: '8px 32px 8px 8px', // Add padding-right for the icon
                                    border: '1px solid #979797',
                                    borderRadius: '8px',
                                }}
                                className="focus:outline-baseOrange m-2 h-8 text-[12px]/[20px] text-customGray pl-4"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: '20px', // Position the icon within the input
                                    color: '#979797',
                                }}
                            >
                                <SearchBoxIcon />
                            </div>
                        </div>

                        <div style={{ maxHeight: '200px', overflowY: 'auto' }} className='pl-2 px-4'>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        onClick={() => toggleOption(option.value)}
                                        style={{
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        className='border-b  border-[#D5D7DA]'
                                    >
                                        <span className='text-[10px]/[20px] text-customGray '>{option.label}  {showDetails && <span className='pl-2'>{extractDates(option.details)}</span>}</span>
                                        <span></span>
                                        {multiSelect && (
                                            <Checkbox
                                                checked={selectedOptions.includes(option.value)}
                                                onChange={() => toggleOption(option.value)}
                                                sx={{
                                                    color: '#EB7E39',
                                                    height: 4,
                                                    '& .MuiSvgIcon-root': {
                                                        color: selectedOptions.includes(option.value)
                                                            ? '#EB7E39'
                                                            : 'default',
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>
                                ))
                            ) : batchLoader ? <div className='h-20 flex justify-center items-center w-full'> <CircularProgress
                                size={30}
                                sx={{
                                    color: "#555555",
                                }}

                            /></div> :
                                <div style={{ padding: '10px', color: '#999' }} className='text-[10px]'>No options found</div>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSearchableSelect;
