import React, { useEffect, useState } from "react";
import BatchDetails from "./BatchDetails";
import BatchComparison from "./BatchComparison";

import getAPIMap from "../routes/url/ApiUrls";
import { useAxiosPrivate } from "../hooks/useAxiosPrivate";

const tabs = [
  {
    key: "batchDetails",
    label: "Batch Details",
    component: BatchDetails,
  },
  {
    key: "batchComparison",
    label: "Batch Comparison",
    component: BatchComparison,
  },
];

const Home = () => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedOption, setSelectedOption] = useState("batchDetails");
  const [masterBatch, setMasterBatch] = useState(false);
  const [toolTip, setToolTip] = useState("");

  const handleTabChange = (key) => {
    if (key === "batchComparison" && !masterBatch) return; // Prevent selection if masterBatch is false
    setSelectedOption(key);
  };

  useEffect(() => {
    const getMasterBatch = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap("mastBatchPath"));
        if (response.status === 200 && response.data.is_master_batch) {
          setMasterBatch(true);
        } else {
          setToolTip("Master batch not set. Please set master batch first!");
        }
      } catch (error) {
        setToolTip("Master batch not set. Please set master batch first!");
      }
    };
    getMasterBatch();
  }, [axiosPrivate]);

  const renderActiveComponent = () => {
    const activeTab = tabs.find((tab) => tab.key === selectedOption);
    return activeTab?.component ? <activeTab.component /> : null; // Render as JSX
  };

  return (
    <div className="h-full flex flex-col ml-4">
      <div className="w-4/12 h-8 flex justify-center items-center">
        {tabs.map((tab) => {
          const isDisabled = tab.key === "batchComparison" && !masterBatch; // Condition to disable Batch Comparison

          return (
            <div
              key={tab.key}
              className={`${selectedOption === tab.key
                ? "bg-white rounded-t-md text-textGray font-semibold text-[12px]/[16px] border"
                : "text-white font-semibold text-[12px]/[16px]"
                }
                ${isDisabled ? "opacity-60 cursor-not-allowed relative group" : "cursor-pointer"}
                w-1/2 h-full flex justify-center items-center`}
              onClick={() => handleTabChange(tab.key)}
            >
              {tab.label}

              {isDisabled && (
                <div className="absolute top-full  left-6 !z-50 hidden group-hover:block w-max bg-black w-full text-white text-xs px-2 py-1 rounded" style={{ zIndex: 3000 }}>
                  {toolTip}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className=" mr-2">{renderActiveComponent()}</div>
    </div>
  );
};

export default Home;
