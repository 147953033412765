import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

export const generateRandomDarkColor = (() => {
  const predefinedColors = [
    "#1E5A8A", "#D63230", "#076807", "#A5600D", "#8A12E1",
    "#9D0C7D", "#08797A", "#2215F7", "#CF5202", "#D006B8", "#7D7425"
  ];
  const usedColors = new Set();

  const getRandomColor = () => {
    if (usedColors.size >= predefinedColors.length) {
      throw new Error("No more unique colors available.");
    }

    let color;
    do {
      const randomIndex = Math.floor(Math.random() * predefinedColors.length);
      color = predefinedColors[randomIndex];
    } while (usedColors.has(color));

    usedColors.add(color);
    return color;
  };

  const resetUsedColors = () => {
    usedColors.clear();
  };

  return {
    getRandomColor,
    resetUsedColors,
  };
})();


export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiCheckbox-root': {
    backgroundColor: 'white', // Default background color
    borderColor: 'white', // Default border color
  },
  '& .MuiCheckbox-root.Mui-checked': {
    backgroundColor: 'white', // White background when checked
    borderColor: 'white', // Border color when checked
  },
  '&:hover .MuiCheckbox-root': {
    backgroundColor: '#f0f0f0', // Lighter background on hover
    borderColor: '#5c5c5c', // Darker border on hover
  },
  '& .MuiCheckbox-root.Mui-checked:hover': {
    backgroundColor: 'white', // Keep background white even on hover when checked
    borderColor: '', // Keep border color gray when checked
  },
  '& .MuiCheckbox-root:focus': {
    boxShadow: '0 0 0 2px rgba(0, 132, 255, 0.2)', // Focus box-shadow
    borderColor: '#8b8b8b', // Border color when focused
  },
  '& .MuiCheckbox-root.Mui-checked:focus': {
    borderColor: 'white', // Border color when checked and focused
  },
  '& .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root': {
    color: 'gray', // Checkmark color when checked
  },
  "& .MuiSvgIcon-root": {
    fontSize: 28,
    color: "#d9d9d9",
    backgroundColor: "white"
  }
}));