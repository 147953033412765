import React from "react";

function MandatoryLabel({ name, isMandatory = false }) {
    return (
        <span className="text-[12px]">
            {name}{isMandatory && <span className="text-red-500">*</span>}
        </span>
    );
}

export default MandatoryLabel;
