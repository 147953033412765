import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import InputField from '../../components/InputField';
import Select from '../../components/SelectBox';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import { EyeClosedIcon, EyeOpenIcon, IIcon } from '../../assets/svgs';

const EditUser = ({ open, handleClose, user, disableBackdropClick, editUser }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValidationVisible, setPasswordValidationVisible] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        minLength: false,
    });
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { role } = useContext(AuthContext);

    useEffect(() => {
        if (user) {
            formik.setValues({
                name: user.name,
                employee_id: user.employee_id,
                email: user.email,
                roleId: user.roleId,
                password: user.password,
                confirmPassword: user.password,
            });
        }
    }, [user]);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^(?=.*[A-Za-z0-9])[ A-Za-z0-9]*$/, "Username must be alphanumeric")
            .max(75, "Maximum length allowed is 75")
            .min(1, "Minimum length allowed is 1")
            .required("Username is required"),
        employee_id: Yup.string()
            .matches(/^[A-Za-z0-9]+$|^000$/, "Employee Id must be alphanumeric")
            .min(1, "Minimum length allowed is 1")
            .max(50, "Maximum length allowed is 50")
            .required("Employee ID is required"),
        email: Yup.string()
            .matches(/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, "Invalid email format")
            .max(64, "Email must be a maximum of 64 characters"),
        roleId: Yup.string().required('Role is required'),
        password: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/, "Password must be 8-32 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.")
            .max(32, "Maximum length allowed is 32")
            .min(8, "Minimum length allowed is 8")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: user?.name || "",
            employee_id: user?.employee_id || "",
            email: user?.email || "",
            roleId: user?.roleId || "",
            password: user?.password || "",
            confirmPassword: user?.password || "",
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!formik.isValid) {
                return;
            }
            try {
                const { confirmPassword, ...payload } = values;
                await editUser(payload);
                handleClose();
            } catch (error) {
                console.error("Failed to update user:", error);
            }
        },
    });

    // Password validation logic
    const checkPasswordRequirements = (password) => {
        setPasswordValidation({
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[\W_]/.test(password),
            minLength: password.length >= 8,
        });
    };

    const renderPasswordRule = (isValid, text) => (
        <div className="flex items-center text-xs" style={{ color: isValid ? 'green' : 'red' }}>
            {isValid ? (
                <CheckIcon sx={{ color: 'green', mr: 1, fontSize: '12px' }} />
            ) : (
                <CrossIcon sx={{ color: 'red', mr: 1, fontSize: '12px' }} />
            )}
            {text}
        </div>
    );

    const renderLabel = (label, isRequired = false) => (
        <div className="text-[12px] font-semibold">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );

    const roleOptions = role.length > 0
        ? role.map(roles => ({ label: roles.name, value: roles.id }))
        : [{ label: 'No roles available', value: '', disabled: true }];

    return (
        <>
            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ${open ? '' : 'hidden'}`} onClick={disableBackdropClick ? null : handleClose}>
                <div className="w-4/12 bg-white p-8 rounded-md shadow-lg mt-[70px] overflow-y-auto max-h-[650px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-rounded-full" onClick={(e) => e.stopPropagation()}>
                    <div className="relative flex flex-col">
                        <button onClick={() => { formik.resetForm(); handleClose(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>
                        <div className="text-textHeading2 text-[12px]/[28px] font-bold mt-0">Edit User</div>
                    </div>
                    <div className="text-textGray text-[10px]/[20px] pt-1 mb-4">Update details for the user.</div>
                    <form onSubmit={formik.handleSubmit} className="w-full mt-4">

                        {renderLabel('Name', true)}
                        <InputField
                            type="text"
                            name="name"
                            placeholder="Enter the user name"
                            className={`block w-full border text-[10px] rounded-md p-2 ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'}`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            labelClass="text-textHeading text-[12px]/[20px] font-semibold"
                            inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                        />
                        {formik.touched.name && formik.errors.name && <div className="text-red-500 text-[8px] mt-[-10px]">{formik.errors.name}</div>}

 <div className="flex items-center">
                    {renderLabel('Employee ID', true)}
                    <div className="relative ml-1 group">
                        <IIcon />
                        <div className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md p-2 w-max max-w-xs -translate-x-1/2 left-24 bottom-6">
                            User name to be used for logging in
                        </div>
                    </div>
                </div>
                        <InputField
                            type="text"
                            name="employee_id"
                            placeholder="Enter employee Id"
                            className={`block w-full border text-[10px] rounded-md p-2 ${formik.touched.employee_id && formik.errors.employee_id ? 'border-red-500' : 'border-gray-300'}`}
                            value={formik.values.employee_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                            inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                        />
                        {formik.touched.employee_id && formik.errors.employee_id && <div className="text-red-500 text-[8px] mt-[-10px]">{formik.errors.employee_id}</div>}

                        {renderLabel('Email Id', false)}
                        <InputField
                            type="email"
                            name="email"
                            placeholder="Enter the mail id"
                            className={`block w-full border text-[10px] rounded-md p-2 ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                            inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                        />
                        {formik.touched.email && formik.errors.email && <div className="text-red-500 text-[8px] mt-[-10px]">{formik.errors.email}</div>}

                        {renderLabel('Role', true)}
                        <Select
                            name="roleId"
                            value={formik.values.roleId}
                            onChange={(e) => formik.setFieldValue("roleId", e.target.value)}
                            options={roleOptions}
                            className={`w-full p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px] ${formik.touched.roleId && formik.errors.roleId ? 'border-red-500' : 'border-gray-300'}`}
                        />
                        {formik.touched.roleId && formik.errors.roleId && <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.roleId}</div>}

                        {renderLabel('Password', true)}
                        <div className="relative mb-2">
                            <InputField
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter the password"
                                value={formik.values.password}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    checkPasswordRequirements(e.target.value);
                                    setPasswordValidationVisible(true);
                                }}
                                onFocus={() => setPasswordValidationVisible(true)}
                                onBlur={formik.handleBlur}
                                name="password"
                                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                                className={`border rounded-md p-2 text-[10px] w-full ${formik.touched.password && formik.errors.password ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            <button type="button" className="absolute right-2 top-3 text-gray-500" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                            </button>
                        </div>

                        {isPasswordValidationVisible && !formik.values.password && (
                            <div className="mb-2 text-[8px]">
                                {renderPasswordRule(passwordValidation.hasUpperCase, 'Minimum one upper case letter required')}
                                {renderPasswordRule(passwordValidation.hasNumber, 'Minimum one number required')}
                                {renderPasswordRule(passwordValidation.hasLowerCase, 'Minimum one lower case letter required')}
                                {renderPasswordRule(passwordValidation.hasSpecialChar, 'Minimum one special character required')}
                                {renderPasswordRule(passwordValidation.minLength, 'Minimum eight characters required')}
                            </div>
                        )}

                        {renderLabel('Confirm Password', true)}
                        <div className="relative mb-2">
                            <InputField
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                placeholder="Confirm the password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                                className={`block w-full border rounded-md p-2 ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} text-[10px]`}
                            />
                            <button type="button" className="absolute right-2 top-3 text-gray-500" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                            </button>
                        </div>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.confirmPassword}</div>}

                        <div className="flex justify-end mt-8 mb-0">
                            <button type="submit" className={`flex justify-center items-center py-2 px-1 h-[40px] bg-baseOrange w-[119px] text-[12px] text-white rounded-md`}>
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EditUser;
