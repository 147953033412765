import React, { useState, useEffect, useRef } from 'react';
import { Chip, Checkbox } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const CustomSearchableSelect = ({
    options = [],
    selectedOptions = [],
    onChange,
    placeholder = 'Select Options',
    multiSelect = false,
    showParameter = false,
    setGroupOptionsSelected,
    setHandleDateRender, handleDataRender,
    batchLoader
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);
    useEffect(() => {
        if (handleDataRender) {

        }
    }, [handleDataRender])
    const transformedOptions = options.map(group => ({
        label: group.label,
        groupId: group.value,
        penIds: group.pendIds,
        options: group.penIds
            .filter(pen => pen.pen_id && pen.pen_name)
            .map(pen => ({
                label: pen?.pen_name,
                value: pen?.pen_id,
            })),
    })).filter(group => group.options.length > 0);

    const toggleOption = (label, groupId, options) => {
        const newSelection = { label, groupId, options };
        if (multiSelect) {

            const alreadySelected = selectedOptions.some(option => option.value === groupId);
            if (alreadySelected) {
                onChange(selectedOptions.filter(option => option.groupId !== groupId));
            } else {
                onChange([...selectedOptions, newSelection]);
            }
        } else {
            onChange([newSelection]);
            setIsOpen(false);
        }
    };


    const handleRemoveChip = (groupId) => {

        const updatedOptions = selectedOptions.filter(option => {
            return option.groupId !== groupId; // Exclude the matching groupId
        });

        onChange(updatedOptions);
    };


    useEffect(() => {
        setGroupOptionsSelected(selectedOptions);
    }, [selectedOptions, setGroupOptionsSelected]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    return (
        <div ref={dropdownRef} style={{ position: 'relative' }}>
            <div
                style={{

                    borderRadius: '4px',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    height: '32px',
                    cursor: 'pointer',
                    minWidth: '300px',
                    backgroundColor: '#FFFFFF',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
                onClick={() => setIsOpen(!isOpen)}
                className={`${isOpen ? "border-baseOrange" : "border-[#D5D7DA]"} border hover:border-baseOrange`}
            >
                <div style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'visible',
                    textOverflow: 'ellipsis',
                    maxWidth: '200px',
                    paddingRight: '8px',
                    alignItems: 'center',

                }}
                    className='h-8'
                >
                    {selectedOptions.length > 0 ? (
                        selectedOptions.map(option => (
                            <Chip
                                key={option.value}
                                label={
                                    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column',lineHeight:1 }}>
                                        <span className="text-[12px] font-semibold">
                                            {option.label}
                                        </span>
                                        {showParameter && (
                                            <span className="text-xs text-gray-500">
                                                {option?.options?.length} parameter
                                            </span>
                                        )}
                                    </div>
                                }
                                onDelete={() => handleRemoveChip(option.groupId)}
                                deleteIcon={<CloseIcon />}
                                style={{
                                    margin: '2px',
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'visible',
                                    textOverflow: 'ellipsis',
                                }}
                            />
                        ))
                    ) : (
                        <span style={{ color: '#979797', fontSize: '10px', }}>
                            {placeholder}
                        </span>
                    )}
                </div>
                <ExpandMoreIcon style={{ marginLeft: 'auto', color: '#979797' }} />
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        marginTop: '10px',
                        width: '130%',
                        border: '1px solid #EB7E39',
                        borderRadius: '8px',
                        backgroundColor: '#FFFFFF',
                        zIndex: 1000,
                        maxHeight: '300px',
                        overflowY: 'auto',
                    }}
                >
                    <div style={{ position: 'relative', padding: '8px' }} className='mx-2'>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{
                                flexGrow: 1,
                                padding: '8px 32px 8px 8px',
                                border: '1px solid #9a9a9a',
                                borderRadius: '8px',
                                width: '100%',
                            }}
                            className='focus:outline-baseOrange mt-2 h-8 text-[12px]/[20px] pl-4'
                        />
                        <SearchIcon
                            style={{
                                position: 'absolute',
                                right: '16px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#979797',
                            }}
                            className='mt-1'
                        />
                    </div>

                    <div className='pl-2 pr-3'>
                        {transformedOptions.map(group => (
                            <div key={group.label} onClick={() => toggleOption(group.label, group.groupId, group.options)} className="py-2 pointer-cursor px-4 border-b border-[#D5D7DA]">

                                <span className="text-customGray text-[10px]/[20px] cursor-pointer">                                    {group.label} </span>
                                {multiSelect && (
                                    <Checkbox
                                        checked={selectedOptions.some(o => o.value === group.label)}
                                        sx={{
                                            color: '#EB7E39',
                                            '& .MuiSvgIcon-root': {
                                                color: selectedOptions.some(o => o.value === group.label)
                                                    ? '#EB7E39'
                                                    : 'default',
                                            },
                                        }}
                                    />
                                )}

                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSearchableSelect;
