import { generateRandomDarkColor } from "../../../utils/color.utils.js";

export const prepareChartData = ({
    data = [],
    valueKey = '',
    errorBand,
    displayFormat = 'real',
    adjustment = 1.5,
    min,
    max,
    isMasterBatch = false
}) => {
    generateRandomDarkColor.resetUsedColors();
    const labels = [];
    const datasets = [];
    let minValue = Infinity;
    let maxValue = -Infinity;


    const parseValue = (value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue)) {
            return 0;
        }
        return numericValue;
    };

    data?.forEach((batch) => {
        batch?.batchResult.forEach((result) => {
            if (!labels.includes(result.minutesPassed)) {
                labels.push(result.minutesPassed);
            }
            const value = parseValue(result[valueKey]);
            if (value < minValue) minValue = value;
            if (value > maxValue) maxValue = value;
        });
    });

    if (min !== undefined) minValue = parseValue(min);
    if (max !== undefined) maxValue = parseValue(max);

    labels.sort((a, b) => a - b);

    const offset = minValue <= 0 ? Math.abs(minValue) + 1 : 0;
    try {
        data?.forEach((batch, index) => {
            if (!batch.visibility) {
                return
            }
            const batchLabel = `Batch ${batch.batchNumber}`;
            const color = generateRandomDarkColor.getRandomColor();

            const batchData = labels.map((minute) => {
                const result = batch.batchResult.find((r) => r.minutesPassed === minute);
                return result ? parseValue(result[valueKey]) : null; // Adjust values to avoid negatives
            });


            datasets.push({
                label: batchLabel,
                data: batchData,
                fill: false,
                borderColor: batch.color,
                borderWidth: batch.lineWidth || 2,
                tension: 0,
                pointBackgroundColor: batch.color,
                hoverBorderWidth: 10,
                pointRadius: 1,
                pointHoverRadius: 3, pointHoverRadius: 0, pointRadius: 0,
            });

            if (batch.isMasterBatch && errorBand) {
                const upperBound = batchData.map(temp => temp !== null ? temp + errorBand : null);
                const lowerBound = batchData.map(temp => temp !== null ? temp - errorBand : null);

                datasets.push({
                    label: `${batchLabel} Error Upper`,
                    data: upperBound,
                    fill: false,
                    borderColor: 'rgba(255, 0, 0, 0.8)',
                    borderWidth: 1,
                    borderDash: [5, 5],
                    pointRadius: 0,
                    tension: 0,
                    pointHoverRadius: 0, pointRadius: 0,
                });

                datasets.push({
                    label: `${batchLabel} Error Lower`,
                    data: lowerBound,
                    fill: false,
                    borderColor: 'rgba(255, 0, 0, 0.8)',
                    borderWidth: 1,
                    borderDash: [5, 5],
                    pointRadius: 0,
                    tension: 0,
                    pointHoverRadius: 0, pointRadius: 0,
                });
            }
        });

    } catch (e) {
    }

    const isExponential = displayFormat?.toLowerCase() === "exponential";
    const isInteger = displayFormat?.toLowerCase() === "integer";
    const isReal = displayFormat?.toLowerCase() === "real";

    let yAxisMin = (minValue - (offset / 50) - (errorBand ?? 5) * (adjustment ?? 1.5))
    let yAxisMax = maxValue + offset + errorBand * adjustment;
    yAxisMin = Math.round((yAxisMin + Number.EPSILON) * 100) / 100; // Ensures precise rounding
    yAxisMax = Math.round((yAxisMax + Number.EPSILON) * 100) / 100;
    return {
        labels,
        datasets,
        minValue,
        maxValue,
        offset,
        yAxisMin,
        yAxisMax,
        isExponential,
        isInteger,
        yAxisTicksCallback: (value) => {
            if (isExponential) {
                return (value - offset).toExponential(2);
            } if (isReal) {

                return (value - offset).toFixed(2)
            }
            return Math.floor((value + Number.EPSILON) * 100) / 100; // Exactly 2 decimals
        },
        tooltipLabelCallback: (context) => {
            const dataset = context.dataset;
            const dataPoint = context.raw;
            const numericValue = parseValue(dataPoint);

            if (isNaN(numericValue)) {
                return `${dataset.label}: Invalid Data`;
            }

            let val = numericValue - offset;
            if (isExponential) {
                val = val.toExponential(2);
            } else if (isInteger) {
                val = Math.trunc(Number(val));
            } else if (isReal) {
                val = Math.floor(Number(val) * 100) / 100;
            }

            return `${dataset.label}: ${val}`;
        }
    };
};


export const externalTooltipHandler = (context, tooltipRef, sethoveredPoint,yAxisFormat) => {
    const { chart, tooltip } = context;

    if (!tooltip || !tooltip.opacity) {
        tooltipRef.current.style.opacity = 0;
        return;
    }

    const tooltipData = {
        title: "",
        body: [],
    };


    tooltip.dataPoints.forEach((activePoint) => {
        const datasetIndex = activePoint.datasetIndex;
        const dataset = chart.data.datasets[datasetIndex];
        const label = activePoint.label;
        const yValue = activePoint.raw;
        // const formattedYValue = yAxisFormat === "exponential"
        //     ? Number(yValue).toExponential(2)
        //     : yAxisFormat === "integer"? parseInt(yValue): Math.floor(Number(yValue) * 100) / 100;
        const formattedYValue = yAxisFormat === "exponential"
            ? parseFloat(yValue).toExponential().replace(/(\.\d{2})\d+e/, '$1e')
            : yAxisFormat === "integer" ? Math.trunc(Number(yValue)) : Math.floor(Number(yValue) * 100) / 100;


        tooltipData.body.push(
            `${dataset.label}: ${formattedYValue}`
        );
    });

    tooltipData.title = Number(tooltip.dataPoints[0].label);

    sethoveredPoint(tooltipData)


};
