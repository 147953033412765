import { useContext, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import axios from "../api/apiBaseUrl"; // Replace with your API base URL module
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL, // Replace with your API base URL
});

let refreshPromise = null; // Shared promise for token refresh
let isInterceptorAttached = false;

export const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { auth, setSessionExpired } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth && !isInterceptorAttached) {
            isInterceptorAttached = true;

            // Request Interceptor
            const requestIntercept = axiosPrivate.interceptors.request.use(
                (config) => {
                    const accessToken = localStorage.getItem("accessToken");
                    if (accessToken && !config.headers["Authorization"]) {
                        config.headers["Authorization"] = `Bearer ${accessToken}`;
                    }
                    return config;
                },
                (error) => Promise.reject(error)
            );

            // Response Interceptor
            const responseIntercept = axiosPrivate.interceptors.response.use(
                (response) => response,
                async (error) => {
                    const prevRequest = error?.config;

                    if (error?.response?.status === 401 && !prevRequest?.sent) {
                        if (!refreshPromise) {
                            // Create a shared refresh promise
                            refreshPromise = refresh()
                                .then((newAccessToken) => {
                                    // Save the new token and clear the shared promise
                                    localStorage.setItem("accessToken", newAccessToken);
                                    refreshPromise = null;
                                    return newAccessToken;
                                })
                                .catch((refreshError) => {
                                    refreshPromise = null;
                                    throw refreshError;
                                });
                        }

                        try {
                            // Wait for the refresh promise to resolve
                            const newAccessToken = await refreshPromise;

                            // Retry the failed request with the new token
                            prevRequest.sent = true; // Mark this request as retried
                            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                            return axiosPrivate(prevRequest);
                        } catch (refreshError) {
                            console.error("Token refresh failed:", refreshError);

                            if (refreshError.response?.status === 401) {
                                setSessionExpired(true); // Notify session expiration
                                navigate("/sign-in"); // Redirect to sign-in page
                            }

                            return Promise.reject(refreshError);
                        }
                    }

                    return Promise.reject(error);
                }
            );

            // Cleanup interceptors on component unmount
            return () => {
                axiosPrivate.interceptors.request.eject(requestIntercept);
                axiosPrivate.interceptors.response.eject(responseIntercept);
                isInterceptorAttached = false;
            };
        }
    }, [auth, refresh, setSessionExpired, navigate]);

    return axiosPrivate;
};
