import React from "react";
import BarChart from "../../../components/bar-chart/BarChart";
import { prepareChartData } from "../utils/batch-comparison.utils";

// function findMinMax(data = [], key) {
//     const positive = data.filter(obj => obj[key] > 0).map(obj => obj[key]);
//     const negative = data.filter(obj => obj[key] < 0).map(obj => obj[key]);

//     let max = Math.max(...positive);
//     let min = Math.min(...negative);

//     console.log({ max, min }); // Output: { max: 3, min: -5 }
//     if ([Infinity, -Infinity].includes(min)) {
//         min = -10;
//         console.log(`Modified max[${min}] value to ${min}`);
//     }
//     if ([Infinity, -Infinity].includes(max)) {
//         max = 10;
//         console.log(`Modified max[${max}] value to ${max}`);
//     }

//     min = min.toString().replace('-', '');
//     max = max.toString()

//     const bigNum = Math.max(Number(min), Number(max));
//     min = -bigNum;
//     max = bigNum;

//     return { min, max };
// }

function findMinMax(data = [], key, axis) {
    const positive = data.filter((obj) => obj[key] > 0).map((obj) => obj[key]);
    const negative = data.filter((obj) => obj[key] < 0).map((obj) => obj[key]);

    let max = positive.length ? Math.max(...positive) : 0;
    let min = negative.length ? Math.min(...negative) : 0;

    if ([Infinity, -Infinity].includes(min) || isNaN(min)) {
        min = -10; // Default min value if no valid negatives are found
    }
    if ([Infinity, -Infinity].includes(max) || isNaN(max)) {
        max = 10; // Default max value if no valid positives are found
    }

    // Normalize min and max to symmetric bounds
    const bigNum = Math.max(Math.abs(min), Math.abs(max));
    min = -bigNum;
    max = bigNum;

    axis = axis?.toLowerCase();

    if (axis === "integer") {
        // Ensure min and max are integers
        min = Math.floor(min);
        max = Math.ceil(max);

        if (min?.toString().includes("-")) {
            min = 0;
        }
    } else if (axis === "real") {
        // Keep min and max as real numbers with small adjustments
        min = Math.floor(min * 10) / 10; // Adjust to 1 decimal place
        max = Math.ceil(max * 10) / 10;
    } else if (axis === "logarithmic" || axis === "exponential") {
        // Ensure positive values for logarithmic scale
        if (min < 0) min = 1; // Logarithmic scale cannot have values <= 0
        if (max < 1) max = 10; // Default upper bound for small values
        min = Math.pow(10, Math.floor(Math.log10(min)));
        max = Math.pow(10, Math.ceil(Math.log10(max)));
    }

    return { min, max };
}

function BatchComparisonDeviationGraph({
    data,
    errorBand,
    selectedPenId,
    yAxisFormat,
    chartRef,
    minMaxValue,
    sethoveredPoint,
}) {
    let allBatchesGroupedData = [];
    data?.forEach(({ batchResult, batchNumber }) => {
        allBatchesGroupedData = [...allBatchesGroupedData, ...batchResult];
    });

    // const yAxiosMinMax = findMinMax(
    //     allBatchesGroupedData,
    //     "difference",
    //     yAxisFormat
    // );
    const xAxiosMinMax = findMinMax(allBatchesGroupedData, "minutesPassed");

    // console.log(`[Deviation-axis] y-axis format : `, yAxisFormat);
    // console.log(`[Deviation] api res data : `, data);
    // console.log(
    //     `[Deviation] All batches grouped data: `,
    //     allBatchesGroupedData.sort((a, b) => a.minutesPassed - b.minutesPassed)
    // );
    // console.table(
    //     allBatchesGroupedData.sort((a, b) => a.minutesPassed - b.minutesPassed)
    // );

    const labelData = [0, ...Array.from({ length: xAxiosMinMax.max + 1 }, (_, i) => i + 1)];
    const deviationData = {
        labels: labelData,
        datasets:
            data?.filter((ele) => ele?.visibility)?.map((ele) => {

                const { batchNumber, batchResult, color } = ele;
                const modifiedData = [];

                labelData.forEach((val) => {

                    const found = batchResult?.find(
                        ({ minutesPassed }) =>
                            minutesPassed?.toString()?.replace("-", "") === val?.toString()
                    );

                    modifiedData?.push(found?.difference || 0);
                });

                return {
                    ...ele,
                    label: `${batchNumber}`,
                    data: modifiedData,
                    backgroundColor: color,
                };
            }) || [],
        errorBand,
    };

    const {
        offset,
        yAxisMin,
        yAxisMax,
        isExponential,
        isInteger,
        yAxisTicksCallback,
    } = prepareChartData({
        data,
        valueKey: "difference",
        errorBand,
        displayFormat: yAxisFormat?.toLowerCase(),
        min: minMaxValue?.min,
        max: minMaxValue?.max,
    });



    return (

            <BarChart
                data={deviationData}
                yAxisProp={{
                    min: Number(yAxisMin),
                    max: Number(yAxisMax),
                    title: selectedPenId?.pen_name,
                }}
                ref={chartRef}
                yAxisTicksCallback={yAxisTicksCallback}
                
                yAxisFormat={yAxisFormat}
                sethoveredPoint={sethoveredPoint}
                style={{ width: "100%", height: "100%" }} 
            />

    );
}

export default React.memo(BatchComparisonDeviationGraph);
