import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GroupIcon as Group, DatabaseIcon as Database, MasterBatchIcon, PenlistIcon as PenList, UserIcon as User, ImportIcon } from '../../assets/svgs';
import { AuthContext } from '../../context/AuthContext';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import DbNotification from '../Notifications/DbNotification';
import { PATHS } from '../../routes/path';
import getAPIMap from '../../routes/url/ApiUrls';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import Notification from '../../components/Notifications/index'
import { Tooltip } from 'antd';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [activePath, setActivePath] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [databaseStatus, setDataStatus] = useState(false)
  const axiosPrivate = useAxiosPrivate();
  const [configSubmenuOpen, setConfigSubmenuOpen] = useState(false);
  const [sideBarMenu, setSideBarMenu] = useState([]);
  const [dbNotificationOpen, setDbNotificationOpen] = useState(false);
  const [dbNotificationMessage, setDbNotificationMessage] = useState('');
  const [dbNotificationSeverity, setDbNotificationSeverity] = useState(''); // You can use this for additional styling of notifications
  const [config, setConfig] = useState({ open: false, message: "", severity: "" })
  const { setRole } = useContext(AuthContext);
  const configButtonRef = useRef(null);
  const submenuRef = useRef(null);
  const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        configSubmenuOpen &&
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !configButtonRef.current.contains(event.target)
      ) {
        setConfigSubmenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [configSubmenuOpen]);
  const iconMap = {
    Group: Group,
    Penlist: PenList,
    User: User,
    Database: Database,
    Config: ImportExportOutlinedIcon,
  };

  const fetchRoles = async () => {
    const accessToken = localStorage.getItem("accessToken")
    try {
      const response = await axiosPrivate.get(getAPIMap("getRoles"));
      setRole(response.data.result.roleList);
    } catch (error) {
      console.error("Failed to fetch roles:", error.message);
    }
  };



  useEffect(() => {
    const fetchSidebarMenu = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap('sideBarMenuApi'));
        const transformedMenu = response.data.map((item) => {
          const cleanName = item.name.startsWith('_') ? item.name.slice(1) : item.name;
          const mappedIcon = cleanName === 'Master Batch' ? MasterBatchIcon : iconMap[item.icon] || null;

          return {
            ...item,
            name: cleanName,
            icon: mappedIcon,
          };
        });

        setSideBarMenu(transformedMenu);
      } catch (error) {
        console.error('Error fetching sidebar menu:', error);
      }
    };

    fetchSidebarMenu();
  }, [axiosPrivate]);

  const handleDbNotificationClose = () => {
    setDbNotificationOpen(false);
    setDbNotificationMessage('');
  };

  const handleViewDetails = () => {
    navigate(PATHS.viewDB);
    handleDbNotificationClose();
  };

  const checkDatabaseConnection = async () => {
    try {
      const response = await axiosPrivate.get('/database'); // Replace with your actual endpoint
      if (response.status === 200) {
        setIsConnected(true);
        return true;
      }
      return false; // Not connected
    } catch (error) {
      console.error('Error checking database connection:', error);
      return false;
    }
  };

  const handleItemClick = async (item) => {
    if (item.name === 'Config') {
      const rect = configButtonRef.current.getBoundingClientRect();
      setSubmenuPosition({
        top: rect.top + window.scrollY, // Adjust for scroll position
        left: rect.right + 20, // Position submenu to the right of the button
      });
      setConfigSubmenuOpen(!configSubmenuOpen);
    } else if (item.path === '/database') {
      setConfigSubmenuOpen(false)
      const connected = await checkDatabaseConnection();
      if (connected) {
        if (window.location.pathname.includes('/viewdb')) {
          navigate(PATHS.homePath);
        } else {
          setDbNotificationOpen(true);

          setDbNotificationMessage('Already connected to the database.');
          setDbNotificationSeverity('warning');
        }
      } else {
        setConfigSubmenuOpen(false)
        navigate(item.path);
      }
    } else {
      if (activePath === item.path) {
        setConfigSubmenuOpen(false)
        setActivePath('');
        navigate('/');
      } else {
        setConfigSubmenuOpen(false)
        if (item.path === '/user') {
          fetchRoles();
        }
        setConfigSubmenuOpen(false)
        setActivePath(item.path);
        navigate(item.path);
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      setActivePath('');
    }
  }, [window.location.pathname]);

  const importConfig = async () => {
    try {
      const response = await axiosPrivate.post(`${getAPIMap("importConfig")}`)
      setConfig({ open: true, message: response.data.message, severity: "success" })
    } catch (error) {
      setConfig({ open: true, message: error.response.data.message, severity: "Failure" })
    } finally {
      setConfigSubmenuOpen(false)
    }

  }
  const exportConfig = async () => {
    try {
      const response = await axiosPrivate.post(`${getAPIMap("exportConfig")}`)
      setConfig({ open: true, message: response.data.message, severity: "success" })
    } catch (error) {
      setConfig({ open: true, message: error.response.data.message, severity: "Failure" })
    } finally {
      setConfigSubmenuOpen(false)

    }
  }
  const closeNotification = () => {
    setConfig({ open: false, message: "", severity: "" })
  }

  useEffect(() => {
    const getDataBaseStatus = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap("databaseBase"))
        setDataStatus(true)
      } catch (error) {
        setDataStatus(false)
      }
    }
if(!databaseStatus){
    getDataBaseStatus()
}
  }, [window.location.pathname])

  return (
    <div className="flex relative">
      <div className="sidebar w-20 ml-2 rounded-lg shadow-lg h-[85.5%] bg-transparent text-white fixed left-0 top-0 bg-primaryGradient z-40 mt-16">

        <ul className="flex justify-center items-center flex-col mt-6">
          {sideBarMenu.map((item, index) => {
            const isActive = window.location.pathname.includes(item.path.trim().toLowerCase());
            return (
              <Tooltip placement='right' title={databaseStatus ||item.name==="Database"||item.name === "Config"?"":"Please connect to database"} style={{fontSize:"10px"}} >
              <li
                key={index}
                onClick={() => {
                  if (databaseStatus || item.name === "Database"||item.name === "Config") {
                    handleItemClick(item);
                  }
                }}
                ref={item.name === 'Config' ? configButtonRef : null}
                className={`${databaseStatus||item.name==="Database"||item.name === "Config"
                    ? "cursor-pointer mb-2 rounded flex flex-col justify-center items-center"
                    : "cursor-not-allowed opacity-50 mb-2 rounded flex flex-col justify-center items-center"
                  }`}

              >

                <div
                  className={`flex justify-center items-center rounded-full w-12 h-12 ${isActive  ? 'bg-[#EB7E39]' :databaseStatus||item.name==="Database"||item.name === "Config"? 'hover:bg-[#EB7E39]':null}`}
                >
                  {item.icon && (
                    <item.icon
                      className={`!w-6 !h-6  ${isActive ? 'text-[#EB7E39]' : 'text-white'}`}
                    />
                  )}
                </div>
                <span className="text-center text-[10px] pt-2">{item.name}</span>
              </li>
              </Tooltip>
            );
          })}
          {configSubmenuOpen && (
            <div
              className="absolute w-24 flex flex-col -mt-8 items-start mb-2 p-2 bg-white border border-gray-200 text-black rounded-lg shadow-lg z-50"
              style={{
                top: submenuPosition.top,
                left: submenuPosition.left - 4,
                position: 'absolute',
              }}
            >
              <button
                onClick={importConfig}
                className="mb-2 border-2-dashed border-b text-black p-2 hover:text-baseOrange text-[10px]"
              >
                Import
              </button>
              <button
                onClick={exportConfig}
                className="text-black p-2  hover:text-baseOrange text-[10px]"
              >
                Export
              </button>
            </div>
          )}


          {/* {sideBarMenu && <>          <div className="cursor-pointer mb-2 rounded flex flex-col justify-center items-center" onClick={importConfig}>
            <div className='w-12 h-12 flex justify-center items-center rounded-full p-5 hover:bg-baseOrange'>
              <PublishOutlinedIcon className=' -rotate-90 ' />

            </div>
            <span className='text-[10px]/[16px] text-center'>Import</span>
          </div>
            <div className="cursor-pointer mb-2 rounded flex flex-col justify-center items-center">
              <div className='w-12 h-12 flex justify-center items-center rounded-full p-5 hover:bg-baseOrange' onClick={exportConfig}>
                <PublishOutlinedIcon className=' rotate-90 ' />

              </div>
              <span className='text-[10px]/[16px] text-center'>Export</span>
            </div>
          </>} */}
        </ul>
      </div>

      {dbNotificationOpen && (
        <DbNotification
          open={dbNotificationOpen}
          message={dbNotificationMessage}
          severity={dbNotificationSeverity}
          onClose={handleDbNotificationClose}
          handleViewDetails={handleViewDetails}
          backgroundClass="backdrop-blur-[5px]"
        />
      )}
      {config.open && (
        <Notification
          open={config.open}
          message={config.message}
          severity={config.severity}
          onClose={closeNotification}

        />
      )}
    </div>
  );
};

export default Sidebar;
