import React from 'react';
import { NoFilterSvg } from '../../assets/svgs';

const Index = ({ label }) => {
    return (
        <div className="w-full h-full rounded-b-md flex justify-center items-center  rounded-r-md bg-white">
            <div className=' flex justify-center items-center w-full h-full'>
                <div className='flex flex-col justify-center items-center justify-center text-center '>
                    <div className='text-customGray font-semibold pb-4 text-[24px]/[36px]'>{label}</div>
                   <div> <NoFilterSvg /></div>
                </div>
            </div>
        </div>
    );
};

export default Index;
