import React from "react";
import ImageLight from "../assets/img/login-office.png";
import Logo from "../assets/img/symbiotic-logo 1.png";
import LoginForm from "../components/Forms/LoginForm";
import { useAuthContext } from "../context/AuthContext";

const LogoIconComponent = (props) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2733_11609" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
        <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2733_11609)">
        <circle cx="10.5" cy="10.5" r="10.5" fill="#462C23" />
        <path d="M4.93279 5.70433L10.624 -1.77813L12.6505 0.336436L4.93279 5.70433Z" fill="#58AB40" />
        <path d="M7.37806 9.44199L18.4392 1.01108L20.5917 5.10297L7.37806 9.44199Z" fill="#F67A35" />
        <path d="M14.5451 11.7701L2.33501 19.7632L0.447411 15.5425L14.5451 11.7701Z" fill="#F9C634" />
        <path d="M16.2855 16.2293L7.78451 25.6754L5.42269 22.8244L16.2855 16.2293Z" fill="#1A94BF" />
      </g>
    </svg>
  );
};

function Login() {
  const { customerDetails } = useAuthContext();
  if (!customerDetails?.logo) {
    return <h2 className="m-4">Loading...</h2>
  }
  return (
    <div className="flex h-screen bg-background-gradient">
      <div className="flex items-center justify-center w-full md:w-6/12 px-6">
        <div className="w-7/12">
          <div className="flex justify-left mb-1 gap-3 text-center content-center">
            <img className="h-12 w-auto" /* src={Logo} */ src={customerDetails?.logo} alt="Symbiotic Logo" />
            {/* {customerDetails?.name ?
              <h1 className="flex my-auto text-2xl font-semibold text-gray-700">{customerDetails.name}</h1>
              : null} */}
          </div>

          {/* <h1 className="mb-[40px] text-2xl font-semibold text-gray-700 dark:text-gray-200"> */}
          <h1 className="mb-[40px] text-2xl font-semibold text-textHeading">
            Welcome User!
          </h1>
          <LoginForm />
        </div>
      </div>

      <div className="hidden md:block w-1/2 m-1 my-2">
        <img
          aria-hidden="true"
          className="object-cover w-full h-full"
          src={ImageLight}
          alt="Office"
        />
      </div>

      <div className="fixed bottom-2 left-4 text-black text-[12px] flex items-center justify-center gap-x-2">
        <span>Powered by</span>
        <LogoIconComponent className="mx-1" />
        <span>Symbiotic automation system</span>
      </div>
    </div>
  );
}

export default Login;