export function safeExtractDate(line, position) {
   
    if (typeof line !== "string") {
      console.warn(`Invalid input for line: Expected a string but got ${typeof line}`, line);
      return null; // Return a fallback value
    }
  
    const dates = line.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g);
    if (!dates || dates.length < position) {
      console.warn(`No date found at position ${position} in line:`, line);
      return null; // Return a fallback value
    }
  
    return new Date(dates[position - 1]); // Position is 1-based
  }
  