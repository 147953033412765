import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATHS } from '../routes/path';
import { SuccessIcon } from '../assets/svgs';
import DatePicker from '../components/DateSelecter';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import dayjs from 'dayjs'; // Import dayjs
import CustomModal from '../components/CustomModal/index'
import { CircularProgress } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import DbNotification from '../components/Notifications/DbNotification';
import { safeExtractDate } from '../utils/dateExtraction.utils';
import axios from 'axios';
import zIndex from '@mui/material/styles/zIndex';
dayjs.extend(utc);
const MasterBatch = () => {
  const [loading, setLoading] = useState(true)
  const axiosPrivate = useAxiosPrivate();
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchParams, setSearchParams] = useSearchParams({ action: "view" });
  const [action, setAction] = useState("view");
  const id = searchParams.get("id");
  const [batchList, setBatchList] = useState([]);
  const [batchName, setBatchName] = useState("")
  const [isMasterBatchTrue, setIsMasterBatchTrue] = useState(false)
  const [successOverlay, setSuccessOverlay] = useState(false)
  const [masterBatchDetails, setMasterBatchDetails] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [resetMasterBatch, setResetMasterBatch] = useState(false)
  const [modalContext, setModalContext] = useState({
    text: "",
    title: "",
  });

  const [selectedBatchDetails, setSelectedBatchDetails] = useState(null); // Store selected batch details
  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleOverlayClick = (e) => {
    closeModal();

    if (formRef.current && !formRef.current.contains(e.target)) {
      navigate(PATHS.homePath); // Navigate back to the home route
      
    }
  };

  useEffect(() => {
    const getMasterBatch = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap(`mastBatchPath`));
        if (response.data.is_master_batch) {
          setIsMasterBatchTrue(true);
          setMasterBatchDetails([response.data]);
          setBatchName(response.data.batch_id);
        }
      } catch (err) {
        if (err.status === 400) {
          setSearchParams({ action: "add" });
        }
      }
    };

    if (action !== "add" && action !== "ex") {
      getMasterBatch();
    }
  }, [action, axiosPrivate]);

  useEffect(() => {
    const getBatchDetails = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap('listBatches'));
        setBatchList(response.data.data);
      } catch (err) {

      }
    };
    getBatchDetails();
  }, [axiosPrivate]);

  // Filter batchList based on search query
  const filteredBatchList = batchList.filter((batch) => {
    const batchName = batch.id.toString();
    return batchName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleBatchClick = (batch) => {
    setSelectedBatchDetails(batch); // Set the selected batch
  };


  const formatStartDate = (date) => {
    return date ? dayjs(date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
  };

  const formatEndDate = (date) => {
    return date ? dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
  };
  const handleClose = () => {
    navigate(PATHS.homePath)
  }
  const handleAddBatch = async () => {

    try {
      const formattedStartDate = formatStartDate(dateRange[0]);
      const formattedEndDate = formatEndDate(dateRange[1]);
      let body = {
        batch_id: selectedBatchDetails.id,
        batch_id_with_date: `${selectedBatchDetails.id} - ${formattedStartDate} - ${formattedEndDate}`,
        is_master_batch: true
      }
      const response = await axiosPrivate.post(getAPIMap("setMasterBatch"), body)
      if (response.status === 200 || response.status === 201) {
        setSuccessOverlay(true)
        setModalContext({ text: `${selectedBatchDetails.id} set as Master Batch successfully.`, })
      }
    } catch (error) {
    } finally {
      setLoading(false)
      setResetMasterBatch(false)
    }
  }

  const handleViewDetails = () => {
    setSearchParams({ action: "add" })
    setResetMasterBatch(true)
    setIsMasterBatchTrue(false)
  };

  const handleEditBatch = () => {
    setSearchParams({ action: "add" })
  }
  useEffect(() => {
    const actionParam = searchParams.get("action");
    if (actionParam) {
      setAction(actionParam);
    }
  }, [searchParams]);

  const [isModalOpen, setIsModalOpen] = useState(false);  

  const closeModal = () => {  
    setIsModalOpen(false);  
  }; 
  return (
    
    <div
      className="fixed inset-0 bg-backgroundColor backdrop-blur-[5px] flex items-center justify-center bg-opacity-70 "
      onClick={handleOverlayClick}
    > 
      {action === "add" && !successOverlay ?
        <div
          className="bg-white flex flex-col rounded-lg shadow-lg ml-20 h-4/6 max-h-screen w-7/12 overflow-hidden"
          ref={formRef}
        >
          <div className="flex flex-1 flex-wrap md:flex-nowrap">
          
            {/* Left Section */}
            <div className="w-full md:w-1/2 p-4 border-r border-gray-200 max-h-full mt-5">
              <div>
                <label className="block text-sm font-medium text-[12px]/[16px] text-[#171717]">
                  Master Batch
                </label>
                <label className="text-[10px]/[18px]  mb-4 text-textGray">
                  Select a batch to set Master Batch
                </label>
              </div>
              <span className='mt-3'>
                <DatePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  label="Select date range"
                  portalTarget={document.body}

                  className="relative h-1/6"

                />
              </span>


              <div className="relative mt-3">
                <input
                  type="text"
                  placeholder="Search batch..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-1 text-[10px] border-t border-b border-[#D9D9D9] focus:outline-none "
                />
                <span className="absolute inset-y-0 right-5 flex items-center text-gray-400">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5313 11.3197L13.7111 13.4294M8.06248 5.14125C9.31034 5.14125 10.3219 6.15326 10.3219 7.40164M13.0082 7.80349C13.0082 10.5222 10.8051 12.7261 8.08758 12.7261C5.37001 12.7261 3.16699 10.5222 3.16699 7.80349C3.16699 5.0848 5.37001 2.88086 8.08758 2.88086C10.8051 2.88086 13.0082 5.0848 13.0082 7.80349Z"
                      stroke="#A9A3A3"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </div>

              <div className="mt-4 max-h-48 overflow-y-auto border border-gray-200 rounded-md">
                {filteredBatchList.length > 0 ? (
                  filteredBatchList.map((batch, index) => (
                    <div
                      key={index}
                      onClick={() => handleBatchClick(batch)}
                      className={`px-4 py-2 text-[10px] border-b last:border-b-0 hover:bg-hoverOrange cursor-pointer ${selectedBatchDetails?.id === batch.id ? 'bg-hoverOrange' : ''}`}
                    >
                      {batch.name || batch.id}
                    </div>
                  ))
                ) : (
                  <div className="w-full h-full flex justify-center items-center">
                    <div className="flex flex-col p-8 items-center">
                      <CircularProgress
                        size={50}
                        sx={{ color: "#555555" }}
                      />
                      Fetching Batch details
                    </div>
                  </div>
                )}
              </div>
            </div>
           
            {/* Right Section */}
            <div className="w-full md:w-1/2 p-6 flex flex-col justify-between max-h-full overflow-y-auto">
            <button onClick={() => { navigate(PATHS.homePath) }} className="text-gray-600 hover:text-gray-800 text-2xl ml-80">  
                        &times;  
                    </button> 
              <h3 className="mt-4 text-customGray text-[12px]/[18px]">Selected batch details</h3>

              <div className="flex-grow">
                {selectedBatchDetails || dateRange[0] !== null && dateRange !== null ? (
                  <div className="w-full h-5/6 py-6 mt-2 px-4 border-[#D9D9D9] border rounded-md">
                    <pre className="text-[12px]/[20px] underline text-[#555555] overflow-x-auto">
                      {selectedBatchDetails?.id}
                    </pre>
                    {dateRange[0] !== null && dateRange[1] !== null && (
                      <>
                        <div className="flex items-center gap-x-6 w-full">
                          <div className="flex">
                            <span className="text-black text-[10px]/[18px] mt-2">Start Date</span>
                          </div>
                          <div className="mt-2 text-[10px]/[18px] text-[#737373] flex justify-end">
                            {formatStartDate(dateRange[0])}
                          </div>
                        </div>
                        <div className="flex items-center gap-x-6 w-full">
                          <div className="flex">
                            <span className="text-black text-[10px]/[18px] mt-2">End Date</span>
                          </div>
                          <div className="mt-2 pl-2 text-[10px]/[18px] text-[#737373] flex justify-end">
                            {dateRange[1] !== "Invalid Date" && formatEndDate(dateRange[1])}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="w-full h-5/6 py-6 mt-4 text-[10px]/[18px] text-customGray px-4 border-[#D9D9D9] border rounded-md">
                    Select a batch to see details.
                  </div>
                )}
              </div>

              <div className="flex gap-x-2 justify-end">
                <button
                  className="text-[#0F172A] border border-[#A9A3A3] py-2 px-4 rounded-md text-[12px]/[24px]"
                  onClick={() => { navigate(PATHS.homePath) }}
                >
                  Cancel
                </button>
                <button
                  className={`text-white py-2 px-2 rounded-md bg-baseOrange text-[12px]/[20px] ${selectedBatchDetails === null || dateRange[0] === null || dateRange[1] === null ? "opacity-60" : ""}`}
                  disabled={selectedBatchDetails === null || dateRange[0] === null || dateRange[1] === null}
                  onClick={handleAddBatch}
                >
                  {resetMasterBatch ? "Reset Master Batch" : "Set Master Batch"}
                </button>
              </div>
            </div>
          </div>
        </div>
        : action === "ex" ? <div
          className="bg-white flex flex-col rounded-lg shadow-lg ml-20 h-4/6 max-w-5xl w-full"
          ref={formRef}
        >
          
          <div className="flex flex-1">

            <div className="w-1/2 p-2 px-6 border-r my-4  border-gray-200">
              <div>
            
                <label className="block text-sm font-semibold text-[12px]/[16px] text-customGray">
                  Master Batch
                </label>
                <label className="text-[10px]/[18px] text-textGray mb-2">
                  Select a batch to set Master Batch
                </label>
                <div className='!z-50'>
                  <DatePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    label="Select date range"
                  />
                </div>
              </div>

              <div className="relative mt-3">
                <input
                  type="text"
                  placeholder="Search batch..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-2  text-sm border-t border-b border-[#D9D9D9] focus:outline-none "
                />
                <span className="absolute inset-y-0 right-5 flex items-center text-gray-400">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5313 11.3197L13.7111 13.4294M8.06248 5.14125C9.31034 5.14125 10.3219 6.15326 10.3219 7.40164M13.0082 7.80349C13.0082 10.5222 10.8051 12.7261 8.08758 12.7261C5.37001 12.7261 3.16699 10.5222 3.16699 7.80349C3.16699 5.0848 5.37001 2.88086 8.08758 2.88086C10.8051 2.88086 13.0082 5.0848 13.0082 7.80349Z"
                      stroke="#A9A3A3"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </div>

              <div className="mt-4 max-h-60 overflow-y-auto border border-gray-200 rounded-md">
                {filteredBatchList.length > 0 ? (
                  filteredBatchList.map((batch, index) => (
                    <div
                      key={index}
                      onClick={() => handleBatchClick(batch)}
                      className={`px-4 py-2 text-sm border-b last:border-b-0 hover:bg-[#F4F4F4] cursor-pointer ${selectedBatchDetails?.id === batch.id ? 'bg-blue-100' : ''
                        }`}
                    >
                      {batch.name || batch.id}
                    </div>
                  ))
                ) : (
                  <div className='w-full h-full flex justify-center items-center'>
                    <div className='flex flex-col p-8 items-center'>
                      <CircularProgress
                        size={50}
                        sx={{
                          color: "#555555",
                        }}

                      />
                      Fetching Batch details
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2 p-6 flex flex-col justify-between">
              <h3 className='mt-2'>Selected batch details</h3>
              <>
                {masterBatchDetails && <div className="w-full h-[75%] py-6 mt-2 px-4 border-[#D9D9D9] border rounded-md">
                  <pre className=" text-[14px]/[20px] underline text-[#555555] overflow-x-auto">{masterBatchDetails[0]?.batch_id}</pre>
                  <>
                    <div className='flex items-center gap-x-6 w-full'>
                      <div className='flex'>
                        <span className='text-black text-[12px]/[18px] mt-2'>Start Date</span>
                      </div>
                      <div className='mt-2 text-[12px]/[18px] text-[#737373] flex justify-end'>
                        {safeExtractDate(masterBatchDetails[0]?.batch_id_with_date, 1)
                          ? dayjs(safeExtractDate(masterBatchDetails[0]?.batch_id_with_date, 1)).format("YYYY-MM-DD HH:mm:ss")
                          : null}
                      </div>
                    </div>

                    <div className='flex items-center gap-x-6 w-full'>
                      <div className='flex'>
                        <span className='text-black text-[12px]/[18px] mt-2'>End Date</span>
                      </div>
                      <div className='mt-2 pl-2 text-[12px]/[18px] text-[#737373] flex justify-end'>
                        {safeExtractDate(masterBatchDetails[0]?.batch_id_with_date, 2)
                          ? dayjs(safeExtractDate(masterBatchDetails[0]?.batch_id_with_date, 2)).format("YYYY-MM-DD HH:mm:ss")
                          : null}
                      </div>
                    </div>
                  </>

                </div>}
              </>

              <div className='flex gap-x-2 justify-end'>
                <button
                  className=" text-[#0F172A] border border-[#A9A3A3] py-2 px-4 rounded-md text-[12px]/[24px]" onClick={() => { navigate(PATHS.homePath) }}
                >
                  Cancel
                </button>
                <button
                  className={`text-white py-2 px-4 rounded-md bg-baseOrange text-[12px]/[20px] `}

                  onClick={handleEditBatch}
                >
                  Reset Master Batch
                </button>
              </div>
            </div>
          </div>
        </div> : null}

      {isMasterBatchTrue && <DbNotification open={isMasterBatchTrue}
        message={`Batch ${batchName} is already set as master batch.`}
        severity={"warning"}
        onClose={handleClose}
        handleViewDetails={handleViewDetails}
        buttonText={"Change Master Batch"} />}
      <CustomModal showModal={successOverlay} modalContext={modalContext} icon={<SuccessIcon />} closeModal={() => { setSuccessOverlay(false); navigate(PATHS.homePath); }} navigate={() => { setSuccessOverlay(false); navigate(PATHS.homePath) }} />
    </div>
  );
};

export default MasterBatch;
