import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import GroupInputField from '../../components/InputField/GroupInputField';
import Select from '../../components/SelectBox';
import { FirstIcon, FirstCompletedIcon, SearchBoxIcon } from '../../assets/svgs';
import MultiSelect from '../SelectBox/MultiSelect';
import Notification from '../Notifications/index';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';
import getAPIMap from '../../routes/url/ApiUrls';
import { formatOptions } from '../../features/batch-comparison/batch-comparison.constants';

const EditGroup = ({ disableBackdropClick, groupId, handleClose, open, editGroupPenDetails, setNotification, existGroup }) => {
    const [groupDetails, setGroupDetails] = useState(null);
    const { UpdateGroupPenDetail } = useContext(AuthContext);
    const [searchText, setSearchText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const axiosPrivate = useAxiosPrivate();

    const [groupTypes, setGroupTypes] = useState([]);
    const [pens, setPens] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedPen, setSelectedPen] = useState(null);
    const [checkedPens, setCheckedPens] = useState({});
    const [addedPens, setAddedPens] = useState([]);
    const [PengroupId, setPenGroupId] = useState(null);
    const [availablePens, setAvailablePens] = useState([]);

    const [selectedPenDetails, setSelectedPenDetails] = useState({});
    const [selectedPenData, setSelectedPenData] = useState(null);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');
    const [groupPenDetails, setGroupPenDetails] = useState({
        minValue: '',
        maxValue: '',
        format: '',
        isLogScale: false,
        penName: '',
    });
    const [validationErrors, setValidationErrors] = useState({
        min: false,
        max: false,
        format: false,
    });

    const handleModalClose = () => {
        resetFormState(); // Reset form state before closing
        handleClose();     // Call the provided handleClose from props
    };
    const fetchPen = async () => {
        const accessToken = localStorage.getItem("accessToken")
        try {
          const response = await axiosPrivate.get(getAPIMap("penlist"));
          return response.data;
        } catch (error) {
          console.error("Failed to fetch roles:", error.message);
          return null;
        }
      };
    const validationSchema = Yup.object().shape({
        groupName: Yup.string()
            .matches(/^(?=.*[A-Za-z0-9])[ A-Za-z0-9]*$/, "Groupname must be alphanumeric")
            .max(64, 'Group Name must be at most 64 characters')
            .required('Group Name is required'),
        groupType: Yup.string().required('Group Type is required'),
        penList: Yup.array().min(1, 'At least one pen must be selected').required('Penlist is required'),
        defaultPen: Yup.string().required('Default Pen is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            groupName: groupDetails?.name || "",
            groupType: groupDetails?.type || "",
            penList: groupDetails?.penIds || [],
            defaultPen: groupDetails?.defaultPen || "",
        },
        validationSchema,
        onSubmit: async (values) => {
            // Handle form submission
            await handleSaveClick(values);
        },
    });
    const getGroupDetailsById = async (groupId) => {
        const accessToken = localStorage.getItem("accessToken")
        try {
          const response = await axiosPrivate.get(
            getAPIMap("getGroupById").replace(":id", groupId)          );
          return response.data;
        } catch (error) {
          console.error("Failed to fetch group details:", error);
          throw error;
        }
      };
    
    useEffect(() => {
        const fetchGroupDetails = async () => {
            if (!groupId) return;
            try {
                setLoading(true);
                const groupDetails = await getGroupDetailsById(groupId);
  
                // Extract list of pen IDs
                const penList = groupDetails.groupPenDetails?.map(
                    (penDetail) => penDetail.pen_id
                ) || [];

                // Find the default pen detail
                let defaultPenId = "";
                const defaultPenDetail = groupDetails.groupPenDetails?.find(
                    penDetail => penDetail.isDefault === true
                );

                // If a default pen is found, get its pen_id
                if (defaultPenDetail) {
                    defaultPenId = defaultPenDetail.pen_id; // Use pen_id instead of pen_name
                }

                formik.setValues({
                    groupName: groupDetails.name || "",
                    groupType: groupDetails.type || "",
                    penList: penList,
                    defaultPen: defaultPenId, // Store the pen ID here
                });
            } catch (error) {
                console.error("Error fetching group details:", error);
                setError("Failed to load group details");
            } finally {
                setLoading(false);
            }
        };

        fetchGroupDetails();
    }, [groupId]);



    const handlePenClick = (pen) => {
        setSelectedPen(pen.value);

        const selectedPenData = groupPenDetails.find((p) => p.pen_id === pen.value);
        setSelectedPenData(selectedPenData);

        if (selectedPenData && selectedPenData.penlist) {
            setSelectedPenDetails({
                penName: selectedPenData.penlist.pen_name,
                min: selectedPenData.min,
                max: selectedPenData.max,
                format: selectedPenData.format || '',
                logScale: selectedPenData.logscale || false,
            });
        } else {
            setSelectedPenDetails({
                penName: null,
                min: null,
                max: null,
                format: '',
                logScale: false,
            });
        }
    };

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    useEffect(() => {
        if (currentStep === 2 && PengroupId) {
            const fetchPenDetails = async () => {
                try {
                    const penData = await getGroupDetailsById(PengroupId); // Fetch details
                    setGroupPenDetails(penData.groupPenDetails || []); // Safely access and set the pen details
                } catch (error) {
                    console.error("Failed to fetch pen details:", error);
                }
            };

            fetchPenDetails();
        }
    }, [currentStep, PengroupId]);

    const GroupPenOptions = Array.isArray(groupPenDetails)
        ? groupPenDetails.map((pen) => ({
            value: pen.pen_id,
            label: pen.penlist?.pen_name, // Add fallback in case pen_name is missing
        }))
        : [];

    const handleAddClick = () => {
        let localErrorMessage = '';
        let hasError = false;
        if (!selectedPenData.min || !selectedPenData.max || !selectedPenData.format) {
            localErrorMessage = 'All fields must be filled!';
            hasError = true;
            setValidationErrors({
                min: !selectedPenData.min,
                max: !selectedPenData.max,
                format: !selectedPenData.format,
            });
        } else {
            setValidationErrors({ min: false, max: false, format: false });

            if (Number(selectedPenData.max) <= Number(selectedPenData.min)) {
                localErrorMessage = 'Max value must be greater than Min value!';
                hasError = true;
                setValidationErrors({ min: false, max: true, format: false });
            }
        }

        if (hasError) {
            setErrorMessage(localErrorMessage);
            return;
        }

        if (selectedPenData?.penlist) {
            const existingPen = addedPens.find(pen => pen.id === selectedPenData.id);
            if (!existingPen) {
                setAddedPens((prevPens) => [
                    ...prevPens,
                    {
                        id: selectedPenData.id,
                        pen_name: selectedPenData.penlist.pen_name,
                        min: selectedPenData.min || null,
                        max: selectedPenData.max || null,
                        format: selectedPenData.format || null,
                        logScale: selectedPenData.logScale || false,
                    },
                ]);
                setAvailablePens((prevPens) =>
                    prevPens.filter(pen => pen.label !== selectedPenData.penlist.pen_name)
                );
                setSelectedPenData({
                    penlist: {
                        pen_name: '',
                    },
                    min: '',
                    max: '',
                    format: '',
                    logScale: false,
                });
                setErrorMessage('');
            } else {
                setErrorMessage('This pen has already been added.');
                setTimeout(() => {
                    setErrorMessage(''); // Clear the message
                }, 2000);
            }
        }
    };

    const resetFormState = () => {
        // setAddedPens([]);
        // setGroupPenDetails([]);
        setCurrentStep(1);
        setNotificationMessage('');
        setNotificationSeverity('');
    };
    const editGroup = async (groupId, updatedgroupDetails) => {
        const accessToken = localStorage.getItem("accessToken")
        try {
          const apiUrl = getAPIMap("updateGroupById").replace(":id", groupId);
    
          const response = await axiosPrivate.put(apiUrl, updatedgroupDetails);
    
          return response.data;
        } catch (error) {
          if (error.response) {
            console.error("Error Response Data:", error.response.data);
            console.error("Error Response Status:", error.response.status);
          } else {
            console.error("Failed to update Group:", error.message);
          }
          throw error;
        }
      };
    useEffect(() => {
        const fetchGroupTypes = async () => {
            try {
                const response = await axiosPrivate.get(`${getAPIMap("getGroupType")}`);
                const data = response.data;

                // Assuming data is an array of strings, map it to the expected format
                const formattedTypes = data.map(type => ({
                    value: type.toLowerCase(), // Use lower case or appropriate value
                    label: type // Use original string as label
                }));

                setGroupTypes(formattedTypes); // Set formatted data as group types
            } catch (error) {
                console.error('Failed to fetch group types:', error);
            }
        };

        fetchGroupTypes();
    }, []);

    useEffect(() => {
        const loadPens = async () => {
            setLoading(true);
            try {
                const data = await fetchPen();
                setPens(data || []);
            } catch (err) {
                setError('Error fetching pens');
            } finally {
                setLoading(false);
            }
        };

        loadPens();
    }, []);

    const [isLogScale, setIsLogScale] = useState(false);

    const handleLogToggle = () => {
        setSelectedPenData(prevData => ({
            ...prevData,
            logScale: !prevData.logScale,
        }));
    };

    const handleRemove = (penId) => {
        setAddedPens(addedPens.filter(pen => pen.id !== penId));
        const removedPen = addedPens.find(pen => pen.id === penId);
        if (removedPen) {
            setAvailablePens((prevPens) => [
                ...prevPens,
                { value: removedPen.id, label: removedPen.pen_name }, // Assuming you have a way to reconstruct the pen object
            ]);
        }
    };

    const filteredPens = availablePens.filter(pen =>
        pen.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleInputChange = (field, value) => {
        setSelectedPenData((prevData) => {
            const updatedData = { ...prevData, [field]: value };
            // Set logScale to true only if the format is set to 'exponential'
            updatedData.logScale = updatedData.format === 'exponential';
            return updatedData;
        });
    };

    const toggleLogScale = () => {
        setSelectedPenData((prevData) => ({
            ...prevData,
            logScale: !prevData.logScale,
        }));
    };

    const PenOptions = pens.map(pen => ({
        value: pen.id,
        label: pen.pen_name,
    }));

    const selectedPenOptions = formik.values.penList.map(penId => {
        const pen = PenOptions.find(option => option.value === penId);
        return pen ? { value: pen.value, label: pen.label } : null;
    }).filter(option => option !== null);

    // Ensure the default pen option is included and correctly selected
    const defaultPenOption = PenOptions.find(pen => pen.value === formik.values.defaultPen);

    if (defaultPenOption && !selectedPenOptions.some(option => option.value === defaultPenOption.value)) {
        selectedPenOptions.unshift(defaultPenOption);  // Add to the beginning of the options
    }
    const renderLabel = (label, isRequired = false) => (
        <div className="text-[12px] font-medium mb-[2px]">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );

    const renderPenLabel = (label, isRequired = false) => (
        <div className="text-[12px] font-medium text-[#191919] mb-[2px]">
            {label}
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );

    const handleSaveClick = async () => {
        try {
            const updatePromises = addedPens.map(async (addedPen) => {
                const groupPenDetail = groupPenDetails.find(pen => pen.id === addedPen.id);

                if (!groupPenDetail) return null;

                const updatedPen = {
                    min: addedPen.min,
                    max: addedPen.max,
                    format: addedPen.format,
                    logScale: addedPen.logScale,
                };

                try {
                    return await editGroupPenDetails(groupPenDetail.id, updatedPen);
                } catch (updateError) {
                    console.error(`Failed to update pen ID ${groupPenDetail.id}:`, updateError);
                    return null;
                }
            });

            const responses = await Promise.all(updatePromises);

            setGroupPenDetails((prevDetails) =>
                prevDetails.map((pen) => {
                    const updatedResponse = responses.find(response => response && response.id === pen.id);
                    if (updatedResponse) {
                        return {
                            ...pen,
                            min: updatedResponse.min || pen.min,
                            max: updatedResponse.max || pen.max,
                            format: updatedResponse.format || pen.format,
                            logScale: updatedResponse.logScale || pen.logScale,
                            penlist: { pen_name: updatedResponse.penlist?.pen_name || pen.penlist.pen_name },
                        };
                    }
                    return pen;
                })
            );

            setNotification('Group updated successfully. Check the group list.', 'success');


            resetFormState();
            setCurrentStep(1);
            handleClose();

        } catch (error) {
            setCurrentStep(1);
            handleClose();
        }
    };

    const renderFirstStep = () => (
        <>
            <div className="flex items-center justify-center mb-[8px]">
                {currentStep >= 2 ? <FirstCompletedIcon /> : <FirstIcon />}

            </div>

            {renderLabel('Group Name', true)}
            <GroupInputField
                type="text"
                placeholder="Enter the group name"
                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                inputClass={`p-3 h-[44px] border rounded-md text-[#555555] text-[10px]/[24px] ${formik.touched.groupName && formik.errors.groupName
                    ? "border-red-500"
                    : "border-gray-300"
                    }`}
                {...formik.getFieldProps("groupName")}
                className={`border rounded-md w-full mb-[16px] text-[10px] text-[#555555] ${formik.touched.groupName && formik.errors.groupName ? 'border-red-500' : 'border-gray-300'}`}

            />
            {formik.touched.groupName && formik.errors.groupName && <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.groupName}</div>}

            {renderLabel('Group Type', true)}
            <Select
                name="groupType"
                value={formik.values.groupType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={groupTypes}
                labelClass="text-textHeading text-[10px] text-[#191919] font-medium"
                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#979797] text-[10px] font-medium placeholder:text-[#979797] placeholder:font-medium placeholder:text-[12px]"
                className={`w-full mb-[16px] p-3 h-[44px] border rounded-md text-[10px] text-[#979797] placeholder:text-[#979797] placeholder:font-medium placeholder:text-[12px] ${formik.touched.groupType && formik.errors.groupType ? 'border-red-500' : 'border-gray-300'}`}
            />
            {formik.touched.groupType && formik.errors.groupType && <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.groupType}</div>}

            {renderLabel('Pen List', true)}
            <MultiSelect
                name="penList"
                options={PenOptions}
                value={formik.values.penList}
                onChange={(penList) => {
                    if (penList.length > 10) {
                        return;
                    }
                    formik.setFieldValue("penList", penList); // Set the selected pens
                }}
                placeholder="Select Pens"
                className="mb-[16px] "

            />
            {formik.touched.penList && formik.errors.penList && <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.penList}</div>}

            {renderLabel("Default Pen", true)}
            <Select
                name="defaultPen"
                value={formik.values.defaultPen}  // Use the pen ID here
                placeholder={"Select default pen"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={selectedPenOptions}
                className={`w-full p-3 h-[44px] border rounded-md text-[12px] text-[#555555] ${formik.touched.defaultPen && formik.errors.defaultPen ? 'border-red-500' : 'border-gray-300'}`}
                labelClass="text-textHeading text-[14px] font-semibold"
                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]"
            />
            {formik.touched.defaultPen && formik.errors.defaultPen && (
                <div className="text-red-500 text-xs mt-[-10px]">{formik.errors.defaultPen}</div>
            )}

            <div className="flex justify-end mt-4 mb-0">
                <button
                    type="button"
                    className="flex justify-center items-center py-2 px-4 h-[40px] bg-white border border-[#A9A3A3] text-[12px] text-[#555555] rounded-md mr-2 hover:bg-gray-100"
                    onClick={handleModalClose} // Call handleModalClose on click
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className={`flex justify-center items-center py-2 px-1 h-[40px] w-[133px] bg-[#EB7E39] text-[12px] backdrop:text-[14px] text-white rounded-md `}
                    onClick={async () => {
                        if (Object.keys(formik.errors || {}).length) {
                            return;
                        }
                        const errors = await formik.validateForm();
                        formik.setTouched({
                            groupName: true,
                            groupType: true,
                            penList: true,
                            defaultPen: true,
                        });


                        if (Object.keys(errors).length === 0) {
                            try {
                                const updatedgroupDetails = {
                                    name: formik.values.groupName,
                                    penIds: formik.values.penList,
                                    type: formik.values.groupType,
                                    defaultPenId: formik.values.defaultPen,
                                };
                                const response = await editGroup(groupId, updatedgroupDetails);
                                if (response?.groupDetail) {
                                    setGroupDetails(response.groupDetail);

                                    const PengroupId = response.groupDetail.id;
                                    if (PengroupId) {
                                        setPenGroupId(PengroupId);
                                    }
                                } else {
                                    console.warn("Group Detail not present in the response");
                                }

                                setCurrentStep(2);
                            } catch (error) {
                                console.error("Error adding group:", error);
                                if (error?.status && error?.response) {
                                    const errMsg = error.response?.data?.message;
                                    const formattedMsg = errMsg?.toLowerCase()?.replace(/\s/g, '');

                                    const errorMapping = {
                                        groupName: "groupname",
                                        groupType: "grouptype",
                                        penList: "penlist",
                                        defaultPen: "defaultpen"
                                    };

                                    for (const [key, value] of Object.entries(errorMapping)) {
                                        if (formattedMsg?.includes(value)) {
                                            errors[key] = errMsg;
                                            break; // Exit loop after the first match
                                        }
                                    }

                                    formik.setErrors(errors);
                                }
                            }
                        }
                    }}
                >
                    Save and Next
                </button>
            </div>
        </>
    );

    const filteredOptions = GroupPenOptions.filter(pen =>
        pen.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const renderSecondStep = () => (
        <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-center mb-1">
                <FirstCompletedIcon className="text-[#EB7E39]" />

            </div>
            <div className="flex justify-between">
                {/* Available Pens */}
                <div className="flex flex-col ml-[-10px] w-1/4">
                    <label className="mb-2 flex text-[12px] text-[#555555] font-medium">Available Pens</label>
                    <div className="border border-gray-400 rounded-md w-[205px] h-[271px] overflow-hidden">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search pen name"
                                className="w-full p-2 rounded-[5px] text-[10px] focus:outline-none focus:ring focus:ring-orange-300"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                                <SearchBoxIcon /> {/* Use your search icon here */}
                            </div>
                        </div>
                        <div className="border-b border-gray-400 mb-1"></div> {/* Space below the input */}

                        <ul className="h-[232px] overflow-y-auto max-h-[550px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((pen, index) => (
                                    <li
                                        key={pen.value}
                                        onClick={() => handlePenClick(pen)}
                                        className={`flex flex-col p-2 text-[#555555] text-[10px] cursor-pointer ${selectedPen === pen.value ? 'bg-[#F5F5F5]' : ''
                                            } hover:bg-[#F5F5F5]`}
                                    >
                                        <span className="flex justify-between ml-2">{pen.label}</span>
                                        {index < filteredOptions.length - 1 && (
                                            <div className="border-b border-[#F5F5F5] w-5/6 mx-auto mt-1"></div>
                                        )}
                                    </li>
                                ))
                            ) : (
                                <li className="flex justify-center items-center p-2 text-[#aaa] text-[10px]">No results found</li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="flex flex-col w-1/3">
                    <label className="mb-2 flex text-[12px] text-[#555555] font-medium" style={{ marginLeft: '10px' }} >Selected Pen Details</label>
                    <div className="border border-gray-400 rounded-md p-4 w-[272px] h-[272px] ml-[8px]">
                        <div className="grid grid-cols-[94px_1fr]">
                            <div className="flex items-center">{renderPenLabel('Pen Name', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.penlist?.pen_name || ''}
                                readOnly
                                className={`border rounded-md w-full text-[#555555] text-[10px] p-1 h-[32px] border-borderColorBox focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                placeholder="Pen Name"
                                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[12px]/[24px]"

                            />

                            <div className="flex items-center">{renderPenLabel('Min', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.min || ''} // Display min value
                                className={`border rounded-md w-full text-[#555555] text-[10px] mt-2 p-1 h-[32px] border-borderColorBox ${validationErrors.min ? 'border-red-500' : 'border-[#D5D7DA]'} focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                onChange={(e) => handleInputChange('min', e.target.value.replace(/[^0-9-]/g, ''))} // Allow only numbers
                                placeholder="Enter min value"
                            />

                            <div className="flex items-center">{renderPenLabel('Max', false)}</div>
                            <input
                                type="text"
                                value={selectedPenData?.max || ''} // Display max value
                                onChange={(e) => handleInputChange('max', e.target.value.replace(/[^0-9-]/g, ''))} // Allow only numbers
                                placeholder="Enter max value"
                                className={`border rounded-md w-full text-[#555555] text-[10px] mt-2 p-1 h-[32px] border-borderColorBox ${validationErrors.max ? 'border-red-500' : 'border-[#D5D7DA]'} focus:border-[#EB7E39] focus:outline-none`} // Added outline-none to remove unwanted outlines
                                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                                inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                            />

                            <div className="flex items-center">{renderPenLabel('Format', false)}</div>
                            <Select
                                name="format"
                                options={formatOptions}
                                value={selectedPenData?.format || ''} // Display format value
                                onChange={(e) => handleInputChange('format', e.target.value)}
                                placeholder="Select format"
                                className="border rounded-md text-[10px]/[24px] text-[#555555]"
                                labelClass="text-textHeading text-[10px]/[20px] font-semibold"
                                inputClass="p-1 h-[32px] w-[100px] border border-borderColorBox rounded-md text-[#555555] text-[10px]/[24px]"
                            />
                            {errorMessage && (
                                <span className="text-red-500 text-xs col-span-2">{errorMessage}</span>
                            )}
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <span className="text-[#191919] text-[12px] mr-2">Log Scale</span>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only"
                                        checked={selectedPenData?.logScale || false}
                                        onChange={toggleLogScale} // Make checkbox controlled
                                        disabled={selectedPenData?.format !== 'exponential'} // Disable if not exponential
                                    />
                                    <div className={`w-[21px] h-[12px] rounded-full shadow-inner transition ${selectedPenData?.logScale ? 'bg-green-500' : 'bg-gray-200'}`}></div>
                                    <div
                                        className={`dot absolute bg-white w-[10px] h-[10px] rounded-full transition transform ${selectedPenData?.logScale ? 'translate-x-2.5' : 'translate-x-0.5'}`} >
                                    </div>
                                </label>
                            </div>
                            <button
                                className="px-2 w-[71px] h-8 bg-white text-[#EB7E39] text-[12px] border border-[#EB7E39] rounded-md hover:bg-[#EB7E39] hover:text-white transition"
                                onClick={handleAddClick}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>

                {/* Added Pens */}
                <div className="w-1/3">
                    <label className="mb-2 flex text-[12px] text-[#555555] font-medium" style={{ marginLeft: '25px' }}>Edited Pens</label>

                    <div className="border border-gray-400 rounded-md p-2 w-[218px] h-[272px] ml-[25px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 scrollbar-thumb-rounded-full">
                        {addedPens.length === 0 ? (
                            <p className="text-gray-500 text-sm text-center"></p>
                        ) : (
                            addedPens.map((pen) => (
                                <div key={pen.id} className="items-center bg-[#D9D9D9] rounded-md px-2 mt-1 py-1 text-sm mr-1 text-[#555555] inline-block">
                                    <span className='text-[10px]'>{pen.pen_name}</span>
                                    <button
                                        className="ml-1 text-[#555555] font-bold focus:outline-none"
                                        onClick={() => handleRemove(pen.id)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            <div className="flex justify-end mt-4 space-x-4 text-[12px]">
                <button onClick={() => setCurrentStep(1)} className="border text-gray-600 px-4 py-2 rounded">
                    Back
                </button>
                <button className="border bg-[#EB7E39] text-white px-4 py-4 rounded" onClick={handleSaveClick}>
                    Save
                </button>
            </div>
        </div>
    );
    const modalWidth = currentStep === 1 ? 'w-[543px] h-[533px]' : 'w-[743px] h-[538px]';

    return (
        <>
            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ${open ? '' : 'hidden'}`} onClick={disableBackdropClick ? null : handleClose}>
                <div className={`${modalWidth} bg-white p-5 rounded-[10px] shadow-lg overflow-y-auto max-h-[590px]`} onClick={(e) => e.stopPropagation()}>
                    <div className="relative flex flex-col">
                        <button onClick={() => { handleModalClose(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>

                        <div className="text-textHeading2 text-[12px]/[16px] font-bold ">Edit Group</div>
                        {currentStep === 1 ? (
                            <div className="text-[#737373] text-[10px]/[20px] pt-1 mb-4">You can add a maximum of 10 pens in a group.</div>
                        ) : (
                            <div className="text-[#737373] text-[10px]/[20px] pt-1 mb-4">Save details for all the listed pens.</div>
                        )}

                    </div>

                    {currentStep === 1 ? renderFirstStep() : renderSecondStep()}
                </div>
            </div>
            <Notification
                open={notificationOpen}
                message={notificationMessage}
                onClose={handleNotificationClose}
                severity={notificationSeverity}
            />
        </>
    );
};

export default EditGroup;
