import React from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, DialogActions, IconButton } from '@mui/material';
import { SuccessIcon, ErrorIcon } from '../../assets/svgs';
import CloseIcon from '@mui/icons-material/Close'; // Import Material UI Close Icon

const Notification = ({ open, message, onClose, severity, disableBackdropClick }) => {
    
    const icon = severity === 'success' ? (
        <SuccessIcon sx={{ color: '#58AB40', fontSize: 40 }} />
    ) : (
        <ErrorIcon sx={{ color: '#FA4343', fontSize: 40 }} />
    );

    const heading = severity === 'success' ? 'Success' : 'Failure';
    const buttonColor = severity === 'success' ? '#58AB40' : '#FA4343';

    return (
        <Dialog
            open={open}
            aria-labelledby="notification-dialog-title"
            onClose={disableBackdropClick ? null : onClose}
            disableBackdropClick={disableBackdropClick}
            PaperProps={{
                style: {
                    width: '400px',  // Set a fixed width
                    minHeight: '200px', // Ensure a minimum height
                    borderRadius: '8px'
                }
            }}
        >
            <DialogTitle id="notification-dialog-title" className="flex items-center">
                {icon}
                <Typography variant="h6" className="mt-1 text-[#555555]">
                    {heading}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon className='w-[14px] h-[14px]'/>
                </IconButton>
            </DialogTitle>

            <DialogContent
                className="flex flex-col"
                style={{ padding: '16px 24px', marginTop: '-16px', flexGrow: 1, color:"#555555", fontSize:"10px" }} // Adjusted marginTop
            >
                <Typography variant="body1" sx={{fontSize:"14px",paddingLeft: '16px'}}>
                    {message}
                </Typography>
            </DialogContent>

            <DialogActions className="flex justify-end mb-2 mr-2">
                <Button
                    variant="contained"
                    onClick={onClose}
                    style={{
                        backgroundColor: buttonColor,
                        color: 'white',
                        width: '56px',
                        height: '32px',
                        fontSize: '14px',
                        textTransform: 'none',
                        borderRadius: '6px'
                    }}
                >
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Notification;
