import React, { useEffect, useRef, useState } from 'react'
import getAPIMap from '../../routes/url/ApiUrls'
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/path'
import { SuccessIcon, ErrorIcon } from '../../assets/svgs';
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';  
import CloseIcon from '@mui/icons-material/Close'; 

const EditPens = ({ penId, setSearchParams, setShowModal, setModalContext }) => {
    const axiosPrivate = useAxiosPrivate()
    const containerRef = useRef(null)
    const navigate = useNavigate()

    const closeModal = () => {
        setSearchParams({ action: "view" })
    }
    const handleSubmit = async () => {
        await axiosPrivate.delete(`${getAPIMap("penlist")}/${penId}`).then(res => {
            const { status, message } = res?.data || {}
            if (status === 200) {
                setShowModal(true)
                setModalContext({
                    text: "Pen details deleted successfully.",
                    title: "",
                    icon: <SuccessIcon />
                })
            } else if (message) {
                setShowModal(true);
                setModalContext({
                    text: message,
                    title: "",
                    isError: true,
                    icon: <ErrorIcon />
                })
            }
        }).catch(err => {
            if (err.status && err.message) {
                setShowModal(true);
                setModalContext({
                    text: err.message,
                    title: "",
                    isError: true,
                    icon: <ErrorIcon />
                })
            }
        })

    }
    const handleOverlayClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            navigate(PATHS.homePath); // Navigate back to the home route
        }
    };

    return (  
        <Dialog  
            open={handleOverlayClick} 
            onClose={closeModal}  
            sx={{  
                '& .MuiPaper-root': {  
                    borderRadius: '8px',  
                },  
            }}  
        >  
            <IconButton  
                onClick={closeModal}  
                size="small"  
                sx={{  
                    position: 'absolute',  
                    right: 8,  
                    top: 8,  
                    color: '#6E7E91',  
                    padding: '4px',  
                }}  
            >  
                <CloseIcon sx={{fontSize:"18px"}} />  
            </IconButton>  

            <DialogContent>  
                <Typography>  
                    <b className='text-[14px]'>Are you sure to delete this pen?</b>  
                    <br />  
                    <span style={{ marginTop: '8px', display: 'block',fontSize:"12px" }}>  
                        This action cannot be undone. This will delete the pen and remove it from the pen list.  
                    </span>  
                </Typography>  
            </DialogContent>  

            <DialogActions>  
                <Button  
                    onClick={closeModal}  
                    sx={{  
                        color: '#0F172A',  
                        border: '1px solid #D9D9D9',  
                        borderRadius: '8px',  
                        padding: '8px 16px',  
                        textTransform: 'none',  
                        fontSize:"14px",
                        fontWeight: 'bold',  
                        '&:hover': {  
                            backgroundColor: '#F5F5F5',  
                        },  
                    }}  
                >  
                    Cancel  
                </Button>  
                <Button  
                    onClick={handleSubmit}  
                    sx={{  
                        backgroundColor: '#F57C00',  
                        color: 'white',  
                        borderRadius: '8px',  
                        padding: '8px 16px',  
                        fontSize:"14px",
                        textTransform: 'none',  
                        fontWeight: 'bold',  
                        '&:hover': {  
                            backgroundColor: '#F57C00',  
                        },  
                    }}  
                >  
                    Continue  
                </Button>  
            </DialogActions>  
        </Dialog>  
    ); 
}

export default EditPens
