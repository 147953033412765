import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import getApiMap from "../routes/url/ApiUrls";
import axios from "axios";
import mem from "mem";
const useRefreshToken = () => {
  const { setAuth, auth, setSessionExpired } = useContext(AuthContext);

  const refresh = async () => {
    const storedRefreshToken = localStorage.getItem("refreshToken");

    if (!storedRefreshToken) {
      console.warn("No refresh token found. Session likely expired.");
      setSessionExpired(true);
     
      throw new Error("No refresh token available");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}${getApiMap("getAccessToken")}`,
        { refreshToken: storedRefreshToken },
        {
          headers: {
            Authorization: null, // Explicitly clear Authorization header
          },
        })

      const accessToken = response?.data?.token?.token;
      const refreshToken = response?.data?.refreshToken;
    
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("accessToken")
      if (!accessToken || !refreshToken) {

        throw new Error("Token refresh response invalid");
      }

      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      setAuth((prev) => ({
        ...prev,
        accessToken,
        refreshToken,
      }));

      return accessToken;
    } catch (err) {
      console.error("Error during token refresh:", err);
      setSessionExpired(true);
      throw err;
    }
  };

  return refresh;
};

export default useRefreshToken;
