import React, { forwardRef, useRef, useMemo, useCallback } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom"; 
import { Bar } from "react-chartjs-2";
import { externalTooltipHandler } from "../../features/batch-comparison/utils/batchComparisondeviation.utils";
import debounce from "lodash/debounce";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, annotationPlugin, zoomPlugin);

const BarChart = forwardRef(({
    data = { labels: [], datasets: [] },
    yAxisProp,
    yAxisTicksCallback,
    yAxisFormat,
    sethoveredPoint,
    tooltipAfterBodyCallback
}, ref) => {
    const tooltipRef = useRef(null);
    const defaultYAxisProp = useMemo(() => ({
        min: -10,
        max: 10,
        title: "",
    }), []);
    yAxisProp = yAxisProp || defaultYAxisProp;

    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: false,
                external: (context)=>externalTooltipHandler(data,context,tooltipRef,sethoveredPoint,yAxisFormat),
            },
            legend: {display:false, },
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        yMin: 0,
                        yMax: 0,
                        borderColor: "red",
                        borderWidth: 2,
                    },
                },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: true },
                    pinch: { enabled: true },
                    mode: "xy",
                },
                pan: { enabled: true, mode: "xy" },
                limits: {
                    x: { min: 0, max: data.labels.length - 1 },
                    y: { min: yAxisProp.min, max: yAxisProp.max },
                },
            },
        },
        scales: {
            y: {
              min: yAxisFormat.toLowerCase() === "exponential" ? yAxisProp.min.toExponential(2) : yAxisFormat.toLowerCase() === "real"?Number(yAxisProp.min.toFixed(2)):parseInt(Number(yAxisProp.min)),
                    max: yAxisFormat.toLowerCase() === "exponential" ? yAxisProp.max.toExponential(2) : yAxisFormat.toLowerCase() === "real"?Number(yAxisProp.max.toFixed(2)):parseInt(Number(yAxisProp.max)),
                      title: { display: true, text: yAxisProp.title },
                ticks: { callback: yAxisTicksCallback },
            },
            x: {
                stacked: true,
                title: { display: true, text: "Time" },
            },
        },
    };

    return (
        <div className="w-full border rounded-md border-gray-100 p-2 h-[60vh]  relative">
            <Bar ref={ref} data={data} options={options} />
            </div>
    );
});

export default React.memo(BarChart);
