import React, { useEffect, useState, useRef } from 'react';
import NoFiltersScreen from '../components/NoFiltersScreen/index';
import SearchandSelectBatch from '../components/SearchandSelectBatch';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import SidebarMenu from '../components/LineChart/Sidebar';
import CustomMultiLineChart from '../components/LineChart/index';
import html2canvas from 'html2canvas';
import DownloadOverlay from '../components/DownloadOverlay/index';
import { generateRandomDarkColor } from '../utils/color.utils.js'
import { DownloadIcon, ToggleSidebarIcon } from '../assets/svgs/index.jsx';
import Sidebar from '../components/BatchDetailsComponents/BatchDetailsSidebar.jsx';
import DownloadModal from '../components/BatchDetailsComponents/BatchDetailsDownload.jsx';
import { CircularProgress } from '@mui/material';
import HoverAndPenListComponent from '../components/BatchDetailsComponents/HoverAndPenListComponent.jsx';
const BatchDetails = () => {

    const axiosPrivate = useAxiosPrivate();
    const chartRef = useRef(null);
    const [hoveredPoint, sethoveredPoint] = useState(null)
    const [data, setData] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [batchLoader, setBatchLoader] = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions2, setSelectedOptions2] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [download, setDownload] = useState(false);
    const [graphLoader, setGraphLoader] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)


    const fetchBatchList = async () => {
        setBatchLoader(true)
        try {
            if (!dateRange) {
                return console.error("Invalid date range:", dateRange);
            }
            const [startDate, endDate] = dateRange;
            if (startDate === null || endDate === null || startDate === "Invalid Date" || endDate === "Invalid Date") {
                return console.error("Invalid date range:", dateRange);
            }
            setGroupData([]);
            const response = await axiosPrivate.get(`${getAPIMap("listBatches")}?startDateTime=${startDate}&endDateTime=${endDate}`);
            const batches = response?.data?.data?.map(batch => ({
                label: batch.id,
                value: batch.id,
                details: batch.idWithDate
            })) || [];
            setBatchList(batches);
        } catch (error) {
            console.error("Error fetching batch list:", error);
            setBatchList([]);
            setData([]);
            setBatchLoader(false)
            setGroupData([]);
        } finally {
            setBatchLoader(false)
        }
    };
    const handleDownload = () => {
        setDownload(false)
        downloadChart()
    }
    const resetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom()
        } else {
            console.error("Can't reset the zoom")
        }

    }

    const downloadChart = async () => {
        const element = document.getElementById('chart-container');
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);

        const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
        const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
        const d = new Date()
        const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
        const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
        const filename = `${selectedOptions[0]}-${dateDownload}-${timeDownload}.jpeg`;

        if (element) {
            const originalWidth = element.offsetWidth;
            const originalHeight = element.offsetHeight;

            const topPadding = 50;

            const originalCanvas = await html2canvas(element, {
                useCORS: true,
                backgroundColor: 'white',
                width: originalWidth,
                height: originalHeight,
                scale: 2,
            });


            const paddedCanvas = document.createElement('canvas');
            paddedCanvas.width = originalCanvas.width;
            paddedCanvas.height = originalCanvas.height + topPadding;

            const ctx = paddedCanvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height);
            ctx.drawImage(originalCanvas, 0, topPadding);
            const link = document.createElement('a');
            link.href = paddedCanvas.toDataURL('image/jpeg', 1.0);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownload(false);
        } else {
            console.error("Element to capture is not available.");
        }
    };

    const fetchGroupList = async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("groupByUserType"));
            const groups = response?.data?.map(group => ({
                label: group.name,
                value: group.id,
                penIds: group.penList
            })) || [];
            setGroupList(groups);
        } catch (error) {
            console.error("Error fetching group list:", error);
        }
    };

    useEffect(() => {
        fetchGroupList();
        fetchBatchList();
    }, [dateRange]);

    const fetchBatchDetailsReport = async () => {
        try {
            const element = document.getElementById('chart-container');
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);

            const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
            const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
            const d = new Date()
            const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
            const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
            const filename = `${selectedOptions[0]}-${dateDownload}-${timeDownload}.pdf`;
            const now = new Date()
            const day = String(now.getDate()).padStart(2, '0'); // Add leading zero if needed
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = now.getFullYear();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const formattedDateTime = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;

            if (element) {
                const originalWidth = element.offsetWidth;
                const originalHeight = element.offsetHeight;

                const topPadding = 50;

                const originalCanvas = await html2canvas(element, {
                    useCORS: true,
                    backgroundColor: 'white',
                    width: originalWidth,
                    height: originalHeight,
                    scale: 2,
                });
                const paddedCanvas = document.createElement('canvas');
                paddedCanvas.width = originalCanvas.width;
                paddedCanvas.height = originalCanvas.height + topPadding;

                const ctx = paddedCanvas.getContext('2d');
                ctx.fillStyle = 'white'; // Set background color
                ctx.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height); // Fill the padded canvas
                ctx.drawImage(originalCanvas, 0, topPadding); // Draw the original canvas with padding

                const dataURL = paddedCanvas.toDataURL('image/jpeg', 1.0);

                const blob = await (await fetch(dataURL)).blob();

                const formData = new FormData();
                formData.append('image', blob, 'Batch details.jpeg');
                const penIds = selectedOptions2.flatMap(groupValue => {
                    const matchingGroup = groupList.find(group => group.value === groupValue);
                    return matchingGroup
                        ? matchingGroup.penIds
                            .filter(pen => pen.pen_id !== null)
                            .map(pen => ({ id: pen.pen_id, format: pen.selectedFormat }))
                        : [];
                });
                const penFormats = data.filter(item => selectedOptions2.includes(item.penId));

                const penDetails = data.map(item => {
                    return {
                        id: item.penId,
                        format: item.selectedFormat === "logarithmic" ? "exponential" : item.selectedFormat.toLowerCase()
                    };
                    return null;
                }).filter(item => item !== null);

                penDetails.forEach(({ id, format }) => {
                    formData.append("penIds[]", JSON.stringify({ id, format })); // Append the object as a JSON string
                });

                formData.append("startDateTime", dateRange[0]);
                formData.append("endDateTime", dateRange[1]);
                formData.append("groupId", selectedOptions2[0]);
                formData.append("batchNumber", selectedOptions[0]);
                formData.append("downloadDateTime", formattedDateTime);

                const response = await axiosPrivate.post(getAPIMap("downloadBatchDetails"), formData, {
                    responseType: 'blob' // Important to handle the response as a blob
                });

                if (response.status === 200) {
                    // Create a URL for the PDF blob
                    const pdfBlob = response.data;
                    const pdfURL = window.URL.createObjectURL(pdfBlob);

                    // Create an anchor element to trigger the download
                    const link = document.createElement('a');
                    link.href = pdfURL;
                    link.download = filename; // Set the filename for download
                    document.body.appendChild(link); // Append to DOM (required for some browsers)
                    link.click(); // Programmatically click the link to trigger the download
                    document.body.removeChild(link); // Remove the link after downloading
                } else {
                    console.error('Error uploading chart image:', response.statusText);
                }
            } else {
                console.error("Element to capture is not available.");
            }
        } catch (error) {
            console.error('Error capturing or uploading the chart image:', error);
        }
    };
    const [chartState, setChartState] = useState(null);

    const saveChartState = () => {
        const state = chartRef.current?.getCurrentState(); // Example for a chart library
        setChartState(state);
    };

    const restoreChartState = () => {
        if (chartState) {
            chartRef.current?.setState(chartState); // Example for a chart library
        }
    };

    useEffect(() => {
        restoreChartState();
    }, [sidebarOpen]);

    const handleReportDownload = () => {
        setDownload(false)
        fetchBatchDetailsReport()
    }

    const handleFetchData = async () => {
        
        try {
            const penId = selectedOptions2.flatMap(groupValue => {
                const matchingGroup = groupList.find(group => group.value === groupValue);
                return matchingGroup
                    ? matchingGroup.penIds.filter(pen => pen.pen_id !== null).map(pen => pen.pen_id)
                    : [];
            });

            const requestBody = {
                penIds: penId,
                groupId: selectedOptions2[0],
                startDateTime: dateRange[0],
                endDateTime: dateRange[1],
                batchNumber: selectedOptions[0]
            };
            setGraphLoader(true)
        sethoveredPoint(null)
            const response = await axiosPrivate.post(getAPIMap("getBatchDetails"), requestBody);
            generateRandomDarkColor.resetUsedColors();

            const fetchedData = response?.data?.data || [];

            const formattedData = fetchedData.map((pen) => {
                // Convert the keys to lowercase and filter out 'date_time' (case-insensitive comparison)
                const resultKeys = pen.penResult.length > 0
                    ? Object.keys(pen.penResult[0]).filter(key => key.toLowerCase() !== 'date_time')
                    : [];

                const axisLabelKey = resultKeys[0] || null;

                // Extract and convert the values into numbers
                const values = pen.penResult.map(result => {
                    const value = result[axisLabelKey];
                    return Number(value);  // Convert to number
                });

                // Filter out NaN values (if any), and replace them with 0
                const numericValues = values.map(value => (isNaN(value) ? 0 : value));

                // Calculate the min and max values for the numeric values
                const minValue = Math.round(Math.min(...numericValues) * 10000) / 10000;
                const maxValue = Math.round(Math.max(...numericValues) * 10000) / 10000;

                return {
                    penId: pen.penId,
                    penName: pen.penName,
                    penResult: pen.penResult,
                    axisLabel: axisLabelKey,
                    minValue: minValue,
                    maxValue: maxValue,
                    selectedFormat: "Integer",  // Assuming this is a static value
                    fillColor: generateRandomDarkColor.getRandomColor(),
                    lineWidth: 1,  // Assuming a static value
                    logScaleVisible: false,  // Assuming a static value
                    displayArea: "left",  // Assuming a static value
                };
            });



            const updatedFormattedData = formattedData.map(pen => {
                const matchingGroupDetail = response?.data?.group?.groupPenDetails?.find(detail => detail.pen_id === pen.penId);
                return {
                    ...pen,
                    isVisible: matchingGroupDetail ? matchingGroupDetail.isDefault : false,
                    scaleVisible: matchingGroupDetail ? matchingGroupDetail.isDefault : false,
                };
            });
            const penListModified = groupList.filter((group) => group.value === selectedOptions2[0]);
            setData(updatedFormattedData);
            setGroupData(penListModified[0]?.penIds || []);
        } catch (error) {
            console.error("Error fetching data:", error);
            setData([]);
            setGraphLoader(false)
            if (error?.response?.data?.error) alert(error?.response?.data?.error);
        } finally {
            setGraphLoader(false)
        }
    };

    const handleChangeColor = (index, color) => {
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, fillColor: color } : item
            )
        );
    };

    const handleToggleDataset = (index) => {

        setData((prevData) => {
            return prevData.map((item, idx) =>
                idx === index
                    ? { ...item, isVisible: !item.isVisible, scaleVisible: false }
                    : item
            )
        }
        );
    };

    const handleChangeMinMax = (index, type, value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return; // Ignore if value is not a number
        }
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index
                    ? {
                        ...item,
                        [type === "min" ? "minValue" : "maxValue"]: parsedValue,
                    }
                    : item
            )
        );
    };

    const handleChangeLineWidth = (index, value) => {
        let parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue)) {
            // return; // Ignore if value is not a number
            parsedValue = 1;
        }

        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, lineWidth: parsedValue } : item
            )
        );
    };

    const handleChangeYAxisDirection = (newDirection, index) => {
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, displayArea: newDirection } : item
            )
        );
    };

    const handleChangeFormat = (format, index) => {
        setData((prevData) =>
            prevData.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        selectedFormat: format === "logarithmic" ? "exponential" : format,
                        logScaleVisible: format === 'logarithmic',
                    };
                }
                return item;
            })
        );
    };

    const handleToggleLogScale = (index, isChecked) => {
        // setData((prevData) =>
        //     prevData.map((item, idx) =>
        //         idx === index
        //             ? { ...item, logScaleVisible: isChecked }
        //             : item
        //     )
        // );
    };

    const handleToggleYAxisVisibility = (index, isVisible) => {
        setData((prevData) => {
            const updatedData = prevData.map((item, idx) => {
                if (idx === index) {
                    return { ...item, scaleVisible: isVisible };
                }
                return item;
            });

            const visibleIndices = updatedData
                .map((item, idx) => (item.scaleVisible ? idx : -1))
                .filter((idx) => idx !== -1);
            if (visibleIndices.length > 3 && isVisible) {

                const firstVisibleIndex = visibleIndices[0];
                updatedData[firstVisibleIndex] = {
                    ...updatedData[firstVisibleIndex],
                    scaleVisible: false,
                };
            }


            const threeYAxisVisible = updatedData.filter(item => item.scaleVisible).length === 3;
            if (threeYAxisVisible) {
                const firstRightIndex = updatedData.findIndex(item => item.scaleVisible && item.displayArea === "right");
                if (firstRightIndex === -1) {
                    const firstVisibleIndex = updatedData.findIndex(item => item.scaleVisible);
                    if (firstVisibleIndex !== -1) {
                        updatedData[firstVisibleIndex].displayArea = 'right';
                    }
                }
            }

            return updatedData; // Return the updated dataset
        });
    };


    return (
        <div className="bg-white w-full rounded-r-md rounded-b-md rounded-l-none relative">
                 <div >

                 <div className={`w-full`}>
                 <div
                        className={`  flex-col  items-center`}
                    >
                        <div className=' pt-1 px-4  w-full' >
                             <SearchandSelectBatch
                                dateRange={dateRange}
                                groupList={groupList}
                                batchLoader={batchLoader}
                                setDateRange={setDateRange}
                                batchList={batchList}
                                groupData={groupData}
                                setGroupData={setGroupData}
                                handleToggleDataset={handleToggleDataset}
                                setData={setData}
                                data={data}
                                handleFetchData={handleFetchData}
                                selectedOptions={selectedOptions}
                                selectedOptions2={selectedOptions2}
                                setSelectedOptions={setSelectedOptions}
                                setSelectedOptions2={setSelectedOptions2}
                            />

                        </div>
                    </div>
                    <div id='chart-container'>

                        {data.length > 0 && <div className="flex gap-x-2 pt-2 !z-20 pl-2 bg-bgBatchDetailsFilter mx-4  rounded-b-md pl-6 pb-4">
                            {data.length > 0 ? (
                                <HoverAndPenListComponent handleToggleDataset={handleToggleDataset} hoveredPoint={hoveredPoint} data={data}/>
                            ) : null}
                        </div>}

                        <div className="flex flex-row w-full h-full ">
                            {graphLoader ? <div className='h-[65vh] flex justify-center items-center w-full bg-gray-50 bg-opacity-10'> <CircularProgress
                                size={40}
                                sx={{
                                    color: "#EB7E39",
                                }}

                            /></div> : data.length === 0 ? (
                               <div className='h-[72vh] w-full flex justify-center items-center'> <NoFiltersScreen label={'Add filters to generate graph!'} /></div>
                            ) : <div className="flex flex-col w-full h-full p-4 pb-10">
                                <div className="flex w-full justify-between items-center gap-x-4 ">
                                    <h2 className="text-[12px]/[28px] font-semibold text-textBoldColor pl-4">Batch Details</h2>
                                    {!sidebarOpen && <div
                                        className="flex justify-end pr-4 gap-x-4 z-20"
                                        onClick={() => {
                                            setSidebarOpen((prev) => !prev);
                                        }}
                                    >

                                        <div className={`${sidebarOpen ? "rotate-180" : ""}`}>
                                            <ToggleSidebarIcon />
                                        </div>
                                    </div>}
                                </div>

                                <div className="flex flex-col mt-4">

                                    <div
                                        className="chart-container mx-auto pr-8 border border-gray-100 rounded-md mb-4"
                                        style={{
                                            width: "100%",
                                            height: "400px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <CustomMultiLineChart data={data} sethoveredPoint={sethoveredPoint} ref={chartRef} />

                                    </div>
                                </div>
                            </div>

                            }
                        </div>
                    </div>
                </div>
                {/* {sidebarOpen && <div className="sidebar-container absolute right-0  top-28 mt-6 w-[22%] h-[70vh] !z-40">

                    <SidebarMenu
                        data={data}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        handleToggleDataset={handleToggleDataset}
                        handleToggleLogScale={handleToggleLogScale}
                        downloadChart={() => setDownload(true)}
                        handleChangeColor={handleChangeColor}
                        handleChangeMinMax={handleChangeMinMax}
                        handleChangeLineWidth={handleChangeLineWidth}
                        handleToggleYAxisVisibility={handleToggleYAxisVisibility}
                        handleChangeFormat={handleChangeFormat}
                        handleChangeYAxisDirection={handleChangeYAxisDirection}
                    />
                </div>}
                 */}
                {sidebarOpen && (
                    <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        data={data}
                        handleToggleDataset={handleToggleDataset}
                        handleToggleLogScale={handleToggleLogScale}
                        downloadChart={() => setDownload(true)}
                        handleChangeColor={handleChangeColor}
                        handleChangeMinMax={handleChangeMinMax}
                        handleChangeLineWidth={handleChangeLineWidth}
                        resetZoom={resetZoom}
                        handleToggleYAxisVisibility={handleToggleYAxisVisibility}
                        handleChangeFormat={handleChangeFormat}
                        handleChangeYAxisDirection={handleChangeYAxisDirection}
                    />
                )}

                {download && (
                    <DownloadOverlay
                        downloadChart={handleDownload}
                        showPdf={true}
                        handleCancel={() => {
                            setDownload(false);
                        }}
                        handleReportDownload={handleReportDownload}
                    />
                )}
            </div>
        </div >

    );
};

export default BatchDetails;