export const calculateMinMaxValues = (batchData) => {
    const minMax = { min: Infinity, max: -Infinity };
    batchData.forEach((batch) => {
        if (batch.batchResult && Array.isArray(batch.batchResult)) {
            batch.batchResult.forEach((result) => {
                Object.values(result).forEach((value) => {
                    if (typeof value === 'number') {
                        minMax.min = Math.min(minMax.min, value);
                        minMax.max = Math.max(minMax.max, value);
                    }
                });
            });
        }
    });

    return {
        min: minMax.min === Infinity ? null : minMax.min,
        max: minMax.max === -Infinity ? null : minMax.max,
    };
}


let previousHoveredPoint = null;

export const getDataAtHoveredPoint = (() => {
    let previousHoveredPoint = null; 
    return (hoveredPoint, data) => {
        const { x, y } = hoveredPoint;
        const parsedX = parseInt(x, 10);
        let results = []; 

        if (previousHoveredPoint && 
            (previousHoveredPoint.x !== parsedX || previousHoveredPoint.y !== y)) {
        }

        previousHoveredPoint = { x: parsedX, y };

        data.forEach(batch => {
            batch.batchResult.forEach(result => {
                const valueKey = Object.keys(result).find(
                    key => key !== "DATE_TIME" && key !== "CHARGE_NUMBER" && key !== "minutesPassed"
                );

                if (result.minutesPassed === parsedX && valueKey) {
                    results.push({
                        batchNumber: batch.batchNumber,
                        VALUE_POINT: result[valueKey], 
                        DATE_TIME: result.DATE_TIME,
                        CHARGE_NUMBER: result.CHARGE_NUMBER
                    });
                }
            });
        });

        return results; 
    };
})();

export const rgbToHex = (rgb) => {
    const result = rgb.match(/\d+/g); // Extract the numeric values
    return result 
        ? `#${result.map(x => {
            const hex = (+x).toString(16).padStart(2, '0'); // Convert to hex and ensure it's two digits
            return hex;
        }).join('')}` 
        : null;
};