import DownloadOverlay from '../DownloadOverlay/index'


function DownloadModal({ download, setDownload, handleDownload, handleReportDownload }) {
    return (
        download && (
            <DownloadOverlay
                downloadChart={handleDownload}
                showPdf={true}
                handleCancel={() => setDownload(false)}
                handleReportDownload={handleReportDownload}
            />
        )
    );
}

export default DownloadModal