import { debounce } from "lodash";


const formatValue = (val, yAxisFormat) => {
    if (yAxisFormat === "integer") {
        return Math.trunc(Number(val)); 
    } else if (yAxisFormat === "real") {
        return Math.trunc(Number(val) * 100) / 100;
    } else if (yAxisFormat === "exponential") {
        return parseFloat(val).toExponential().replace(/(\.\d{2})\d+e/, '$1e'); 
    }
    return val; 
};

export const externalTooltipHandler = debounce(
    (data, context, tooltipRef, sethoveredPoint, yAxisFormat) => {
        const { chart, tooltip } = context;
        if (!tooltip || !tooltip.dataPoints) return;

        const getDifferencesByMinutes = (data, targetMinutes) => {
            return data.map((batch) => {
                
                const matchedResults = batch.batchResult.filter(
                    (result) =>
                        targetMinutes.includes(result.minutesPassed) &&
                        result.difference !== undefined &&
                        result.difference !== null
                );

                return {
                    batchNumber: batch.batchNumber,
                    differences: matchedResults.map(({ minutesPassed, difference }) => ({
                        minutesPassed,
                        difference: formatValue(difference, yAxisFormat), 
                    })),
                };
            });
        };

        const targetMinutes = tooltip.dataPoints.map((dataPoint) =>
            parseInt(dataPoint.label, 10)
        );

        const differences = getDifferencesByMinutes(data.datasets, targetMinutes);

        const tooltipData = {
            title: tooltip.dataPoints[0]?.label || "",
            body: differences
                .filter((batch) => batch.differences.length > 0) // Only include batches with valid differences
                .map((batch) => {
                    return `${batch.batchNumber}: ${batch.differences
                        .map(({ difference }) => difference)
                        .join(", ")}`;
                }),
        };

        sethoveredPoint?.(tooltipData);
    },
    100 // Debounce delay
);
