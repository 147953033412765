import React, { useEffect, useState } from 'react';
import DateRangePickerComponent from '../DateSelecter'; // Adjust the import path as needed
import SearchableSelect from '../SearchableSelect'; // Adjust the import path as needed

const BatchFilter = ({
    setData,
    dummyData,
    dateRange,
    batchList,
    colors,
    data,
    groupList,
    batchLoader,
    handleFetchData,
    penColorMap,
    setPenColorMap,
    setDateRange,
    multiSelect,
    groupData,
    setGroupData,
    handleToggleDataset,
    visibleDatasets,
    setVisibleDatasets,
    selectedOptions,
    selectedOptions2,
    setSelectedOptions,
    setSelectedOptions2,
}) => {
    const handleOptionChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
    };

    const handleOptionChange2 = (newSelectedOptions) => {
        setSelectedOptions2(newSelectedOptions);
    };

    useEffect(() => {

    }, [data])

    return (
        <div className="px-2 mt-2 bg-bgBatchDetailsFilter">

<div className="w-full h-14 mx-4  rounded-t-md  pl-2  ml-2 flex gap-x-1 justify-start items-center">
<DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />

                <div className="max-w-4/12">
                    <SearchableSelect
                        options={batchList}
                        showLabel={false}
                        showSearch={true}
                        batchLoader={batchLoader}
                        singleSelect={false}
                        multiSelect={multiSelect}
                        showAddOption={true}
                        showParameter={false}
                        showDetails={true}
                        placeholder="Select batch from the drop down"
                        selectedOptions={selectedOptions}
                        onChange={handleOptionChange}
                    />
                </div>

                <div className="max-w-4/12">
                    <SearchableSelect
                        options={groupList}
                        showLabel={false}
                        showSearch={true}
                        multiSelect={multiSelect}
                        singleSelect={false}
                        showParameter={true}
                        showAddOption={true}

                        placeholder="Select group from the drop down"
                        selectedOptions={selectedOptions2}
                        onChange={handleOptionChange2}
                    />
                </div>

                <div
                    className="w-3/12 mr-2 text-[12px] rounded-md bg-baseOrange h-8 text-white flex justify-center items-center cursor-pointer"
                    onClick={handleFetchData}
                >
                    Apply Filters
                </div>
            </div>



        </div>

    );
};

export default BatchFilter;
