import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../routes/path'
import PasswordInput from "../Password/Password";
function LoginForm() {
  const { auth, login } = useContext(AuthContext)
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .required("Username is required")
          .matches(
            /^[A-Za-z0-9 ]*$/,
            "Special characters not allowed"
          )
          .max(75, "Maximum length allowed is 75")
          .min(1, "Minimum length allowed is 1")
          .test(
            "no-starting-space",
            "Username cannot start with a space",
            (value) => value && !value.startsWith(" ")
          ),
        password: Yup.string()
          .required("Password is required"),
      })
      }
      onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
        setSubmitting(true);
        setStatus();
        login(username, password)
          .then((res) => {
            if (res?.status === 200) {
              navigate(PATHS.homePath);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              setStatus(error.response.data.message);
            } else {
              setStatus("Invalid username or password. Please try again.");
            }
            setSubmitting(false);
            setTimeout(() => setStatus(null), 8000);
          });

      }}
    >
      {({ isSubmitting, status, errors, touched }) => (
        <Form>
          <div className="mb-[6px]">
            <label
              htmlFor="username"
              className="block text-[14px]/[20px] text-[#191919] font-medium text-textHeading"
            >
              Username
            </label>

            <Field
              className={`form-input mt-[10px] mb-[8px] block w-full h-10 text-[12px]/[24px] ${errors.username && touched.username ? "border-baseOrange" : "border-gray-300"} h-[44px] bg-white rounded-md border  shadow-sm pl-2 focus:outline-baseOrange`}
              as="input"
              name="username"
              type="text"
              placeholder=" Enter your username"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-600 text-sm mb-[32px]"
            />
          </div>

          <div className="mb-[10px] mt-[24px]">
            <label
              htmlFor="password"
              className="block text-[14px]/[20px] text-[#191919] font-medium text-gray-700"
            >
              Password
            </label>
            <Field
              className={`form-input mt-[10px] mb-[8px] block w-full h-10 rounded-md text-[12px]/[24px] border bg-white ${errors.password && touched.password ? "border-baseOrange" : "border-gray-300"} shadow-sm pl-2 focus:outline-baseOrange`}

              name="password"
              component={PasswordInput}
              placeholder=" Enter your password"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-600 text-sm mt-[8px]"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 mt-6 h-10 bg-orange-500 text-white rounded-md transition duration-200 focus:outline-none"
            disabled={isSubmitting}
          >
            Login
          </button>

          {status && (
            <div className="mt-2 text-red-600 text-sm p-2">
              {status}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
