import React, { useState, useEffect, useMemo, forwardRef, useRef, useImperativeHandle } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart,
    LinearScale,
    CategoryScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    TimeScale,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import dayjs from 'dayjs';
import { externalTooltipHandler } from './CustomToolTip';



Chart.register(
    LinearScale,
    CategoryScale,
    PointElement,
    LogarithmicScale,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    TimeScale,
    zoomPlugin
);
Chart.defaults.elements.line.tension = 0; // Ensure global default is set

const intersectDataVerticalLine = {
    id: 'intersectDataVerticalLine',
    beforeDraw: (chart) => {
        const activeElements = chart.getActiveElements();
        const chartArea = chart.chartArea;

        if (activeElements.length && chartArea) {
            const activePoint = activeElements[0];
            const { x } = activePoint.element;

            if (x >= chartArea.left && x <= chartArea.right) {
                const ctx = chart.ctx;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, chartArea.top);
                ctx.lineTo(x, chartArea.bottom);
                ctx.lineWidth = 2;
                ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
                ctx.stroke();
                ctx.restore();
            }
        }
    },
};

Chart.register(intersectDataVerticalLine);


const valueOnLinePlugin = {
    id: 'valueOnLine',
    afterDatasetsDraw: (chart) => {
        const { ctx, tooltip } = chart;

        if (tooltip && tooltip.active.length) {
            const activePoint = tooltip.active[0];
            const datasetIndex = activePoint.datasetIndex;
            const datasets = chart.data.datasets;

            ctx.save();
            const dataPoint = datasets[datasetIndex].data[activePoint.index];
            ctx.fillStyle = datasets[datasetIndex].borderColor;
            ctx.font = '12px Arial';
            ctx.fillText(dataPoint.y.toFixed(2), activePoint.element.x + 5, activePoint.element.y - 10);
            ctx.restore();
        }
    },
};

Chart.register(valueOnLinePlugin);

const formatYValue = (value, format) => {
    switch (format) {
        case 'integer':
            return parseInt(value);
        case 'decimal':
            return parseInt(value);
        case 'real':
            return Math.floor(Number(value) * 100) / 100;
        case 'logarithmic':
            return parseFloat(value).toExponential().replace(/(\.\d{2})\d+e/, '$1e');
        default:
            return value;
    }
};



const CustomMultiLineChart = forwardRef(({ data, sethoveredPoint }, ref) => {
    const chartRef = useRef(null);
    const hover = useRef(null);
    const [zoomState, setZoomState] = useState(null);

    const tooltipRef = useRef(null);
    const datasets = useMemo(() => {
        return data.map((pen, index) => {
            if (pen.isVisible) {
                const values = pen.penResult.map(result => {
                    const value = result[pen.axisLabel];
                    return Number(value);
                });

                const numericValues = values.map(value => (isNaN(value) ? 0 : value));

                const timestamps = pen.penResult
                    .filter((_, idx) => values[idx] !== null && !isNaN(values[idx]))
                    .map(result => {
                        const dateTimeKey = Object.keys(result).find(key => key.toLowerCase() === 'date_time');
                        if (dateTimeKey && result[dateTimeKey]) {
                            const parsedDate = dayjs(result[dateTimeKey]);
                            if (parsedDate.isValid()) {
                                return parsedDate.toISOString();
                            }
                        }
                        return null;
                    }).filter(Boolean);

                const dataPoints = numericValues.map((y, idx) => ({
                    x: timestamps[idx],
                    y: formatYValue(Math.max(y, 0), pen.selectedFormat),
                }));

                return {
                    label: pen.penName,
                    data: dataPoints,
                    backgroundColor: pen.fillColor,
                    borderWidth: pen.lineWidth,
                    borderColor: pen.fillColor,
                    fill: false,
                    min: pen.minValue,
                    max: pen.maxValue,
                    tension: 0,
                    lineTension: 0,
                    scaleVisible: pen.scaleVisible,
                    logScaleVisible: pen.logScaleVisible,
                    displayArea: pen.displayArea,
                    selectedFormat: pen.selectedFormat,
                    yAxisID: pen.scaleVisible ? `y-axis-${index}` : undefined,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    cubicInterpolationMode: 'linear'
                };
            }
            return null;
        }).filter(Boolean)
    }, [data]);
    function formatYaxisMinMax(dataset) {
        const adjustmentMin = 0;
        const adjustmentMax = 0;

        switch (dataset?.selectedFormat) {
            case "integer": {
                const min = parseInt((dataset.min || 0) - adjustmentMin);
                const max = parseInt(dataset.max + adjustmentMax);
                return { min, max };
            }
            case "real": {
                const min = parseFloat((dataset.min || 0) - adjustmentMin).toFixed(2);
                const max = parseFloat(dataset.max + adjustmentMax).toFixed(2);
                return { min, max };
            }
            default: {
                const min = parseFloat((dataset.min || 0) - adjustmentMin).toFixed(2);
                const max = parseFloat(dataset.max + adjustmentMax).toFixed(2);
                return { min, max };
            }
        }
    }

    const truncateToTwoDecimals = (value) => {
        if (typeof value === 'number') {
            return Math.trunc(value * 100) / 100;
        }
        return value; // Return as-is if not a number
    };

    const createYAxisConfig = (datasets) => {
        const yAxisConfigMap = {};
        let leftCount = 0; // Counter for left-side y-axes
        let rightCount = 0; // Counter for right-side y-axes

        datasets.forEach(dataset => {
            if (!yAxisConfigMap[dataset.yAxisID]) {
                const { min, max } = formatYaxisMinMax(dataset);

                const position = dataset.displayArea === 'right' ? 'right' : 'left';
                const numericMin = truncateToTwoDecimals(Number(min));
                const numericMax = truncateToTwoDecimals(Number(max));

                if (dataset.scaleVisible) {
                    if (position === 'left') leftCount++;
                    else rightCount++;
                }

                // Handle the case when min === max
                let adjustedMin = numericMin;
                let adjustedMax = numericMax;

                if (min === max) {
                    // Adjust for logarithmic scale
                    if (dataset.selectedFormat === "logarithmic") {
                        adjustedMin = truncateToTwoDecimals(Math.max(0.0001, numericMin)); // Ensure positive value for log scale
                        adjustedMax = truncateToTwoDecimals(adjustedMin * 2);
                    } else {

                        adjustedMin = truncateToTwoDecimals(numericMin - 10);
                        adjustedMax = truncateToTwoDecimals(numericMax + 10);
                    }
                }

                yAxisConfigMap[dataset.yAxisID] = {
                    id: dataset.yAxisID,
                    type: 'linear', // Or 'logarithmic' if needed
                    position: position,
                    offset: dataset.scaleVisible, // Prevent layout offset if hidden
                    display: dataset.scaleVisible, // Show/hide the axis
                    title: {
                        display: dataset.scaleVisible || dataset.isVisible,
                        text: dataset.label,
                        color: dataset.scaleVisible ? dataset?.backgroundColor : 'rgba(0, 0, 0, 0)', // Transparent when hidden
                        font: { size: 14 },
                        padding: 5,
                    },
                    ticks: {
                        color: dataset.scaleVisible ? dataset?.backgroundColor : 'rgba(0, 0, 0, 0)', // Transparent ticks when hidden
                        font: { size: 12 },
                        maxTicksLimit: 5,
                        padding: 1,
                        callback: function (value) {
                            if (typeof value === 'number') {
                                if (
                                    dataset.logScaleVisible &&
                                    (dataset.selectedFormat.toLowerCase() === "exponential" || dataset.selectedFormat.toLowerCase() === "logarithmic")
                                ) {
                                    return  parseFloat(value).toExponential().replace(/(\.\d{2})\d+e/, '$1e') ; // Exponential format for log scale
                                } else if (dataset.selectedFormat.toLowerCase() === "real") {
                                    return value.toFixed(2); // Truncate to 2 decimals
                                }else{
                                    return  Math.trunc(Number(value)); // Truncate to 2 decimals
                                }
                            }
                            return value;
                        },
                    },
                    grid: {
                        color: dataset.scaleVisible ? 'black' : 'rgba(0, 0, 0, 0)', // Transparent grid lines when hidden
                        drawBorder: dataset.scaleVisible, // Show/hide axis border
                        display: dataset.scaleVisible, // Show/hide grid lines
                        tickMarkLength: dataset.scaleVisible ? 5 : 0,
                    },
                    min: dataset.selectedFormat === "logarithmic"
                        ? adjustedMin
                        : truncateToTwoDecimals(adjustedMin), // Truncate min for linear scale
                    max: dataset.selectedFormat === "logarithmic"
                        ? adjustedMax
                        : truncateToTwoDecimals(adjustedMax), // Truncate max for linear scale
                };
            }
        });

        // Adjust spacing for left and right axes
        const leftSpacing = leftCount > 0 ? 1 / leftCount : 1; // Fractional spacing for left y-axes
        const rightSpacing = rightCount > 0 ? 1 / rightCount : 1; // Fractional spacing for right y-axes

        // Apply spacing to visible axes
        Object.values(yAxisConfigMap).forEach(axisConfig => {
            if (axisConfig.display) {
                if (axisConfig.position === 'left') {
                    axisConfig.weight = leftSpacing; // Adjust weight for left axes
                } else if (axisConfig.position === 'right') {
                    axisConfig.weight = rightSpacing; // Adjust weight for right axes
                }
            }
        });

        return Object.values(yAxisConfigMap);
    };




    const allTimestamps = data
        .flatMap(dataset => {

            const timestamps = dataset.penResult.map(result => {
                const lowerCaseResult = Object.keys(result).reduce((acc, key) => {
                    acc[key.toLowerCase()] = result[key];
                    return acc;
                }, {});

                const timestamp = lowerCaseResult['date_time'];
                return timestamp;
            });
            return timestamps;
        })
        .filter(timestamp => {
            const isValid = timestamp !== null && !isNaN(new Date(timestamp).getTime());
            return isValid;
        });

    const allTimestampsMillis = allTimestamps.map(timestamp => {
        const parsedTimestamp = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss");
        return parsedTimestamp.isValid() ? parsedTimestamp.valueOf() : null;
    }).filter(timestamp => timestamp !== null);

    let minTimestamp = null;
    let maxTimestamp = null;


    if (allTimestampsMillis.length > 0) {
        minTimestamp = Math.min(...allTimestampsMillis);
        maxTimestamp = Math.max(...allTimestampsMillis);
        minTimestamp = Math.min(...allTimestampsMillis);
        maxTimestamp = Math.max(...allTimestampsMillis);

        minTimestamp = dayjs(minTimestamp).subtract(1, 'hour').valueOf();
        maxTimestamp = dayjs(maxTimestamp).add(1, 'hour').valueOf();
        minTimestamp = dayjs(minTimestamp).subtract(1, 'hour').valueOf();
        maxTimestamp = dayjs(maxTimestamp).add(1, 'hour').valueOf();
    } else {
        console.error("No valid timestamps found!");
    }


    const timeRange = maxTimestamp - minTimestamp;

    let stepSize = 1;
    if (timeRange > 1000 * 60 * 60 * 24 * 30) {
        stepSize = 7;
    } else if (timeRange > 1000 * 60 * 60 * 24 * 7) {
        stepSize = 1;
    } else if (timeRange > 1000 * 60 * 60 * 24) {
        stepSize = 1;
    } else {
        stepSize = 1;
    }

    const yAxisConfig = createYAxisConfig(datasets);
    const chartOptions = {
        responsive: true,
        type: "line",
        bezierCurve: false,
        indexAxis: "y",
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {

                    tooltipFormat: 'dd/MM HH:mm',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 24,
                    unitStepSize: stepSize,
                    min: minTimestamp,
                    max: maxTimestamp,
                    callback: function (value) {
                        return dayjs(value).format('D/MM HH:mm');
                    },
                },
                title: {
                    display: true,
                    text: 'Date and Time',
                    color: 'black',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    padding: { top: 10 },
                },
            },


            ...yAxisConfig.reduce((acc, axisConfig) => {
                acc[axisConfig.id] = axisConfig;
                return acc;
            }, {}),
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: false,
                external: (context) => externalTooltipHandler(context, tooltipRef,sethoveredPoint),
                callbacks: {
                    afterLabel: (context,tooltipRef) => {
                        const xValue = context.dataset.label;
                        const yValue = context.raw;

                        const { chart, tooltip } = context;



                        sethoveredPoint({ x: xValue, y: yValue });
                        hover.current = { x: xValue, y: yValue };
                        return "";
                    },
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',

                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'xy',


                },
            },
            intersectDataVerticalLine,
            valueOnLinePlugin,
        },

    };
    useImperativeHandle(ref, () => ({
        getChart: () => {
            return chartRef.current;
        },
        download: () => {
            const canvas = chartRef.current.canvas;
            canvas.style.backgroundColor = "white";
            const dataURL = canvas.toDataURL("image/jpeg", 1.0);
            const link = document.createElement("a");
            link.href = dataURL;
            link.download = "batchcomparisonchart.jpeg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        resetZoom: () => {

            const chartInstance = chartRef.current;
            if (chartInstance) {
                chartInstance.resetZoom(); // Simply reset zoom
                // setIsZoomedIn(false);
            }
        },
    }));

    return (
        <div className=' w-full h-full pb-4' >

            <Line
                ref={chartRef}
                data={{ datasets }}
                options={chartOptions}
            />
            <div
                ref={tooltipRef}
                style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    opacity: 0,
                }}
            />

        </div >
    );
});

export default React.memo(CustomMultiLineChart);