import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import MUI eye icons
import { EyeClosedIcon, EyeOpenIcon, IIcon } from '../../assets/svgs';

const PasswordInput = ({ field, form, name, ...props }) => {
  const { errors, touched } = form;
  const errorMessage = errors[name] && touched[name];

  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to toggle visibility

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev); // Toggle the password visibility
  };

  return (
    <div className="relative">
      <input
        {...field}
        {...props}
        className={`form-input mt-[10px] mb-[8px] block w-full h-10 rounded-md border text-[12px]/[24px] bg-white ${errorMessage ? 'border-baseOrange' : 'border-gray-300'} shadow-sm pl-2 pr-10 focus:outline-baseOrange`}
        type="text" 
        placeholder="Enter your password"
        style={{
          WebkitTextSecurity: isPasswordVisible ? 'none' : 'disc', // Use a mask when password is not visible
        }}
      />

      <div
        onClick={handleTogglePasswordVisibility}
        className="absolute !z-50 right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
      >
        {isPasswordVisible ? (
          <EyeOpenIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <EyeClosedIcon className="h-5 w-5 text-gray-500" />
        )}
      </div>
      {errorMessage && (
        <div className="text-sm text-baseOrange mt-[8px]">{errors[name]}</div>
      )}
    </div>
  );
};

export default PasswordInput;
