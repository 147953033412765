import React, { useContext, useEffect } from 'react'
import { NavbarUserIcon, LogOutIcon } from '../../assets/svgs/index'
import logo from '../../assets/img/symbiotic-logo 1.png';
import { AuthContext } from '../../context/AuthContext';

const Navbar = ({ name }) => {
    const { logout, customerDetails } = useContext(AuthContext);
    useEffect(() => {

    }, [customerDetails.logo])

    return (
        <div className="fixed top-0 h-12 w-full bg-white shadow-lg">
            <div className='flex justify-between mr-8'>
                <div>
                    <img /* src={logo} */ src={customerDetails.logo} alt='Symbiotic Logo' className='h-10 w-18 ml-4 mt-2' />
                </div>
                <div className='flex justify-center items-center gap-x-4'>
                    <div className='flex justify-center items-center gap-2 w-full h-full'>
                        <NavbarUserIcon width='40px' height='40px' />
                        <div className='flex-col'><div className='text-[10px] text-customGray'>Hello,</div>
                            <div className='text-[14px] mt-2 text-black font-semibold'>{name}</div></div>
                    </div>
                    <div className='w-[1px] h-8 bg-black'></div>
                    <div className='flex justify-center items-center gap-x-2' onClick={() => { logout() }}>
                        <LogOutIcon />
                        <div className='text-[14px]/[20px] text-customGray font-semibold cursor-pointer'>Logout</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
