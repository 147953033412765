export const timeIntervalOptions = [
    { label: "None", value: null },
    { label: "1 minute", value: 1 },
    { label: "5 minutes", value: 5 },
    { label: "15 minutes", value: 15 },
];
export const formatOptions = [
    { label: "Integer", value: "integer" },
    { label: "Real", value: "real" },
    { label: "Exponential", value: "exponential" },
];
