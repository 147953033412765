import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { useAxiosPrivate } from "../hooks/useAxiosPrivate";
import InputField from "../components/InputField";
import Select from "../components/SelectBox";
import getApiMap from "../routes/url/ApiUrls";
import { PencilIcon } from "../assets/svgs/index";
import EditDBConnection from "../features/database-management/components/EditDBConnection";
import { EyeClosedIcon, EyeOpenIcon } from "../assets/svgs";
import { databaseOptions } from "../features/database-management/db-mgmt.constants";
import ConnectionFooter from "../features/database-management/components/ConnectionFooter";
import Notification from "../components/Notifications/index";
import MandatoryLabel from "../components/common/MandatoryLabel";
import { config } from "../assets/config/config";
import FormikErrorComponent from "../features/database-management/components/FormikErrorComponent";
import { PATHS } from "../routes/path";
const { serverTypeMandatoryConfig } = config.database;


const ViewDBConnection = () => {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const formRef = useRef(null)
    const [showPassword, setShowPassword] = useState(false);
    const [tableOptions, setTableOptions] = useState([]);
    const [dbConnectionData, setDbConnectionData] = useState(null);
    const [message, setMessage] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [valuesSet, setValuesSet] = useState(false); // New state to track if values are set
    const validationSchema = Yup.object({
        serverType: Yup.string()
            .required("Server type is required")
            .min(1, "Server type is required"),
        path: Yup.string().min(1, "Path is required"),
        databaseName: Yup.string()
            .required("Database name is required")
            .min(1, "Database name is required"),
        userName: Yup.string().min(
            2,
            "Username must be at least 3 characters long"
        ),
        password: Yup.string()
            // .required("Password is required")
            .min(6, "Password must be at least 6 characters long"),
        port: Yup.string().matches(/^[0-9]+$/, "Port must be a valid number"),
        tableName: Yup.string()
            .required("Table name is required")
            .min(1, "Table name is required"),
        dateTimeColumn: Yup.string()
            .required("DateTime column name is required")
            .min(1, "DateTime column name is required"),
        batchNumberColumn: Yup.string()
            .required("Batch number column name is required")
            .min(1, "Batch number column name is required"),
        jobNumberColumn: Yup.string()
            .required("Job number column name is required")
            .min(1, "Job number column name is required"),
        Username_dateTimeColumn: Yup.string()
            .required("User name for DateTime column is required")
            .min(1, "User name for DateTime column is required"),
        Username_batchNumberColumn: Yup.string()
            .required("User name for Batch number column is required")
            .min(1, "User name for Batch number column is required"),
        Username_jobNumberColumn: Yup.string()
            .required("User name for Job number column is required")
            .min(1, "User name for Job number column is required"),
    });

    useEffect(() => {
        const fetchDatabaseConnectionData = async () => {
            try {
                const response = await axiosPrivate.get(`${getApiMap("databaseBase")}`);
                setDbConnectionData(response.data);
            } catch (error) {
                console.error("Error fetching database connection data:", error);
            }
        };

        fetchDatabaseConnectionData();
    }, [axiosPrivate]);

    const getDataBaseTables = async () => {
        try {
            const response = await axiosPrivate.get(`${getApiMap("dataBaseTables")}`);
            const tables = response.data.tables.map((table) => ({
                label: table,
                value: table,
            }));
            setTableOptions(tables);
        } catch (error) {
            console.error("Error fetching table options:", error);
        }
    };

    // Fetch table options on component mount
    useEffect(() => {
        getDataBaseTables();
    }, []);

    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationSeverity, setNotificationSeverity] = useState("success");
    const handleNotificationClose = () => {
        setNotificationOpen(false);
        setNotificationMessage("");
        if (notificationSeverity === "success") navigate("/");
    };

    const formik = useFormik({
        initialValues: {
            serverType: "",
            path: "",
            databaseName: "",
            userName: "",
            password: "",
            port: "",
            tableName: "",
            dateTimeColumn: "",
            batchNumberColumn: "",
            jobNumberColumn: "",
            Username_dateTimeColumn: "",
            Username_batchNumberColumn: "",
            Username_jobNumberColumn: "",
        },
        validationSchema,
        enableReinitialize: true, // Allow the form to reinitialize when initialValues change
        onSubmit: async (values) => {
            try {
                // Create a new object to filter out non-mandatory fields if they are empty
                const filteredValues = Object.fromEntries(
                    Object.entries(values).filter(
                        ([_, value]) =>
                            value !== undefined && value !== null && value !== ""
                    )
                );
                const serverType = formik.values?.serverType;
                const mandatoryFields = serverTypeMandatoryConfig?.[serverType]
                if (!serverType || !mandatoryFields) {
                    alert("Please select server type");
                    return;
                }

                const missingMandatoryFields = []
                mandatoryFields.forEach((field) => {
                    if (!filteredValues[field]) missingMandatoryFields.push(field)
                })
                if (missingMandatoryFields.length > 0) {

                    const errors = await formik.validateForm();

                    missingMandatoryFields.forEach((field) => {
                        // formik.setFieldError(field, "This field is required");
                        errors[field] = "This field is required";
                    });
                    formik.setErrors(errors);
                    return;
                }


                await setMessage(''); // to clear any previous error message
                const response = await axiosPrivate.put(
                    `${getApiMap("databaseBase")}`,
                    filteredValues
                );
                if (response.status === 200) {
                    setNotificationOpen(true);
                    setNotificationMessage("Database updated successfully.");
                    setNotificationSeverity("success");
                    // setFormVisible(false); // Close the form after successful submission
                }
            } catch (error) {
                console.error("Error updating database connection:", error);
                setNotificationOpen(true);
                setNotificationMessage(
                    "Failed to update database connection. Please try again."
                );
                setNotificationSeverity("error");
                setMessage("Failed to update database connection. Please try again.");
            }
        },
    });

    useEffect(() => {
        if (dbConnectionData && !valuesSet) {
            formik.setValues({
                serverType: dbConnectionData.message.serverType || "",
                path: dbConnectionData.message.path || "",
                databaseName: dbConnectionData.message.databaseName || "",
                userName: dbConnectionData.message.userName || "",
                password: dbConnectionData.message.password || "",
                port: dbConnectionData.message.port || "",
                tableName: dbConnectionData.message.tableName || "",
                dateTimeColumn: dbConnectionData.message.dateTimeColumn || "",
                batchNumberColumn: dbConnectionData.message.batchNumberColumn || "",
                jobNumberColumn: dbConnectionData.message.jobNumberColumn || "",
                Username_dateTimeColumn:
                    dbConnectionData.message.Username_dateTimeColumn || "",
                Username_batchNumberColumn:
                    dbConnectionData.message.Username_batchNumberColumn || "",
                Username_jobNumberColumn:
                    dbConnectionData.message.Username_jobNumberColumn || "",
            });
            setValuesSet(true); // Mark values as set
        }
    }, [dbConnectionData, formik, valuesSet]);

    const navigateBack = () => {
        navigate(PATHS.homePath);
    };

    const handleOkayClick = () => {
        if (isEditMode) return;
        navigate(PATHS.homePath);
    };

    const isMandatoryField = (field) => {
        return serverTypeMandatoryConfig?.[formik.values?.serverType]?.includes(
            field
        );
    };

    const handleOverlayClick = (e) => {
        if (formRef.current && !formRef.current.contains(e.target)) {

            navigate(PATHS.homePath);
        }
    };

    return (

        <div className="fixed inset-0 w-full h-screen flex items-center bg-opacity-80 bg-backgroundColor !z-30 justify-center backdrop-blur-[5px]" onClick={handleOverlayClick}>
            <div className="w-7/12 bg-white p-6 rounded-md shadow-lg mt-16 overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200" /* ref={formRef} */ >
                <div className="flex items-center relative">
                    <button
                        onClick={() => {
                            formik.resetForm();
                            navigateBack();
                        }}
                        className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl"
                    >
                        &times;
                    </button>
                    <div className="text-textHeading2 text-[16px]/[20px] font-bold">
                        {isEditMode ? "Edit Details" : "Connected Details"}
                    </div>

                    {isEditMode ? null : (
                        <Tooltip title="Edit DB details and connect">
                            <button onClick={() => setEditMode(true)} >
                                <PencilIcon />
                            </button>
                        </Tooltip>
                    )}
                </div>

                <div className="text-textGray text-[14px]/[20px] pt-1">
                    {isEditMode ? "Edit the required details and save" : "Details of connected Database"}
                </div>


                <form className="w-full mt-4" onSubmit={formik.handleSubmit}>
                    <div className="w-full gap-x-4">
                        {/* first box */}
                        <div className="w-full grid grid-cols-2 gap-x-4">
                            <Select
                                // label="Database"
                                label={
                                    <MandatoryLabel
                                        name={"Database"}
                                        isMandatory={isMandatoryField("serverType")}
                                    />
                                }
                                name="serverType"
                                options={databaseOptions}
                                value={formik.values.serverType}
                                onChange={(e) => {
                                    if (!isEditMode) {
                                        e.preventDefault();
                                        return;
                                    }
                                    const selectedValue = e.target.value;
                                    formik.setFieldValue("serverType", selectedValue);
                                }}
                                error={
                                    formik.touched.serverType && formik.errors.serverType
                                }
                                placeholder="Choose database server"
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                            />
                            <InputField
                                // label="Database Name"
                                label={
                                    <MandatoryLabel
                                        name={"Database Name"}
                                        isMandatory={isMandatoryField("databaseName")}
                                    />
                                }
                                type="text"
                                name="databaseName"
                                value={formik.values.databaseName}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Database Name"
                            />
                            <InputField
                                // label="Path"
                                label={
                                    <MandatoryLabel
                                        name={"Path"}
                                        isMandatory={isMandatoryField("path")}
                                    />
                                } type="text"
                                name="path"
                                value={formik.values.path}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Path"
                            />
                        </div>

                        {/* Second box */}
                        <div className="w-full grid grid-cols-2 gap-x-4">
                            <InputField
                                // label="User Name"
                                label={
                                    <MandatoryLabel
                                        name={"User Name"}
                                        isMandatory={isMandatoryField("userName")}
                                    />
                                }
                                type="text"
                                name="userName"
                                value={formik.values.userName}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter User Name"
                                error={formik.touched.userName && formik.errors.userName ? (
                                    <FormikErrorComponent errorMsg={formik.errors.userName} />
                                ) : null}
                            />
                            <div className="flex row">
                                <InputField
                                    // label="Password"
                                    label={
                                        <MandatoryLabel
                                            name={"Password"}
                                            isMandatory={isMandatoryField("password")}
                                        />
                                    }
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                    value={formik.values.password}
                                    labelClass="text-textHeading text-[14px]/[20px] mt-[-4px] font-semibold"
                                    inputClass={styles.inputClass}
                                    placeholder="********"
                                    error={formik.touched.password && formik.errors.password ? (
                                        <FormikErrorComponent errorMsg={formik.errors.password} />
                                    ) : null}
                                />
                                <div className="relative">
                                    <button
                                        type="button"
                                        className="absolute right-3 top-12 m-[3] transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                    </button>
                                </div>
                            </div>
                            <InputField
                                // label="Port"
                                label={
                                    <MandatoryLabel
                                        name={"Port"}
                                        isMandatory={isMandatoryField("port")}
                                    />
                                }
                                type="text"
                                name="port"
                                value={formik.values.port}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Port"
                                error={formik.touched.port && formik.errors.port ? (
                                    <FormikErrorComponent errorMsg={formik.errors.port} />
                                ) : null}
                            />
                            <InputField
                                // label="Table name"
                                label={
                                    <MandatoryLabel
                                        name={"Table name"}
                                        isMandatory={isMandatoryField("tableName")}
                                    />
                                }
                                name="tableName"
                                value={formik.values.tableName}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                error={formik.touched.tableName && formik.errors.tableName}
                                placeholder="Table1"
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                            />
                            <InputField
                                // label="Column name to retrieve date and time"
                                label={
                                    <MandatoryLabel
                                        name={"Column name to retrieve date and time"}
                                        isMandatory={isMandatoryField("dateTimeColumn")}
                                    />
                                }
                                type="text"
                                name="dateTimeColumn"
                                value={formik.values.dateTimeColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter DateTime Column"
                            />
                            <InputField
                                // label="User given name for retrieved date and time"
                                label={
                                    <MandatoryLabel
                                        name={"User given name for retrieved date and time"}
                                        isMandatory={isMandatoryField("Username_dateTimeColumn")}
                                    />
                                }
                                type="text"
                                name="Username_dateTimeColumn"
                                value={formik.values.Username_dateTimeColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Value"
                            />
                            <InputField
                                // label="Column name to retrieve batch number"
                                label={
                                    <MandatoryLabel
                                        name={"Column name to retrieve batch number"}
                                        isMandatory={isMandatoryField("batchNumberColumn")}
                                    />
                                }
                                type="text"
                                name="batchNumberColumn"
                                value={formik.values.batchNumberColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Batch Number Column"
                            />
                            <InputField
                                // label="User given name for retrieved batch number"
                                label={
                                    <MandatoryLabel
                                        name={"User given name for retrieved batch number"}
                                        isMandatory={isMandatoryField("Username_batchNumberColumn")}
                                    />
                                }
                                type="text"
                                name="Username_batchNumberColumn"
                                value={formik.values.Username_batchNumberColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Value"
                            />
                            <InputField
                                // label="Column name to retrieve batch description"
                                label={
                                    <MandatoryLabel
                                        name={"Column name to retrieve batch description"}
                                        isMandatory={isMandatoryField("jobNumberColumn")}
                                    />
                                }
                                type="text"
                                name="jobNumberColumn"
                                value={formik.values.jobNumberColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Job Number Column"
                            />
                            <InputField
                                // label="User given name for the retrieved batch description"
                                label={
                                    <MandatoryLabel
                                        name={"User given name for retrieved batch description"}
                                        isMandatory={isMandatoryField("Username_jobNumberColumn")}
                                    />
                                }
                                type="text"
                                name="Username_jobNumberColumn"
                                value={formik.values.Username_jobNumberColumn}
                                onChange={isEditMode ? formik.handleChange : undefined}  // Only pass onChange in edit mode
                                labelClass={styles.labelClass}
                                inputClass={styles.inputClass}
                                placeholder="Enter Value"
                            />
                        </div>
                    </div>
                    {/* Footer */}
                    <ConnectionFooter
                        handleCancelClick={navigateBack}
                        handleOkayClick={handleOkayClick}
                        isEditMode={isEditMode}
                    />
                    {message && (
                        <p className="mt-4 text-center text-red-500 ">{message}</p>
                    )}
                </form>


                <Notification
                    open={notificationOpen}
                    message={notificationMessage}
                    severity={notificationSeverity}
                    onClose={handleNotificationClose}
                />
            </div>
        </div>

    );
};

const styles = {
    labelClass: "text-textHeading text-sm font-semibold whitespace-nowrap text-ellipsis w-full",
    inputClass: "p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[10px]"
}

export default ViewDBConnection;
