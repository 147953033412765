import { CircularProgress } from '@mui/material'
import React from 'react'

function Loader({ loading }) {
    if (!loading) return null;
    return (
        <div className=' w-full h-full flex justify-center items-center'>
            <CircularProgress
                size={70}
                sx={{
                    color: "#EB7E39",
                }}
            />
        </div>
    )
}

export default Loader