import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker'; // Import DatePicker from react-datepicker
import { Button } from '@mui/material'; // Use MUI Button
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import dayjs from 'dayjs';
import { Chip, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CustomSearchableSelect from '../SearchableSelect/index'; // Import your searchable select component
import '../DateSelecter/index.css'
import { DayPicker, useDayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
const DateRangePickerComponent = React.memo(({ label = "Pick a date range", timeLabel, options = [], batchList,
    batchOptionSelected,
    handleDateRender,
    setBatchOptionsSelected,
    setHandleDateRender, listTempSelected,
    setListTempSelect,
    startDate,
    batchLoader,
    endDate,
    setStartDate,
    setEndDate,
    handleOptionChange, dateRange, setDateRange }) => {

    const [isSelectOpen, setIsSelectOpen] = useState(false); // Control visibility of the searchable select
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([]); // State for selected options
    const datePickerRef = useRef(null); // Ref for the date picker container
    const handleCalendar = useRef(null)

    // const handleDateChange = (dates) => {
    //     if (dates && dates.length === 2) {
    //         setStartDate(dates[0]);
    //         setEndDate(dates[1]);
    //         setDateRange([
    //             dayjs(dates[0]).format('YYYY-MM-DD') + ' 00:00:00',
    //             dayjs(dates[1]).format('YYYY-MM-DD') + ' 23:59:59'
    //         ]);

    //     }
    // };

    const handleDateChange = (range) => {
        if (range) {
            const { from, to } = range;
            setStartDate(from || null);
            setEndDate(to || null);
            setDateRange([
                dayjs(from).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(to).format('YYYY-MM-DD') + ' 23:59:59'
            ]);

        }
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setDateRange([null, null]); // Clear the range
    };

    const handleSelectClick = () => {
        setIsSelectOpen(!isSelectOpen); // Toggle the select dropdown visibility
    };
    const handleCalendarClick = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the select dropdown visibility
    };

    useEffect(() => {
        if (handleDateRender) {
            setStartDate(null)
            setEndDate(null)
            setHandleDateRender(false)
            setListTempSelect([])
            setBatchOptionsSelected([])

        }
    }, [handleDateRender])


    const handleToggleDatePicker = () => {
        setIsSelectOpen(false); // Close the dropdown when opening the date picker
        setStartDate(null); // Optionally reset start and end dates when dropdown is opened
        setEndDate(null);
    };

    const formattedStartDate = startDate ? dayjs(startDate).format('MMM D, YYYY') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('MMM D, YYYY') : '';
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsDatePickerOpen(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside); // Add the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup the listener
        };
    }, [datePickerRef]);
    const handleAdd = () => {
        if (batchOptionSelected.length > 0) {
            setListTempSelect([...batchOptionSelected])
        }
    }
    const handleRemoveChip = (value) => {
        setListTempSelect((prevList) => prevList.filter((batch) => batch !== value));
        setBatchOptionsSelected((prev) => prev.filter((batch) => batch !== value))
    };
    const handleMonthChange = (month) => {
        // Handle the month change here if needed
    };

    useEffect(() => {

    }, [listTempSelected])
    const YearDropdown = ({ children }) => (
        <div style={{ height: "80px", overflowY: "scroll" }}>{children}</div>
    );

    return (
        <div style={{ position: 'relative' }} ref={datePickerRef}>
            <div
                onClick={handleSelectClick}
                style={{
                    width: '300px',
                    padding: '8px',
                    borderRadius: '6px',
                    background: 'white',
                    color: '#979797',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: 32,
                    overflow: 'hidden', // Hide overflowing content
                    whiteSpace: 'nowrap', // Prevent wrapping
                    textOverflow: 'clip', // Optional: clip overflow without ellipsis
                }}
                className={`${isSelectOpen ? "border-baseOrange" : "border-[#d4d4d4]"} border hover:border-baseOrange`}
            >
                <span
                    className="pt-1 text-sm leading-5 flex gap-x-2 items-center justify-start pl-2"
                    style={{
                        padding:2,
                        overflow: 'hidden', // Ensure content inside span doesn't overflow
                        textOverflow: 'clip', // Prevent showing ellipsis
                        whiteSpace: 'nowrap', // Keep content in a single line
                    }}
                >
                    {listTempSelected.length > 0
                        ? listTempSelected?.map((batch) => (
                            <Chip
                                key={batch}
                                deleteIcon={<CloseIcon className="!w-4" />}
                                label={batch}
                                onDelete={() => handleRemoveChip(batch)}
                                style={{
                                    margin: '2px',
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    whiteSpace: 'nowrap', // Prevent wrapping of Chip text
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis', // Optional: Add ellipsis inside the chip
                                }}
                            />
                        ))
                        : <span className='text-[10px]/[14px] text-[#737373]'> {label} </span>}
                </span>
                {startDate && endDate && (
                    <Button
                        onClick={handleClear}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: 10,
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 0,
                            color: '#979797',
                        }}
                    />
                )}
            </div>

            {isSelectOpen && (
                <div
                    className="z-10 mt-3 border border-customGray rounded-md bg-white shadow-md"
                    style={{
                        position: 'absolute', // Position as overlay
                        top: '100%',          // Position directly below the parent
                        left: 0,              // Align with the left side of the parent container
                        width: '230%',        // Take full width of the parent
                        // Ensure it's on top of other elements
                    }}
                >
                    <div className="w-full flex justify-center items-stretch h-full p-3">
                        <div className="p-2 flex-1">
                            <h3 className="text-black font-medium text-[12px]/[18px]">Add Batch</h3>
                            <p className="pt-1 text-textGray text-[10px]/[16px]">You can select only 10 batches</p>
                            <p className="pt-3 text-[#171717] text-[12px]/[18px] pb-2">Time Period</p>

                            <div
                                onClick={handleCalendarClick} // Toggle calendar
                                style={{
                                    padding: '8px',

                                    borderRadius: '6px',
                                    background: 'white',
                                    color: '#979797',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    height: 32,
                                    position: 'relative', // Allow button positioning relative to this div
                                }}
                                className={`${isDatePickerOpen ? "border-baseOrange" : "border-[#d4d4d4]"} border hover:border-baseOrange`}
                            >
                                <span className="text-[10px]/[16px] text-[#737373] leading-5 flex gap-x-2 items-center justify-center pl-2">
                                    <CalendarTodayIcon className="!w-4 !h-4" style={{ color: '#171717' }} />
                                    {formattedStartDate && formattedEndDate
                                        ? `${formattedStartDate} - ${formattedEndDate}`
                                        : timeLabel}
                                </span>
                                {startDate && endDate && (
                                    <Button
                                        onClick={handleClear}
                                        size="small"
                                        style={{
                                            position: 'absolute',
                                            right: 8,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: 10,
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            padding: 0,
                                            color: '#979797',
                                        }}
                                    >
                                        <CloseIcon style={{ fontSize: 12 }} />
                                    </Button>
                                )}
                            </div>

                            {isDatePickerOpen && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 1010,
                                        marginTop: '10px',
                                    }}
                                    ref={datePickerRef}
                                >

                                    <DayPicker
                                        mode="range"
                                        showOutsideDays={true}
                                        selected={{ from: startDate, to: endDate }}
                                        onSelect={(range) => {
                                            if (range && range.from && range.to && range.from.getTime() !== range.to.getTime()) {
                                                handleDateChange(range);
                                            } else if (range && range.from) {
                                                handleDateChange({ from: range.from, to: null });
                                            }
                                        }}
                                        fromYear={2000}
                                        toYear={new Date().getFullYear()}
                                        defaultMonth={startDate || new Date()} // Start with the selected startDate or current date
                                        modifiers={{
                                            today: new Date(),
                                            disabled: (date) => date > new Date(),
                                        }}
                                        modifiersStyles={{
                                            today: { backgroundColor: '#EB7E39', color: 'white',height:"60%" },
                                            range_start: { backgroundColor: '#EB7E39', color: 'white' },
                                            range_end: { backgroundColor: '#EB7E39', color: 'white' },
                                        }}
                                        numberOfMonths={2}
                                        styles={{
                                            caption: {
                                                textAlign: 'center',
                                                color: 'orange',
                                                fontWeight: 'bold',
                                                fontSize: '8px', 
                                            },
                                            container: {
                                                background: 'white',
                                                borderRadius: '8px',
                                                border: '1px solid #EB7E39',
                                                fontSize: '14px', // Adjusted font size for calendar content
                                                height: "60%",
                                            },
                                            dropdown: {
                                                padding: '8px',  // Increase padding for a better look
                                                margin: '0 4px',
                                                width: '150%',
                                                paddingLeft: '10px',
                                                fontSize: '12px', // Increased font size for dropdown
                                                color: 'black',
                                                border: '1px solid #EB7E39', // Add border matching the theme color
                                                borderRadius: '4px', // Add rounded corners for dropdown
                                            },
                                            dropdownOption: {
                                                padding: '8px 12px', // Add padding to dropdown options
                                                color: 'black', // Set text color to match theme
                                            },
                                        }}
                                        className="custom-daypicker"
                                        captionLayout="dropdown"
                                        dropdownContainer={YearDropdown}
                                    />

                                </div>
                            )}


                            <div style={{ position: 'relative', zIndex: 1000, marginTop: '10px' }}>
                                <CustomSearchableSelect
                                    options={batchList}
                                    selectedOptions={batchOptionSelected}
                                    onChange={handleOptionChange}
                                    multiSelect={true}
                                    showDetails={true}
                                    batchLoader={batchLoader}
                                    placeholder="Select batch from the drop down"
                                    selectMaxCount={10}
                                    showParameter={false}
                                />
                            </div>
                            <div className="mt-3 w-full flex justify-end">
                                <button className="border border-baseOrange w-20 text-[12px] text-baseOrange h-8 flex justify-center items-center rounded-md" onClick={handleAdd}>+ Add</button>
                            </div>
                        </div>
                        <div className="w-[1px] bg-[#D4D4D4] my-3"></div>
                        <div className="w-80 flex-1">
                            <div className="w-full h-8 flex justify-end" onClick={handleSelectClick}>
                                <CloseIcon style={{fontSize:"16px"}} />
                            </div>
                            <div className="p-2 h-full">
                                {batchOptionSelected.length > 0 ? (
                                    <div className="w-full h-[60%] rounded-md p-3 border border-[#D4D4D4] text-customGray text-[12px]/[20px]">
                                        <div
                                            className="flex flex-wrap gap-2 overflow-auto"
                                            style={{
                                                maxHeight: '80%', // Ensures it doesn't exceed container height
                                                overflowY: 'auto', // Enables vertical scrolling if necessary
                                            }}
                                        >
                                            {listTempSelected?.map((batch, index) => (
                                                <div
                                                    key={index}
                                                    className="text-customGray text-[12px]/[20px] m-1 px-2 bg-[#F5F5F5] truncate"
                                                    style={{
                                                        maxWidth: '100%', // Ensures it doesn't overflow horizontally
                                                        whiteSpace: 'nowrap', // Prevents wrapping of the batch text
                                                        textOverflow: 'ellipsis', // Adds ellipsis for long text
                                                        overflow: 'hidden', // Hides any overflowed content
                                                    }}
                                                >
                                                    {batch}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="w-full h-[65.5%] rounded-md p-3 border border-[#D4D4D4] text-customGray text-[12px]/[20px]">
                                        Start selecting batches to compare
                                    </div>
                                )}

                                <div className="mt-3 w-full flex justify-end">
                                    <button
                                        className="border border-baseOrange w-20 bg-baseOrange text-white h-8 flex justify-center items-center rounded-md text-[12px]"
                                        onClick={handleSelectClick}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

})

export default DateRangePickerComponent;