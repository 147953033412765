import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
const Index = ({ handleCancel, downloadChart,showPdf,handleReportDownload }) => {
  const [selectedOption, setSelectedOption] = useState('');  // State to manage the selected format

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);  // Update the state when an option is selected
  };

  const handleDownload = () => {
    handleCancel()
    
    if (selectedOption === "jpeg") {
      downloadChart()
    } else if(selectedOption==="pdf") {
handleReportDownload()
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-5/12 text-center">
        <div className='flex justify-between items-start'>
          <h2 className="text-[20px]/[28px] font-semibold text-left" >Download</h2>
          <div onClick={handleCancel}><CloseIcon className='!w-5 !h-5' /></div>
        </div>
        <p className="text-[12px]/[20px] text-[#737373] mb-3 text-left">Select from the option below.</p>
        <div className="mb-6 text-left flex-col justify-start items-center gap-y-4">
          {showPdf?<div className='mb-4 flex justify-center items-center w-full h-full'>
            <div className='flex justify-start items-center w-full'>
              <input
                type="radio"
                value="pdf"
                checked={selectedOption === 'pdf'}
                onChange={handleOptionChange}
                className="mr-2 w-4"
              />
              <div className="text-[12px]/[20px] text-[#171717]">
                PDF
              </div>
            </div>
          </div>:null}
          <div className='flex justify-center items-center'>
            <div className='flex justify-start items-center w-full'>

              <input
                type="radio"
                value="jpeg"
                checked={selectedOption === 'jpeg'}
                onChange={handleOptionChange}
                className="mr-2 w-4"
              />
              <label className="block text-[12px]/[20px] text-[#1717171]">
                JPEG
              </label>
            </div>
          </div>
        </div>

        <div className='flex justify-end gap-x-2'>
          <button type='button' className='rounded-md px-2 border border-[#A9A3A3] text-[14px]/[20px] text-[#0F172A]' onClick={handleCancel}> Cancel</button>
          <button type='button' className="px-4 py-2 bg-baseOrange text-[14px]/[20px] text-white rounded-md cursor-pointer hover:bg-orange-600" onClick={handleDownload} disabled={selectedOption===""}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
