import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import './index.css'; // Ensure your custom styles are applied
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DayPicker } from 'react-day-picker';
export default function DateRangePickerComponent({ dateRange, setDateRange, label = "Pick a date range", height = "80%" }) {
    const [startDate, setStartDate] = useState(dateRange[0] ? dayjs(dateRange[0]).toDate() : null);
    const [endDate, setEndDate] = useState(dateRange[1] ? dayjs(dateRange[1]).toDate() : null)
    const [isPickerOpen, setIsPickerOpen] = useState(false); // Control visibility of the DatePicker

    const datePickerRef = useRef(null); // Ref for the container of the date picker

    const handleDateChange = (range) => {
        if (range) {
            const { from, to } = range;
            setStartDate(from || null);
            setEndDate(to || null);
            setDateRange([
                dayjs(from).format('YYYY-MM-DD') + ' 00:00:00',
                dayjs(to).format('YYYY-MM-DD') + ' 23:59:59'
            ]);

        }
    };

    const handleClear = () => {
        setStartDate(null);
        setEndDate(null);
        setDateRange([null, null]); // Clear the range
    };

    const handleInputClick = () => {
        setIsPickerOpen(!isPickerOpen); // Toggle the picker visibility
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsPickerOpen(false); // Close the picker if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Cleanup listener on unmount
        };
    }, [datePickerRef]);
    const YearDropdown = ({ children }) => (
        <div style={{ height: "80px", overflowY: "scroll" }}>{children}</div>
    );


    const formattedStartDate = startDate ? dayjs(startDate).format('MMM D, YYYY') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('MMM D, YYYY') : '';
    return (
        <div style={{ width: '300px', position: 'relative' }} ref={datePickerRef} className={`${isPickerOpen ? "border-baseOrange" : ""}`}>
            {isPickerOpen && (
                <div className="z-20" style={{ position: 'absolute', top: '40px', left: '0', padding: '10px', borderRadius: '5px', width: "180%" }}>
                    {/* <DayPicker
                        mode="range"
                        showOutsideDays={true}
                        selected={{ from: startDate, to: endDate }}
                        onSelect={(range) => {
                            // Check if the same date is selected for both "from" and "to"
                            if (range && range.from && range.to && range.from.getTime() !== range.to.getTime()) {
                                handleDateChange(range);
                            } else if (range && range.from) {
                                handleDateChange({ from: range.from, to: null }); // Clear the to date if they are the same
                            }
                        }}
                        fromYear={2000}
                        toYear={new Date().getFullYear()}
                        modifiers={{
                            today: new Date(),
                            disabled: (date) => date > new Date(),  // Disable dates after today
                        }}
                        modifiersStyles={{
                            today: { backgroundColor: '#EB7E39', color: 'white' },
                            range_start: { backgroundColor: '#EB7E39', color: 'white' },
                            range_end: { backgroundColor: '#EB7E39', color: 'white' },
                        }}
                        numberOfMonths={2}
                        disabled={false}
                        styles={{
                            caption: {
                                textAlign: 'center',
                                color: 'orange',
                                fontWeight: 'bold',
                                fontSize: '14px', // Increased font size for caption
                            },
                            container: {
                                background: 'white',
                                borderRadius: '8px',
                                border: '1px solid #EB7E39',
                                padding: '10px',
                                width: '100%',
                                fontSize: '14px', // Adjusted font size for calendar content
                                height: height,
                            },
                            dropdown: {
                                padding: '8px',  // Increase padding for a better look
                                margin: '0 4px',
                                width: '150%',
                                paddingLeft: '10px',
                                fontSize: '14px', // Increased font size for dropdown
                                color: 'black',
                                border: '1px solid #EB7E39', // Add border matching the theme color
                                borderRadius: '4px', // Add rounded corners for dropdown
                            },
                            dropdownOption: {
                                padding: '8px 12px', // Add padding to dropdown options
                                color: 'black', // Set text color to match theme
                            },
                        }}
                        className="custom-daypicker"
                        captionLayout="dropdown"
                        dropdownContainer={YearDropdown}
                    />
 */}

<DayPicker
  mode="range"
  showOutsideDays={true}
  selected={{ from: startDate, to: endDate }}
  onSelect={(range) => {
      if (range && range.from && range.to && range.from.getTime() !== range.to.getTime()) {
          handleDateChange(range);
      } else if (range && range.from) {
          handleDateChange({ from: range.from, to: null });
      }
  }}
  fromYear={2000}
  toYear={new Date().getFullYear()}
  defaultMonth={startDate || new Date()} // Start with the selected startDate or current date
  modifiers={{
      today: new Date(),
      disabled: (date) => date > new Date(),
  }}
  modifiersStyles={{
      today: { backgroundColor: '#EB7E39', color: 'white' },
      range_start: { backgroundColor: '#EB7E39', color: 'white' },
      range_end: { backgroundColor: '#EB7E39', color: 'white' },
  }}
  numberOfMonths={2}
  styles={{
    caption: {
        textAlign: 'center',
        color: 'orange',
        fontWeight: 'bold',
        fontSize: '8px', // Increased font size for caption
    },
    container: {
        background: 'white',
        borderRadius: '8px',
        border: '1px solid #EB7E39',
        padding: '8px',
        fontSize: '14px', // Adjusted font size for calendar content
        height: "80%",
    },
    dropdown: {
        padding: '8px',  // Increase padding for a better look
        margin: '0 4px',
        width: '150%',
        paddingLeft: '10px',
        fontSize: '12px', // Increased font size for dropdown
        color: 'black',
        border: '1px solid #EB7E39', // Add border matching the theme color
        borderRadius: '4px', // Add rounded corners for dropdown
    },
    dropdownOption: {
        padding: '8px 12px', // Add padding to dropdown options
        color: 'black', // Set text color to match theme
    },
}}
  className="custom-daypicker"
  captionLayout="dropdown"
  dropdownContainer={YearDropdown}
/>

                </div>
            )}


            <div
                onClick={handleInputClick}
                style={{
                    padding: '8px 8px',
                    borderRadius: '6px',
                    background: 'white',
                    color: '#979797',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: 300,
                    textAlign: 'left',
                    height: 32,
                }}
                className={`${isPickerOpen ? "border-baseOrange" : "border-[#d4d4d4]"} border`}
            >
                <span className='pt-1 text-[10px] leading-5 flex gap-x-2 items-center justify-center pl-2 h-8'>
                    <CalendarTodayIcon className='!w-4 !h-4' style={{ color: "#171717" }} />
                    {formattedStartDate && formattedEndDate
                        ? <span className='text-black'>{`${formattedStartDate} - ${formattedEndDate}`}</span>
                        : label}
                </span>
                {startDate && endDate && (
                    <Button
                        onClick={handleClear}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: 12,
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 0,
                            color: '#979797'
                        }}
                        icon={<i className="fas fa-times" style={{ fontSize: 16 }} />}
                    />
                )}
            </div>
        </div>
    );
}