import React, { useContext, useEffect, useState } from 'react';
import AdminWrapper from './routes/Wrapper/AdminWrapper';
import { adminRoutes } from './routes/mappings/adminMapping';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import PublicWrapper from './routes/Wrapper/PublicWrapper';
import { publicMapping } from './routes/mappings/publicMappings';
import { CircularProgress } from "@mui/material"
import { AuthContext } from './context/AuthContext';
import TestingPages from './pages/TestingPages'
import UserPage from './components/User'
import GroupPage from './components/GroupComps'
import DBConnection from './pages/DBConnection';
import DetailedSidebar from './pages/Penlist';
import ViewDBConnection from './pages/ViewDBConnection';

const App = () => {

  const { auth, setProfileData, initialLoader, setInitialLoader } = useContext(AuthContext)
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {

      setProfileData()
    } else {
      setTimeout(() => {
        setInitialLoader(false)
      }, 400)
    }
  }, [initialLoader])
  if (initialLoader) {
    return (
      <div className="w-full h-screen flex justify-center items-center ">
        <div className='flex-col items-center justify-center text-center'>
          <div className=''>
            <CircularProgress
              size={70}
              sx={{
                color: "#555555",
              }}

            />
          </div>
          <div className="mt-2 text-customGray text-[32px]">Loading...</div>
        </div>
      </div>

    );
  }

  return (
    <>
      <Routes>
        <Route path="" element={<AdminWrapper />}>
          {adminRoutes?.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route path="" element={<PublicWrapper />}>
          {publicMapping?.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>

        {/* <Route path="*" element={<Navigate to="/sign-in" replace />} /> */}
        <Route path="/" element={<TestingPages />} >
          <Route path="group" element={<GroupPage />} />
          <Route path="user" element={<UserPage />} />
          <Route path="db" element={<DBConnection />} />
          <Route path="pen" element={<DetailedSidebar />} />
          <Route path="viewdb" element={<ViewDBConnection />} />

        </Route>

      </Routes>
    </>
  );
};

export default App;
