import React from 'react'

const FormikErrorComponent = ({ children, errorMsg }) => {
    return (<div className="relative m-0">
        <div className="text-red-500 text-[10px] absolute top-0 -m-2 pl-3">
            {errorMsg || children}
        </div>
    </div>)
}

export default FormikErrorComponent